import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const PeriodSelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      defaultValue={initialrecord ? initialrecord.period : t('titles.choose')}
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
    >
      <Option value="HOUR">{t('columns.hour')}</Option>
      <Option value="MINUTE">{t('columns.minute')}</Option>
      {/* <Option value="SONG">{t('columns.song')}</Option> */}
    </Select>
  );
};

export default PeriodSelector;
