/* eslint-disable no-undef */
import React, { useEffect, useCallback } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import jwt from 'jsonwebtoken';
import { useMutation } from '@apollo/react-hooks';
import queryString from 'query-string';
import MainLayout from '../components/MainLayout';
import {
  MANAGER_ROUTES,
  PARTNER_ROUTES,
  BRANCH_ROUTES,
} from '../config/userAccess';
import VERIFY_TOKEN from './gql';

const cleanLocalStorageAndRedirect = (history) => {
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('token');

  history.push('/');
};

const checkTokenIfExpired = (token) => {
  const decodedToken = jwt.decode(token);

  if (!decodedToken) {
    return true;
  }

  const isTokenExpired = new Date() > new Date(decodedToken.exp * 1000);

  if (isTokenExpired) {
    return true;
  }
  return false;
};

const getUserRole = () => {
  const user = JSON.parse(window.localStorage.getItem('user'));

  if (!user) {
    return false;
  }

  return user.role;
};

const PrivateRoute = ({ component: Component, path, pageName, location }) => {
  let hasAccess = false;
  let token = window.localStorage.getItem('token');
  const parsedUrlParams = queryString.parse(location.search);

  // if token is null and url has token then get user info from url and set localStorage
  if (!token && parsedUrlParams?.token) {
    localStorage.setItem(
      'user',
      JSON.stringify({
        id: parsedUrlParams?.id,
        username: parsedUrlParams?.username || 'user',
        role: 'BRANCH',
        partnerBranch: {
          id: parsedUrlParams?.branchId,
        },
      })
    );
    localStorage.setItem('token', parsedUrlParams?.token);
    token = parsedUrlParams?.token;
  }

  // hooks
  const history = useHistory();
  const [verifyToken] = useMutation(VERIFY_TOKEN);

  // handles
  const handleVerifyToken = useCallback(async () => {
    try {
      const response = await verifyToken({ variables: { token } });

      if (!response.data.verify) {
        return false;
      }
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }, [verifyToken, token]);

  useEffect(() => {
    const callHandleVerifyToken = async () => {
      const isVerified = await handleVerifyToken();
      if (!isVerified) {
        cleanLocalStorageAndRedirect(history);
      }
    };

    if (!token) {
      cleanLocalStorageAndRedirect(history);
    } else {
      callHandleVerifyToken();
    }
  }, [token, handleVerifyToken, history]);

  const isTokenExpired = checkTokenIfExpired(token);
  if (isTokenExpired) {
    cleanLocalStorageAndRedirect(history);
  }

  const currentUserRole = getUserRole();
  if (!currentUserRole) {
    cleanLocalStorageAndRedirect(history);
  }

  switch (currentUserRole) {
    case 'ADMIN':
      hasAccess = MANAGER_ROUTES.includes(pageName);
      break;
    case 'MANAGER':
      hasAccess = MANAGER_ROUTES.includes(pageName);
      break;
    case 'PARTNER':
      hasAccess = PARTNER_ROUTES.includes(pageName);
      break;
    case 'BRANCH':
      hasAccess = BRANCH_ROUTES.includes(pageName);
      break;
    default:
      hasAccess = false;
      break;
  }

  if (!hasAccess) {
    return (
      <Redirect
        to={{
          pathname: '/notAuthorized',
        }}
      />
    );
  }

  return (
    <Route
      exact
      path={path}
      render={(props) => (
        <MainLayout>
          <Component {...props} />
        </MainLayout>
      )}
    />
  );
};

export default PrivateRoute;
