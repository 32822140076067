import { Upload, Button } from 'antd';
import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const SongFileUploadInput = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  const [fileListState, setFileListState] = useState();

  const props = {
    name: 'file',
    action: '',
    multiple: false,
    onRemove: () => {
      onChange(undefined);
    },
    beforeUpload: (file) => {
      onChange(file);
      return false;
    },
    onChange: (info) => {
      setFileListState([info.file]);
    },
    fileList: fileListState,
  };

  return (
    <Upload {...props}>
      <Button>
        <UploadOutlined />
        {t('messages.clickUploadImage')}
      </Button>
    </Upload>
  );
};

export default SongFileUploadInput;
