/* eslint-disable no-undef */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';

import {
  CREATE_PARTNER_BRANCH,
  PARTNER_BRANCHES,
  UPDATE_PARTNER_BRANCH,
  DELETE_PARTNER_BRANCH,
} from './gql';

import ModalFormBuilder from '../../components/ModalFormBuilder';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';
import userInfoGetter from '../../utils.js/userInfoGetter';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.partnerBranchName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'partnerId',
    label: t('titles.partnerName'),
    inputType: 'partnerSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'userId',
    label: t('titles.username'),
    inputType: 'userSelector',
    propsForSelector: { role: 'BRANCH' },
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.username'),
    dataIndex: 'userId',
    key: 'userId',
    inputType: 'userSelector',
    propsForSelector: { role: 'BRANCH' },
    render: (userId, record) => {
      return record.username || t('warnings.noData');
    },
    editable: true,
  },
  {
    title: t('titles.partnerName'),
    dataIndex: 'partnerId',
    key: 'partnerId',

    render: (partnerId, record) => {
      return record.partnerName || t('warnings.noData');
    },
    editable: false,
    width: '16%',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    title: t('titles.partnerBranchName'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
    width: '15%',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '15%',
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '15%',
  },
];

const PartnerBranch = () => {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);
  const { role, partnerId } = userInfoGetter();
  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);
  const upperGetReqConditionCreator = useCallback(() => {
    if (role === 'PARTNER') {
      return {
        limit: 10,
        offset: 0,
        order: [['createdAt', 'desc']],
        where: {
          partnerId,
        },
      };
    }
    return {
      limit: 10,
      offset: 0,
      order: [['createdAt', 'desc']],
    };
  }, [role, partnerId]);
  const afterGetFunc = useCallback((response) => {
    const newData = response?.partnerBranches?.data?.map((partnerBranch) => ({
      id: partnerBranch.id,
      name: partnerBranch.name,
      partnerName: partnerBranch.partner.name,
      partnerId: partnerBranch.partner.id,
      username: partnerBranch.user.username,
      userId: partnerBranch.user.id,
      createdAt: humanizeTimestamp(partnerBranch.createdAt),
      updatedAt: humanizeTimestamp(partnerBranch.updatedAt),
    }));

    return {
      ...response,
      partnerBranches: { ...response?.partnerBranches, data: newData },
    };
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="center">{t('header.partnerBranch')}</Divider>
        <Col span={24} style={{ marginTop: '20px' }}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleTrueFalse}
            modalTitle={t('titles.newPartnerBranch')}
            showModalButtonLabel={t('titles.newPartnerBranch')}
            fields={fields}
            postReq={CREATE_PARTNER_BRANCH}
            postResIndex="createPartnerBranch"
            postResFieldForUpdating="partnerBranch"
            willBeUpdatedQuery={PARTNER_BRANCHES}
            willBeUpdatedQueryName="partnerBranches"
            showOpenButton={role !== 'PARTNER'}
          />
        </Col>
      </Row>
      <Row>
        <Col md={24} lg={24} sm={24} xs={24}>
          <IntegratedEditableTable
            upperGetReqCondition={upperGetReqConditionCreator()}
            columns={columns}
            getReq={PARTNER_BRANCHES}
            getResIndex="partnerBranches"
            deleteReq={DELETE_PARTNER_BRANCH}
            deleteResIndex="deletePartnerBranch"
            updateReq={UPDATE_PARTNER_BRANCH}
            updateResIndex="updatePartnerBranch"
            afterGetFunc={afterGetFunc}
            filterColumns={{
              user: ['username'],
              partner: ['name'],
              partnerBranch: ['name'],
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default PartnerBranch;
