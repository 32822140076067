/* eslint-disable no-new */
import React, { useState, useEffect } from 'react';
import { Row, Col, Spin } from 'antd';
import '../statistic.css';
import { useQuery } from '@apollo/react-hooks';
import PieChart from '../../../components/Charts/PieChart';
import BarChart from '../../../components/Charts/BarChart';
import { MONTHLY_TOTAL_STATISTIC } from '../gql';
import ServerWrong from '../../../components/Result/ServerWrong';
import { useTranslation } from 'react-i18next';

const templateOfChartData = {
  standard: {
    label: 'Standart',
    backgroundColor: 'rgba(54, 162, 235, 0.2)',
    data: null,
    borderWidth: 1,
    borderColor: 'rgba(54, 162, 235, 1)',
  },
  inter: {
    label: 'Ara',
    backgroundColor: 'rgba(153, 102, 255, 0.2)',
    data: null,
    borderWidth: 1,
    borderColor: 'rgba(153, 102, 255, 1)',
  },
  special: {
    label: 'Özel',
    backgroundColor: 'rgba(255, 159, 64, 0.2)',
    data: null,
    borderWidth: 1,
    borderColor: 'rgba(255, 159, 64, 1)',
  },
};

const standardStatisticArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
const interStatisticArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
const specialStatisticArray = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
let totalStandard = 0;
let totalInter = 0;
let totalSpecial = 0;

const AdvertisementStatistic = () => {
  const [chartData, setChartData] = useState();
  const [loading, setLoading] = useState(false);
  const [pieChartData, setPieChartData] = useState();
  const { t } = useTranslation();

  // #region useQuery Section
  const {
    standardAdsLoading,
    standardAdsError,
    data: standardAdsData,
  } = useQuery(MONTHLY_TOTAL_STATISTIC, {
    variables: {
      type: 'STANDARD_ADVERTISEMENT',
    },
  });

  const { interAdsLoading, interAdsError, data: interAdsData } = useQuery(
    MONTHLY_TOTAL_STATISTIC,
    {
      variables: {
        type: 'INTER_ADVERTISEMENT',
      },
    }
  );

  const { specialAdsLoading, specialAdsError, data: specialAdsData } = useQuery(
    MONTHLY_TOTAL_STATISTIC,
    {
      variables: {
        type: 'SPECIAL_ADVERTISEMENT',
      },
    }
  );
  // #endregion

  useEffect(() => {
    if (standardAdsData && interAdsData && specialAdsData) {
      const standardStatistic =
        standardAdsData?.monthlyTopByTotalOfType?.statistic;
      const interStatistic = interAdsData?.monthlyTopByTotalOfType?.statistic;
      const specialStatistic =
        specialAdsData?.monthlyTopByTotalOfType?.statistic;

      standardStatistic.forEach((datum) => {
        totalStandard += Number(datum.value);
        standardStatisticArray[datum.month - 1] = datum.value;
      });

      interStatistic.forEach((datum) => {
        totalInter += Number(datum.value);
        interStatisticArray[datum.month - 1] = datum.value;
      });

      specialStatistic.forEach((datum) => {
        totalSpecial += Number(datum.value);
        specialStatisticArray[datum.month - 1] = datum.value;
      });

      const tempChartData = { ...templateOfChartData };

      tempChartData.standard.data = standardStatisticArray;
      tempChartData.inter.data = interStatisticArray;
      tempChartData.special.data = specialStatisticArray;

      setChartData(tempChartData);
      setPieChartData([
        {
          backgroundColor: ['#66d1bc', '#f19957', '#e966a6'],
          data: [totalStandard, totalInter, totalSpecial],
        },
      ]);
    }
  }, [
    standardAdsData,
    interAdsData,
    specialAdsData,
    setChartData,
    setPieChartData,
  ]);

  // #region loading and error section
  useEffect(() => {
    if (standardAdsLoading || interAdsLoading || specialAdsLoading) {
      setLoading(true);
    }
    setLoading(false);
  }, [standardAdsLoading, interAdsLoading, specialAdsLoading]);

  if (standardAdsError || interAdsError || specialAdsError) {
    return <ServerWrong />;
  }
  // #endregion loading and error section finish

  return (
    <div>
      <Row>
        <Col xs={{ span: 24 }} sm={24} md={{ span: 12 }}>
          <Spin spinning={loading}>
            <BarChart
              labels={[
                t('labels.jan'),
                t('labels.feb'),
                t('labels.march'),
                t('labels.apr'),
                t('labels.may'),
                t('labels.june'),
                t('labels.jul'),
                t('labels.aug'),
                t('labels.sep'),
                t('labels.oct'),
                t('labels.nov'),
                t('labels.dec'),
              ]}
              chartData={chartData ? Object.values(chartData) : null}
              title={t('titles.mountlyAdvertisementStatistic')}
            />
          </Spin>
        </Col>
        <Col
          style={{ display: 'flex', alignItems: 'center' }}
          md={{ span: 12 }}
          xs={{ span: 24 }}
          sm={24}
        >
          <PieChart
            labels={[
              t('columns.standard'),
              t('columns.inter'),
              t('columns.special'),
            ]}
            chartData={pieChartData}
            title={t('titles.adsvertisementPercent')}
          />
        </Col>
      </Row>
    </div>
  );
};

export default AdvertisementStatistic;
