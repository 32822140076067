/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback, useEffect } from 'react';
import {
  List,
  Avatar,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Divider,
  Spin,
  message,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { BACKEND_URL } from '../../constants';
import { PlaylistSelector } from '../../components/Selectors';
import ServerWrong from '../../components/Result/ServerWrong';
import { SONGS_OF_PLAYLIST, REMOVE_SONG_FROM_PLAYLIST } from './gql';
import Load from '../../components/Load';
import userInfoGetter from '../../utils.js/userInfoGetter';

const PlaylistMonitorAndEdit = () => {
  const { role, partnerId, userId } = userInfoGetter();
  // hooks
  const { t } = useTranslation();
  const [selectedPlaylistId, setSelectedPlaylistId] = useState();
  const [readCondition, setReadCondition] = useState();
  const [listData, setListData] = useState();
  const [hasPermissionToRemoveSong, setHasPermissonToRemoveSong] = useState(
    false
  );

  // gql hooks
  const { loading, error: fetchError, data } = useQuery(SONGS_OF_PLAYLIST, {
    variables: readCondition,
    skip: !readCondition,
    fetchPolicy: 'network-only',
  });
  const [removeSong] = useMutation(REMOVE_SONG_FROM_PLAYLIST, {
    update(cache, { data: deleteData }) {
      const responseFromMutation = deleteData.deleteSongFromPlaylist.data;

      const queryData = cache.readQuery({
        query: SONGS_OF_PLAYLIST,
        variables: readCondition,
      });

      const newStateAfterDelete = queryData?.playlist?.songs?.filter(
        (song) => !responseFromMutation.songIds.includes(song.id)
      );

      cache.writeQuery({
        query: SONGS_OF_PLAYLIST,
        variables: readCondition,
        data: {
          playlist: {
            ...queryData.playlist,
            songs: newStateAfterDelete,
          },
        },
      });
    },
  });

  useEffect(() => {
    const songs = data?.playlist?.songs;

    if (songs) {
      const formattedSongData = songs?.map((song) => ({
        id: song.id,
        name: song.name,
        address: song?.file?.address,
        artistFirstName: song?.artist?.firstName || '',
        artistLastName: song?.artist?.lastName || '',
        albumName: song?.album?.name,
      }));
      setListData(formattedSongData);

      if (role === 'ADMIN' || role === 'MANAGER') {
        setHasPermissonToRemoveSong(true);
      } else {
        const isOwnerOfComingPlaylist =
          data?.playlist?.createdBy?.id === userId;
        setHasPermissonToRemoveSong(isOwnerOfComingPlaylist);
      }
    }
  }, [data, userId, role]);

  // handles
  const handleRemove = useCallback(
    async (songId) => {
      try {
        const response = await removeSong({
          variables: {
            data: { playlistId: selectedPlaylistId, songIds: [songId] },
          },
        });
        const { success, errors } = response?.data?.deleteSongFromPlaylist;
        if (!success) {
          console.error(errors);
          message.error('errors.requestFailed');
        }
      } catch (error) {
        console.error(error);
        message.error('errors.requestFailed');
      }
    },
    [removeSong, selectedPlaylistId]
  );

  const handlePlaylistSelect = useCallback(
    (playlistId) => {
      setSelectedPlaylistId(playlistId);
      setReadCondition({ where: playlistId });
    },
    [setReadCondition]
  );

  if (fetchError) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading} indicator={<Load />}>
      <Row style={{ marginBottom: 30 }}>
        <Col xs={24} sm={24} md={8} lg={8} xl={12}>
          <h4>{t('labels.selectPlaylistSongs')}</h4>
          <PlaylistSelector
            customWhere={role === 'PARTNER' ? { partnerId } : undefined}
            onChange={handlePlaylistSelect}
          />
        </Col>
      </Row>
      <Divider />
      {listData && (
        <Row>
          <Col span={24}>
            <List
              itemLayout="horizontal"
              dataSource={listData}
              grid={{ gutter: 24, xs: 1, sm: 1, md: 1, lg: 1, xl: 2 }}
              renderItem={(song, index) => (
                <List.Item>
                  <Row>
                    <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                      <Avatar>{index + 1}</Avatar>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={10} xl={10}>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Tooltip title={song.name}>
                          <span>{song.name}</span>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Tooltip
                          title={`Artist: ${song.artistFirstName} ${song.artistLastName}`}
                        >
                          <span>{`${song.artistFirstName} ${song.artistLastName}`}</span>
                        </Tooltip>
                      </div>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        <Tooltip title={`Album: ${song.albumName}`}>
                          <span>{`${song.albumName}`}</span>
                        </Tooltip>
                      </div>
                    </Col>
                    <Col xs={8} sm={8} md={8} lg={10} xl={10}>
                      <audio
                        controls
                        controlsList="nodownload"
                        style={{ width: '100%' }}
                        src={BACKEND_URL + song.address}
                      />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                      <Popconfirm
                        disabled={!hasPermissionToRemoveSong}
                        title={t('messages.deleteSure')}
                        okText={t('buttons.yes')}
                        cancelText={t('buttons.no')}
                        onConfirm={() => handleRemove(song.id)}
                      >
                        <FontAwesomeIcon
                          style={{ float: 'right' }}
                          icon="trash"
                          size="lg"
                          color="#D21F3C"
                        />
                      </Popconfirm>
                    </Col>
                  </Row>
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )}
    </Spin>
  );
};

export default PlaylistMonitorAndEdit;
