import format from 'date-fns/format';

const dateParser = (dateString) => {
  const timestamp =
    new Date(dateString).getTime() + 60 * 1000 * new Date().getTimezoneOffset();
  const time = format(timestamp, 'HH:mm');
  const date = format(timestamp, 'dd/MM/yyyy');

  return { time, date };
};
export default dateParser;
