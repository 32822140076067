import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import PeriodicAdvertisements from './PeriodicAdvertisements';
import SpecialAdvertisements from './SpecialAdvertisements';

const { TabPane } = Tabs;

const BranchAdvertisements = () => {
  const { t } = useTranslation();

  return (
    <Tabs defaultActiveKey="1">
      <TabPane tab={t('buttons.periodicAdvertisements')} key="1">
        <PeriodicAdvertisements />
      </TabPane>
      <TabPane tab={t('buttons.specialAdvertisements')} key="2">
        <SpecialAdvertisements />
      </TabPane>
    </Tabs>
  );
};

export default BranchAdvertisements;
