import React, { useState, useCallback } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { DateSelector } from './Selectors';

const StartEndFilter = ({ handleFilterResult }) => {
  const { t } = useTranslation();
  const [chosenStartDate, setChosenStartDate] = useState();
  const [chosenEndDate, setChosenEndDate] = useState();

  const handleStartDateChange = useCallback(
    (startDate) => {
      setChosenStartDate(startDate);
    },
    [setChosenStartDate]
  );

  const handleEndDateChange = useCallback(
    (endDate) => {
      setChosenEndDate(endDate);
    },
    [setChosenEndDate]
  );

  const handleClickFilter = useCallback(() => {
    if (!(chosenStartDate && chosenEndDate)) {
      return;
    }

    handleFilterResult({
      startDate: chosenStartDate,
      endDate: chosenEndDate,
    });
  }, [chosenStartDate, chosenEndDate, handleFilterResult]);

  return (
    <Row gutter={(16, 16)}>
      <Col md={24} lg={24} xxl={24}>
        <Card>
          <Row gutter={(16, 16)}>
            <Col md={{ span: 3 }} xs={12} lg={{ span: 3 }} xxl={{ span: 2 }}>
              <div>
                <span>{t('labels.start')}</span>
                <DateSelector onChange={handleStartDateChange} />
              </div>
            </Col>
            <Col
              md={{ span: 3 }}
              xs={{ span: 12 }}
              lg={{ span: 3 }}
              xxl={{ span: 2 }}
            >
              <div>
                <span>{t('labels.end')}</span>
                <DateSelector onChange={handleEndDateChange} />
              </div>
            </Col>
            <Col md={{ span: 3 }} xs={{ span: 12 }} style={{ marginTop: 21 }}>
              <Button
                ghost
                type="primary"
                onClick={handleClickFilter}
                className="filterButton"
                disabled={!(chosenStartDate && chosenEndDate)}
              >
                {t('buttons.filter')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default StartEndFilter;
