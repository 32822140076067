import React, { useEffect, useState } from 'react';
import { Col, Row, Avatar, Input, Button, Form, message, Spin } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { USER_DETAIL, UPDATE_USER } from './gql';
import ServerWrong from '../../components/Result/ServerWrong';
import './userdetail.css';
import image from './img/2.jpg';
import MaskedInput from 'antd-mask-input';
import Load from '../../components/Load';

export default function UserDetail() {
  const [updateUser] = useMutation(UPDATE_USER);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [userId, setUserId] = useState();
  const [firstPassword, setFirstPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  useEffect(() => {
    if (
      firstPassword !== undefined &&
      confirmPassword !== undefined &&
      firstPassword === confirmPassword
    ) {
      setIsButtonDisabled(false);
    } else setIsButtonDisabled(true);
  }, [firstPassword, confirmPassword]);
  const handleSubmit = async () => {
    try {
      const name = await form.validateFields();
      updateUser({ variables: { where: { id: userId }, newValues: name } });
      message.success(t('messages.successUpdate'));
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };
  const { loading, error, data } = useQuery(USER_DETAIL, {
    variables: { where: { id: userId } },
  });
  useEffect(() => {
    const user = JSON.parse(window.localStorage.getItem('user'));
    setUserId(user.id);
  }, []);
  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading} indicator={<Load />}>
      <Form
        form={form}
        fields={[
          {
            name: ['firstName'],
            value: data?.user.firstName,
          },
          {
            name: ['lastName'],
            value: data?.user.lastName,
          },
          {
            name: ['email'],
            value: data?.user.email,
          },
          {
            name: ['primaryPhone'],
            value: data?.user.primaryPhone,
          },
          {
            name: ['secondaryPhone'],
            value: data?.user.secondaryPhone,
          },

          {
            name: ['description'],
            value: data?.user.description,
          },
          {
            name: ['username'],
            value: data?.user.username,
          },
          {
            name: ['role'],
            value: data?.user.role,
          },
        ]}
      >
        <Row gutter={(16, 16)} className="detailInput">
          <Col
            xs={24}
            sm={24}
            md={13}
            lg={24}
            xl={14}
            style={{
              background: '#FAFAFA',
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
            }}
          >
            <Row gutter={16}>
              <Col
                sm={{ span: 24, offset: 8 }}
                xs={{ span: 24, offset: 8 }}
                md={{ span: 24, offset: 10 }}
                style={{ marginTop: '20px' }}
              >
                <Avatar
                  size={70}
                  icon={<UserOutlined style={{ color: '#cd853f' }} />}
                />
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: '20px' }}>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('titles.username')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name="username">
                  <Input style={{ background: '#FAFAFA', borderRadius: 10 }} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('titles.name')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name={['firstName']}>
                  <Input
                    type="text"
                    style={{ background: '#FAFAFA', borderRadius: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('titles.surname')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name={['lastName']}>
                  <Input
                    type="text"
                    style={{ background: '#FAFAFA', borderRadius: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('titles.email')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name={['email']}>
                  <Input
                    type="text"
                    style={{ background: '#FAFAFA', borderRadius: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('titles.primaryPhone')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name={['primaryPhone']}>
                  <MaskedInput
                    mask="+90(111)1111111"
                    name="card"
                    size="14"
                    style={{ borderRadius: 10, background: '#FAFAFA' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('titles.secondaryPhone')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name={['secondaryPhone']}>
                  <MaskedInput
                    mask="+90(111)1111111"
                    name="card"
                    size="14"
                    style={{ borderRadius: 10, background: '#FAFAFA' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('form.password')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name={['password']}>
                  <Input
                    onChange={(e) => setFirstPassword(e.target.value)}
                    type="password"
                    defaultValue=""
                    style={{ background: '#FAFAFA', borderRadius: 10 }}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ sm: 24 }}
                md={{ span: 6, offset: 2 }}
              >
                <h1>{t('form.confirmpassword')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item>
                  <Input
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    type="password"
                    defaultValue=""
                    style={{ background: '#FAFAFA', borderRadius: 10 }}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col xs={{ span: 24 }} sm={24} md={{ span: 6, offset: 2 }}>
                <h1> {t('titles.role')}</h1>
              </Col>
              <Col xs={{ span: 24 }} sm={{ sm: 24 }} md={{ span: 15 }}>
                <Form.Item name={['role']}>
                  <Input
                    type="text"
                    style={{ background: '#FAFAFA', borderRadius: 10 }}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row style={{ marginTop: '20px' }}>
              <Col md={12} offset={10}>
                <Form.Item>
                  <Button
                    onClick={handleSubmit}
                    ghost
                    style={{
                      borderRadius: 10,
                      color: '#ff4d4f',
                      borderColor: '#ff4d4f',
                    }}
                    disabled={isButtonDisabled}
                  >
                    {t('buttons.update')}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col md={7} lg={10} sm={12} xs={24}>
            <div className="leftImg">
              <img
                src={image}
                alt="logo"
                style={{
                  marginTop: '25%',
                  marginLeft: '15%',
                  width: '400px',
                  height: '400px',
                  borderRadius: '50%',
                  boxShadow: '0 0 15px 5px',
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
