import { gql } from 'apollo-boost';

export const CREATE_JINGLE_POOL = gql`
  mutation createJinglePool($data: JinglePoolInput!) {
    createJinglePool(data: $data) {
      __typename
      ... on JinglePoolCreateSuccess {
        jinglePool {
          id
          name
          partners {
            id
            name
          }
          jingleCount
        }
      }
      ... on JinglePoolInvalidInputError {
        name
      }
    }
  }
`;

export const JINGLE_POOLS = gql`
  query jinglePools($condition: Condition) {
    jinglePools(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedJinglePoolResponse {
        data {
          id
          name
          partners {
            id
            name
          }
          jingleCount
        }
        totalCount
      }
    }
  }
`;

export const UPDATE_JINGLE_POOL = gql`
  mutation updateJinglePool($where: JSON!, $newValues: JinglePoolInput!) {
    updateJinglePool(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on JinglePoolInvalidInputError {
        name
      }
      ... on JinglePoolUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          partners {
            id
            name
          }
          jingleCount
        }
      }
    }
  }
`;

export const DELETE_JINGLE_POOL = gql`
  mutation deleteJinglePool($where: JSON!) {
    deleteJinglePool(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on JinglePoolDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          partners {
            id
            name
          }
          jingleCount
        }
      }
    }
  }
`;
