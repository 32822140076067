import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const GenderSelector = ({ onChange, initialrecord }) => {
  const [selectedOption, setSelectedOption] = useState();
  const { t } = useTranslation();
  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      defaultValue={initialrecord ? initialrecord.gender : t('titles.choose')}
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
    >
      <Option value="WOMAN">{t('columns.woman')}</Option>
      <Option value="MAN">{t('columns.man')}</Option>
      <Option value="OTHER">{t('columns.other')}</Option>
    </Select>
  );
};

export default GenderSelector;
