import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const popularityOptions = ['SUPER', 'A', 'B', 'C', 'D'];

const PopularitySelector = ({ onChange, initialrecord }) => {
  const [selectedOption, setSelectedOption] = useState();
  const { t } = useTranslation();
  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      defaultValue={initialrecord?.popularity}
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
      placeholder={t('messages.selectPopularity')}
    >
      <Option value={null} key={null}>
        {t('buttons.cancelOption')}
      </Option>
      {popularityOptions.map((option) => (
        <Option key={option} value={option}>
          {option}
        </Option>
      ))}
    </Select>
  );
};

export default PopularitySelector;
