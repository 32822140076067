/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable radix */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
const second = (x) => 1000 * x;
const minute = (x) => second(60) * x;
const hour = (x) => minute(60) * x;
// const day = (x) => hour(24) * x;
// const week = (x) => day(7) * x;

// use this when sending the hours to the backend
export const timeToMS = (time) => {
  const withoutTimezone =
    time.split(':').reduce((acc, r, index) => {
      if (index === 0) return (acc += parseInt(r) * 60 * 60);
      if (index === 1) return (acc += parseInt(r) * 60);
    }, 0) * 1000;

  return withoutTimezone;
};

// use this for formatting(humanizing) milliseconds
export const msToTime = (ms) => {
  const hours = Math.floor(ms / hour(1));
  const minutes = (ms % hour(1)) / minute(1);
  return `${hours >= 10 ? '' : 0}${hours}:${minutes >= 10 ? '' : 0}${minutes}`;
};

export const msToTimeWithSecond = (ms) => {
  const hours = Math.floor(ms / hour(1));
  const minutes = Math.floor((ms % hour(1)) / minute(1));
  const seconds = Math.floor((ms % minute(1)) / 1000);
  return `${hours >= 10 ? '' : 0}${hours}:${minutes >= 10 ? '' : 0}${minutes}:${
    seconds >= 10 ? '' : 0
  }${seconds}`;
};
