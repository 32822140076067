import { Select, notification, Button } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SEARCH_PARTNER_BRANCHES from '../PartnerBranchSelector/gql';
import {
  SEARCH_PARTNERS_WITH_PLAYLIST,
  READ_PLAYLISTS_FOR_PUBLISH,
} from './gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const { Option } = Select;

const openNotification = (t) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      {t('buttons.confirm')}
    </Button>
  );
  notification.warning({
    duration: 0,
    message: t('warnings.allBranchesSelected'),
    description: t('warnings.chosenPlaylistAffectAllBranches'),
    btn,
    key,
  });
};

const PartnerAndBranchWithPlaylistSelector = ({ onChange }) => {
  // #region hooks
  const { t } = useTranslation();
  const { role, partnerId } = userInfoGetter();
  const [partnerCondition, setPartnerCondition] = useState({ limit: -1 });
  const [selectedPartner, setSelectedPartner] = useState();

  const [branchCondition, setBranchCondition] = useState();
  const [selectedBranches, setSelectedBranches] = useState([]);

  const [playlistCondition, setPlaylistCondition] = useState();
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  // #endregion hooks

  // #region  gql queries
  const {
    loading: partnerLoading,
    error: partnerError,
    data: partnerData,
  } = useQuery(SEARCH_PARTNERS_WITH_PLAYLIST, {
    variables: { condition: partnerCondition },
  });

  const {
    loading: branchLoading,
    error: branchError,
    data: branchData,
  } = useQuery(SEARCH_PARTNER_BRANCHES, {
    variables: { condition: branchCondition },
    skip: !branchCondition,
  });

  const {
    loading: playlistLoading,
    error: playlistError,
    data: playlistData,
  } = useQuery(READ_PLAYLISTS_FOR_PUBLISH, {
    variables: { condition: playlistCondition },
    skip: !playlistCondition,
  });
  // #endregion gql queries

  // #region handles

  // search handles
  const handlePartnerSearch = useCallback(
    (input) => {
      if (input) {
        setPartnerCondition({
          limit: 5,
          where: {
            _or: {
              name: { _iLike: `%${input}%` },
            },
          },
        });
      }
    },
    [setPartnerCondition]
  );

  // select handles
  const handlePartnerChange = useCallback(
    (value) => {
      setSelectedPartner(value);
      setSelectedBranches();
      setSelectedPlaylist();
      setBranchCondition({
        limit: -1,
        where: {
          partnerId: value,
        },
      });
      setPlaylistCondition({
        limit: -1,
        where: {
          partnerId: value,
        },
      });
    },
    [setSelectedPartner, setBranchCondition]
  );

  useEffect(() => {
    if (role === 'PARTNER') {
      handlePartnerChange(partnerId);
    }
  }, [handlePartnerChange, partnerId, role]);

  const handleBranchChange = useCallback(
    (values) => {
      if (values.includes('all')) {
        // deselect all
        if (
          selectedBranches &&
          selectedBranches.length === branchData?.partnerBranches?.data?.length
        ) {
          setSelectedBranches();
        }
        // select all
        else {
          openNotification(t);
          const ids = branchData?.partnerBranches?.data?.map(
            (datum) => datum.id
          );
          setSelectedBranches(ids);
        }
        setSelectedPlaylist();
      }
      // select one
      else {
        setSelectedBranches(values);
        setSelectedPlaylist();
      }
    },
    [t, branchData, selectedBranches, setSelectedBranches]
  );

  const handlePlaylistChange = useCallback(
    (value) => {
      setSelectedPlaylist(value);
      onChange({
        partnerId: selectedPartner,
        partnerBranchIds: selectedBranches,
        playlistId: value,
      });
    },
    [setSelectedPlaylist, onChange, selectedPartner, selectedBranches]
  );
  // #endregion handles

  if (partnerError || branchError || playlistError) {
    return t('messages.somethingBad');
  }

  return (
    <div>
      {role === 'PARTNER' ? (
        <Select
          disabled
          loading={partnerLoading}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          value={selectedPartner}
          placeholder={t('placeholders.selectPartner')}
          style={{ width: '100%' }}
          onSearch={handlePartnerSearch}
          onChange={handlePartnerChange}
        >
          {partnerData?.partners.data.map((partner) => (
            <Option value={partner.id} key={partner.id}>
              {' '}
              {partner.name}
            </Option>
          ))}
        </Select>
      ) : (
        <Select
          loading={partnerLoading}
          showSearch
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          value={selectedPartner}
          placeholder={t('placeholders.selectPartner')}
          style={{ width: '100%' }}
          onSearch={handlePartnerSearch}
          onChange={handlePartnerChange}
        >
          {partnerData?.partners?.data &&
            partnerData.partners.data.map((partner) => (
              <Option value={partner.id} key={partner.id}>
                {partner.name}
              </Option>
            ))}
        </Select>
      )}

      {selectedPartner && (
        <>
          <Select
            mode="multiple"
            loading={branchLoading}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            value={selectedBranches}
            placeholder={t('placeholders.selectBranch')}
            style={{ width: '100%', marginTop: 20 }}
            onChange={handleBranchChange}
          >
            <Option key="all" value="all">
              {`---${t('buttons.selectAll')}---`}
            </Option>
            {branchData?.partnerBranches?.data?.map((branch) => (
              <Option value={branch.id} key={branch.id}>
                {branch.name}
              </Option>
            ))}
          </Select>
          <Select
            loading={playlistLoading}
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            value={selectedPlaylist}
            placeholder={t('placeholders.selectPlaylist')}
            style={{ width: '100%', marginTop: 20 }}
            onChange={handlePlaylistChange}
          >
            {playlistData?.playlists?.data?.map((playlist) => (
              <Option value={playlist.id} key={playlist.id}>
                {playlist.name}
              </Option>
            ))}
          </Select>
        </>
      )}
    </div>
  );
};

export default PartnerAndBranchWithPlaylistSelector;
