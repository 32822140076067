/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useCallback } from 'react';
import { Row, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import IntegratedEditableTable from '../../../components/IntegratedEditableTable/Index';
import { CREATE_JINGLE, JINGLES, UPDATE_JINGLE, DELETE_JINGLE } from './gql';
import ModalFormBuilder from '../../../components/ModalFormBuilder';
import humanizeTimestamp from '../../../utils.js/humanizeTimestamp';
import { BACKEND_URL } from '../../../constants';
import useToggle from '../../../hooks/useToggle';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const mimeTypeGroups = [
  'audio/aac',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
];
const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.jingle'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'jinglePoolId',
    label: t('columns.jinglePoolName'),
    inputType: 'jinglePoolSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },

  {
    name: 'file',
    label: t('titles.file'),
    inputType: 'songFileUploadInput',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        validator: (_, value) => {
          const fileType = value.type;
          return mimeTypeGroups.includes(fileType)
            ? Promise.resolve()
            : Promise.reject(t('messages.invalideFile'));
        },
      },
      {
        validator: (_, value) => {
          const fileSize = value.size;
          return fileSize < 104857600
            ? Promise.resolve()
            : Promise.reject(t('messages.fileSmaller'));
        },
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('columns.jingleName'),
    dataIndex: 'name',
    key: 'name',
    width: '13%',
    editable: true,
  },
  {
    title: t('columns.jinglePoolName'),
    dataIndex: 'jinglePoolId',
    key: 'jinglePoolId',
    inputType: 'jinglePoolSelector',
    width: '13%',
    editable: true,
    render: (jinglePoolId, record) => {
      return record.jinglePoolName || t('warnings.noData');
    },
  },
  {
    title: t('titles.file'),
    dataIndex: 'fileId',
    key: 'fileId',
    inputType: 'songFileUploadInput',
    editable: false,
    width: 80,
    render: (fileId, record) => {
      return (
        <audio
          controls
          controlsList="nodownload"
          style={{ width: '220px' }}
          src={`${BACKEND_URL + record?.file?.address}`}
        />
      );
    },
  },
  {
    title: t('columns.time'),
    dataIndex: 'duration',
    key: 'duration',
    width: '8%',
    render: (file, record) => {
      if (record?.duration) {
        const durations = record?.duration / 1000;
        return moment
          .duration(durations.toFixed(2), 'seconds')
          .format(
            `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
              'labels.seconds'
            )}]`
          );
      }

      return t('warnings.noData');
    },
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '14%',
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '14%',
  },
];

export default function Jingle() {
  const { role, partnerId } = userInfoGetter();
  const token = localStorage.getItem('token');
  // hooks
  const { t } = useTranslation();
  const [visibility, toggleModal] = useToggle(false);

  const beforeUpload = useCallback((formValues) => {
    const { file } = formValues;
    delete formValues.file;

    return { variables: { data: formValues, file } };
  }, []);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t, token);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.jingles?.data?.map((jingle) => ({
      id: jingle.id,
      key: jingle.key,
      name: jingle.name,
      jinglePoolName: jingle?.jinglePool?.name,
      jinglePoolId: jingle?.jinglePool?.id,
      file: jingle.file,
      duration: jingle?.file?.duration,

      createdAt: humanizeTimestamp(jingle.createdAt),
      updatedAt: humanizeTimestamp(jingle.updatedAt),
    }));

    return {
      ...response,
      jingles: { ...response?.jingles, data: newData },
    };
  }, []);

  return (
    <Row>
      <Row gutter={16}>
        <Divider orientation="center">{t('columns.jingle')}</Divider>
        <Col span={5} md={24} sm={24} xs={24}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleModal}
            modalTitle={t('titles.newJingle')}
            showModalButtonLabel={t('titles.newJingle')}
            fields={fields}
            postReq={CREATE_JINGLE}
            postResIndex="createJingle"
            postResFieldForUpdating="jingle"
            willBeUpdatedQuery={JINGLES}
            willBeUpdatedQueryName="jingles"
            beforeUpload={beforeUpload}
            showOpenButton={role !== 'PARTNER'}
          />
        </Col>
        <Col style={{ marginTop: 10 }} md={24} lg={24} sm={24} xs={24}>
          <IntegratedEditableTable
            columns={columns}
            getReq={JINGLES}
            getResIndex="jingles"
            updateReq={UPDATE_JINGLE}
            updateResIndex="updateJingle"
            deleteReq={DELETE_JINGLE}
            deleteResIndex="deleteJingle"
            afterGetFunc={afterGetFunc}
            filterColumns={{
              jingle: ['name'],
              jinglePool: ['name'],
              partner: ['name'],
            }}
            operationsDisabledStatus={role === 'PARTNER'}
            upperGetReqCondition={
              role === 'PARTNER'
                ? {
                    limit: 10,
                    offset: 0,
                    order: [['createdAt', 'desc']],
                    where: {
                      partnerId,
                    },
                  }
                : {
                    limit: 10,
                    offset: 0,
                    order: [['createdAt', 'desc']],
                  }
            }
          />
        </Col>
      </Row>
    </Row>
  );
}
