import { gql } from 'apollo-boost';

export const RELEASED_PERIODIC_ADVERTISEMENTS_OF_BRANCH = gql`
  query partnerBranches(
    $condition: Condition
    $start: Date!
    $end: Date!
    $isReleased: Boolean!
  ) {
    partnerBranches(condition: $condition) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerBranchResponse {
        data {
          id
          periodicAdvertisements(
            start: $start
            end: $end
            isReleased: $isReleased
          ) {
            id
            startDate
            endDate
            type
            advertisementSet {
              name
              type
            }
            advertisementPool {
              name
              file {
                address
                duration
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

export const RELEASED_SPECIAL_ADVERTISEMENTS_OF_BRANCH = gql`
  query partnerBranches(
    $condition: Condition
    $start: Date!
    $end: Date!
    $isReleased: Boolean!
  ) {
    partnerBranches(condition: $condition) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerBranchResponse {
        data {
          id
          specialAdvertisements(
            start: $start
            end: $end
            isReleased: $isReleased
          ) {
            id
            date
            advertisementPool {
              id
              name
              file {
                address
                duration
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;
