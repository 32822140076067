/* eslint-disable no-new */
import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';

const BarChart = ({ labels, chartData, title }) => {
  const chartRef = useRef();

  useEffect(() => {
    if (chartRef.current) {
      new Chart(chartRef.current, {
        type: 'bar',
        data: {
          labels,
          datasets: chartData,
        },
        options: {
          title: {
            display: true,
            text: title,
          },
          scales: {
            xAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    }
  }, [labels, chartData, title]);

  return <canvas height={200} ref={chartRef} />;
};

export default BarChart;
