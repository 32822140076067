import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SEARCH_JINGLE_POOLS } from './gql';

const { Option } = Select;

const JinglePoolSelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  // hooks
  const [selectedOption, setSelectedOption] = useState();
  const [condition, setCondition] = useState({
    limit: -1,
  });
  const { loading, error, data } = useQuery(SEARCH_JINGLE_POOLS, {
    variables: { condition },
    fetchPolicy: 'network-only',
  });

  const handleSearch = useCallback((input) => {
    if (input) {
      setCondition({
        limit: -1,
        where: {
          _or: {
            name: { _iLike: `%${input}%` },
          },
        },
      });
    }
  }, []);

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <Select
      defaultValue={initialrecord?.jinglePoolId}
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.selectPlaylistKind')}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
      filterOption={false}
    >
      {data?.jinglePools?.data &&
        data.jinglePools.data.map((jinglePool) => (
          <Option value={jinglePool.id} key={jinglePool.id}>
            {jinglePool.name}
          </Option>
        ))}
    </Select>
  );
};

export default JinglePoolSelector;
