/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useCallback } from 'react';
import { Row, Col, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import {
  CREATE_ADVERTISEMENT_SET,
  ADVERTISEMENT_SETS,
  DELETE_ADVERTISEMENT_SET,
  UPDATE_ADVERTISEMENT_SET,
} from './gql';
import { msToTime } from '../../utils.js/adsSetHelper';
import '../../components/MainLayout/style.css';
import userInfoGetter from '../../utils.js/userInfoGetter';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.advertisementName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'type',
    label: t('titles.advertisementType'),
    inputType: 'periodicAdvertisementSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'hours',
    label: t('titles.advertisementItems'),
    inputType: 'setSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.advertisementName'),
    dataIndex: 'name',
    key: 'name',
    width: '30%',
    editable: true,
  },
  {
    title: t('titles.advertisementType'),
    dataIndex: 'type',
    key: 'type',
    editable: true,
    width: '20%',
    inputType: 'periodicAdvertisementSelector',
    filters: [
      {
        text: 'Standart',
        value: 'STANDARD',
      },
      {
        text: 'Ara',
        value: 'INTERN',
      },
    ],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.type.indexOf(value) === 0,
    render: (type) => t(`columns.${type.toLowerCase()}`),
  },
  {
    title: t('titles.advertisementItems'),
    dataIndex: 'hours',
    key: 'hours',
    editable: false,
    width: '40%',
    inputType: 'setSelector',
    render: (_, record) => {
      const hours = record?.formattedHours;
      const sortedHours = hours.sort((a, b) => a - b);
      return (
        <Row>
          {sortedHours.map((h, index) => (
            <Col key={index} md={{ span: 7 }} xs={{ span: 10 }}>
              <li className="hours" key={h}>
                {h}
              </li>
            </Col>
          ))}
        </Row>
      );
    },
  },
];

export default function AdvertisementSet() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);
  const { role } = userInfoGetter();
  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    // if success
    if (
      response?.advertisementSets?.__typename ===
      'PaginatedAdvertisementSetResponse'
    ) {
      const newDataWithFormattedHours = response?.advertisementSets?.data?.map(
        (datum) => {
          const sortedHours = datum?.hours.sort((a, b) => a - b);
          const formattedHours = sortedHours.map((hour) => msToTime(hour));
          const hours = datum?.hours?.map((hour) => String(hour));

          return { ...datum, hours, formattedHours };
        }
      );
      return {
        ...response,
        advertisementSets: {
          ...response.advertisementSets,
          data: newDataWithFormattedHours,
        },
      };
    }
    // if response has an any error
    return response;
  }, []);

  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('titles.advertisementSet')}</Divider>

      <Col span={24} style={{ marginTop: '20px' }}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newAdvertisementSet')}
          showModalButtonLabel={t('titles.newAdvertisementSet')}
          fields={fields}
          postReq={CREATE_ADVERTISEMENT_SET}
          postResIndex="createAdvertisementSet"
          postResFieldForUpdating="advertisementSet"
          willBeUpdatedQuery={ADVERTISEMENT_SETS}
          willBeUpdatedQueryName="advertisementSets"
          showOpenButton={role !== 'PARTNER'}
        />
      </Col>
      <Col md={24} lg={24} xs={24} sm={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={ADVERTISEMENT_SETS}
          getResIndex="advertisementSets"
          deleteReq={DELETE_ADVERTISEMENT_SET}
          deleteResIndex="deleteAdvertisementSet"
          updateReq={UPDATE_ADVERTISEMENT_SET}
          updateResIndex="updateAdvertisementSet"
          filterColumns={{
            advertisementSet: ['name', 'type'],
          }}
          afterGetFunc={afterGetFunc}
          operationsDisabledStatus={role === 'PARTNER'}
        />
      </Col>
    </Row>
  );
}
