import { gql } from 'apollo-boost';

export const CREATE_ADVERTISEMENT_POOL = gql`
  mutation createAdvertisementPool(
    $data: AdvertisementPoolInput!
    $file: Upload!
  ) {
    createAdvertisementPool(data: $data, file: $file) {
      __typename
      ... on AdvertisementPoolCreateSuccess {
        advertisementPool {
          id
          name
          isPlanned
          partner {
            __typename
            ... on Partner {
              id
              name
            }
          }
          file {
            id
            filename
            path
            duration
            address
          }
          createdAt
          updatedAt
        }
      }
      ... on AdvertisementPoolInvalidInputError {
        name
        partnerId
        file
      }
    }
  }
`;

export const ADVERTISEMENT_POOLS = gql`
  query advertisementPools($condition: Condition) {
    advertisementPools(condition: $condition) {
      __typename
      ... on PaginatedAdvertisementPoolResponse {
        data {
          id
          name
          isPlanned
          createdAt
          updatedAt
          partner {
            __typename
            ... on Partner {
              id
              name
            }
          }
          file {
            id
            filename
            path
            duration
            address
          }
        }
        totalCount
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_ADVERTISEMENT_POOL = gql`
  mutation updateAdvertisementPool(
    $where: JSON!
    $newValues: AdvertisementPoolUpdateInput!
    $file: Upload
  ) {
    updateAdvertisementPool(where: $where, newValues: $newValues, file: $file) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on AdvertisementPoolInvalidInputError {
        name
        partnerId
        file
      }
      ... on AdvertisementPoolUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          isPlanned
          partner {
            __typename
            ... on Partner {
              id
              name
            }
          }
          file {
            id
            filename
            address
            duration
            path
          }
          createdAt
          updatedAt
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_ADVERTISEMENT_POOL = gql`
  mutation deleteAdvertisementPool($where: JSON!) {
    deleteAdvertisementPool(where: $where) {
      __typename

      ... on DeleteError {
        message
      }

      ... on AdvertisementPoolDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          isPlanned
          createdAt
          updatedAt
          file {
            id
            filename
            address
          }
          partner {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Partner {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const CREATE_PERIODIC_ADVERTISEMENT = gql`
  mutation createPeriodicAdvertisement($data: PeriodicAdvertisementInput!) {
    createPeriodicAdvertisement(data: $data) {
      __typename

      ... on PeriodicAdvertisementInvalidInputError {
        startDate
        endDate
        type
        advertisementPoolId
        advertisementSetId
        partnerBranchIds
      }

      ... on PeriodicAdvertisementCreateSuccess {
        periodicAdvertisement {
          id

          isReleased
          startDate
          endDate
          type
          createdAt
          updatedAt
          advertisementPool {
            id
            name
          }
          advertisementSet {
            id
            name
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
      }
    }
  }
`;

export const CREATE_SPECIAL_ADVERTISEMENT = gql`
  mutation createSpecialAdvertisement($data: SpecialAdvertisementInput!) {
    createSpecialAdvertisement(data: $data) {
      __typename
      ... on SpecialAdvertisementInvalidInputError {
        date
        advertisementPoolId
        partnerBranchIds
      }

      ... on SpecialAdvertisementCreateSuccess {
        specialAdvertisement {
          id
          createdAt
          updatedAt
          isReleased
          date
          partnerBranches {
            id
            name
            partner {
              id
            }
          }
          areAllBranchesSelected
          advertisementPool {
            id
            name
            isPlanned
            file {
              id
              filename
              mimetype
              path
              duration
              address
            }
            releasedCount
            releasedDuration
          }
        }
      }
    }
  }
`;
