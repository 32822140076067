import { gql } from 'apollo-boost';

export const CREATE_PARTNER_SONG_POOL_RELATION = gql`
  mutation addSongPoolToPartner($data: PartnerSongPoolAppendRelation!) {
    addSongPoolToPartner(data: $data) {
      __typename
      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;

export const PARTNER_SONG_POOLS = gql`
  query partnerSongPools($where: JSON!) {
    partner(where: $where) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on Partner {
        id
        name
        songPools {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_PARTNER_SONG_POOL_RELATION = gql`
  mutation deleteSongPoolFromPartner($data: PartnerSongPoolDeleteRelation!) {
    deleteSongPoolFromPartner(data: $data) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;
