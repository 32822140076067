/* eslint-disable consistent-return */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Calendar, Alert, Button } from 'antd';
import { useClearCache } from 'react-clear-cache';
import RoutesCreator from '../config/routes';
import PrivateRoute from './PrivateRoute';
import NotAuthorized from '../components/Result/NotAuthorized';
import MainLayout from '../components/MainLayout';
import NotExist from '../components/Result/NotExist';
import VerticalTimeline from '../containers/VerticalTimeline';
import UserDetail from '../containers/UserDetail';
import Sector from '../containers/Sector';
import SectorCategory from '../containers/SectorCategory';
import Welcome from '../containers/Welcome';

const AppRouter = () => {
  const { t } = useTranslation();
  const routes = RoutesCreator(t);
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const roles = Object.keys(routes.privateRoutes);

  return (
    <>
      {!isLatestVersion ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Alert
            message={
              <div>
                <h5>{t('messages.appHasNewVersion')}</h5>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Button type="primary" onClick={() => emptyCacheStorage()}>
                    {t('buttons.update')}
                  </Button>
                </div>
              </div>
            }
            type="warning"
            closable={false}
          />
        </div>
      ) : (
        <Switch>
          {routes.publicRoutes.map((publicRoute) => (
            <Route
              key={publicRoute.id}
              exact
              path={publicRoute.path}
              component={publicRoute.component}
            />
          ))}

          <Route
            key="notAuthorized"
            exact
            path="/notAuthorized"
            render={(props) => (
              <MainLayout>
                <NotAuthorized {...props} />
              </MainLayout>
            )}
          />

          <PrivateRoute
            key="verticalTimeline"
            path="/verticalTimeline"
            component={VerticalTimeline}
            pageName="verticalTimeline"
          />
          <PrivateRoute
            key="sector"
            path="/sector"
            component={Sector}
            pageName="sector"
          />
          <PrivateRoute
            key="sectorCategory"
            path="/sectorCategory"
            component={SectorCategory}
            pageName="sectorCategory"
          />

          <PrivateRoute
            key="userDetail"
            path="/userDetail"
            component={UserDetail}
            pageName="userDetail"
          />
          <PrivateRoute
            key="welcome"
            path="/welcome"
            component={Welcome}
            pageName="welcome"
          />
          <PrivateRoute
            key="calendar"
            path="/calendar"
            component={Calendar}
            pageName="calendar"
          />

          {roles.map((role) => {
            const roleRoutes = routes.privateRoutes[role];

            return roleRoutes.map((data) => {
              if (data.subRoutes) {
                return data.subRoutes.map((subItem) => {
                  if (subItem.childRoutes) {
                    return subItem.childRoutes.map((childItem) => {
                      return (
                        <PrivateRoute
                          key={childItem.id}
                          path={childItem.path}
                          component={childItem.component}
                          pageName={childItem.pageName}
                        />
                      );
                    });
                  }
                  return (
                    <PrivateRoute
                      key={subItem.id}
                      path={subItem.path}
                      component={subItem.component}
                      pageName={subItem.pageName}
                    />
                  );
                });
              }
              return (
                <PrivateRoute
                  key={data.id}
                  path={data.path}
                  component={data.component}
                  pageName={data.pageName}
                />
              );
            });
          })}

          <Route key="notExist" path="*" component={NotExist} />
        </Switch>
      )}
    </>
  );
};
export default AppRouter;
