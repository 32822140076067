import React, { useCallback } from 'react';
import CustomSelector from '../CustomSelector';
import SEARCH_ADVERTISEMENT_SETS from './gql';

const AdvertisementSetSelector = (props) => {
  const createCustomWhere = useCallback(
    (input = '') => {
      if (props.propsForSelector.setType) {
        return {
          name: { _like: `%${input}%` },
          type: props.propsForSelector.setType,
        };
      }
      return {
        name: { _like: `%${input}%` },
      };
    },
    [props]
  );

  return (
    <CustomSelector
      defaultValueIndex="advertisementSetId"
      getReq={SEARCH_ADVERTISEMENT_SETS}
      customWhereCreator={createCustomWhere}
      placeholder="selectAdvertisementSet"
      getResIndex="advertisementSets"
      customLimit={-1}
      {...props}
    />
  );
};

export default AdvertisementSetSelector;
