import { gql } from 'apollo-boost';

export const CREATE_PARTNER = gql`
  mutation createPartner($data: PartnerInput!) {
    createPartner(data: $data) {
      __typename
      ... on PartnerInvalidInputError {
        name
        userId
      }
      ... on PartnerCreateSuccess {
        partner {
          id
          name
          description
          createdAt
          updatedAt
          partnerSector {
            __typename
            ... on PartnerSector {
              id
              name
            }
          }
          user {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on User {
              id
              username
            }
          }
        }
      }
    }
  }
`;

export const PARTNERS = gql`
  query partners($condition: Condition) {
    partners(condition: $condition) {
      __typename
      ... on PaginatedPartnerResponse {
        data {
          id
          name
          description
          createdAt
          updatedAt
          partnerSector {
            __typename
            ... on PartnerSector {
              id
              name
            }
          }
          user {
            __typename
            ... on User {
              id
              username
            }

            ... on NotFoundResult {
              notFoundErrorMessage
            }
          }
        }
        totalCount
      }
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_PARTNER = gql`
  mutation updatePartner($where: JSON!, $newValues: PartnerUpdateInput!) {
    updatePartner(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PartnerInvalidInputError {
        name
        userId
      }

      ... on PartnerUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          description
          createdAt
          updatedAt
          partnerSector {
            __typename
            ... on PartnerSector {
              id
              name
            }
          }
          user {
            __typename
            ... on User {
              id
              username
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PARTNER = gql`
  mutation deletePartner($where: JSON!) {
    deletePartner(where: $where) {
      __typename

      ... on DeleteError {
        message
      }

      ... on PartnerDeleteSuccess {
        affectedRows
        deletedRows {
          id
        }
      }
    }
  }
`;
