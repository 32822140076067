import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, message } from 'antd';
import moment from 'moment';
import IntegratedEditableTable from '../../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../../components/ModalFormBuilder';
import useToggle from '../../../hooks/useToggle';
import {
  RELEASED_JINGLE_POOLS,
  UPDATE_RELEASED_JINGLE_POOL,
  DELETE_RELEASED_JINGLE_POOL,
  CREATE_RELEASED_JINGLE_POOL,
} from './gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';
import dateParser from '../../../utils.js/dateParser';

const fieldsCreator = (t) => [
  {
    name: 'partnerBranchSection',
    label: t('titles.partnerName'),
    inputType: 'partnerAndBranchMultiSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'period',
    label: t('labels.inHowManySongs'),
    inputType: 'inputNumberSelector',
    propsForSelector: {
      min: 1,
      max: 50,
    },
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'start',
    label: t('labels.startDate'),
    inputType: 'dateWithTimeSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'end',
    label: t('labels.endDate'),
    inputType: 'dateWithTimeSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.partnerName'),
    dataIndex: 'partnerId',
    key: 'partnerId',
    width: '14%',
    inputType: 'partnerSelector',
    editable: false,
    render: (_, record) => {
      return record?.partnerName || t('warnings.noData');
    },
  },
  {
    title: t('labels.partnerBranch'),
    dataIndex: 'partnerBranchIds',
    key: 'partnerBranchIds',
    width: '20%',
    inputType: 'partnerBranchSelector',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (_, record) => {
      if (record?.areAllBranchesSelected) {
        return <h4 key="all">{t('labels.allBranchesSelected')}</h4>;
      }
      return record?.partnerBranches?.map((branch) => (
        <span style={{ display: 'block' }} key={branch.id}>
          {branch.name}
        </span>
      ));
    },
  },
  {
    title: t('columns.jinglePools'),
    dataIndex: 'jinglePoolId',
    key: 'jinglePoolId',
    width: '15%',
    inputType: 'jinglePoolSelector',
    editable: false,
    render: (_, record) => {
      return record?.jinglePoolName || t('warnings.noData');
    },
  },
  {
    title: t('labels.inHowManySongs'),
    dataIndex: 'period',
    key: 'period',
    width: '11%',
    inputType: 'inputNumberSelector',
    propsForSelector: {
      min: 1,
      max: 50,
    },
    editable: true,
  },

  {
    title: t('labels.startDate'),
    dataIndex: 'start',
    key: 'start',
    inputType: 'dateWithTimeSelector',
    propsForSelector: { fieldName: 'start' },
    editable: true,

    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      const { date, time } = dateParser(value);

      return [date, '  ', time];
    },
  },
  {
    title: t('labels.endDate'),
    dataIndex: 'end',
    key: 'end',
    inputType: 'dateWithTimeSelector',
    propsForSelector: { fieldName: 'end' },
    editable: true,

    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      const { date, time } = dateParser(value);

      return [date, '  ', time];
    },
  },
];

const ReleasedJinglePool = () => {
  const { partnerId, role } = userInfoGetter();
  const { t } = useTranslation();
  const [visibility, toggleModal] = useToggle(false);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const releasedJinglePoolsData = response?.releasedJinglePools?.data;
    const newData = releasedJinglePoolsData?.map((releasedJinglePool) => {
      return {
        ...releasedJinglePool,
        partnerBranchIds: releasedJinglePool?.partnerBranches?.map(
          (branch) => branch.id
        ),
        partnerName: releasedJinglePool?.partner?.name,
        partnerId: releasedJinglePool?.partner?.id,
        jinglePoolName: releasedJinglePool?.jinglePool?.name,
        jinglePoolId: releasedJinglePool?.jinglePool?.id,
      };
    });

    return {
      ...response,
      releasedJinglePools: {
        ...response?.releasedJinglePools,
        data: newData,
      },
    };
  }, []);

  const beforeUpload = useCallback((req) => {
    req.end = moment(req.end).utcOffset(0, true).valueOf();
    req.start = moment(req.start).utcOffset(0, true).valueOf();

    const newReq = {
      ...req.partnerBranchSection,
      period: req.period,
      start: req.start,
      end: req.end,
    };
    return { variables: { data: newReq } };
  }, []);

  const beforeSave = useCallback(
    (recordId, record) => {
      const newRecord = {
        ...record,
        // end: moment(record.end).utcOffset(0, true).valueOf(),
        // start: moment(record.start).utcOffset(0, true).valueOf(),
      };
      if (newRecord.start >= newRecord.end) {
        message.error(t('warnings.startMustBeSmallerThanEnd'));
        return null;
      }

      return {
        variables: {
          where: { id: recordId },
          newValues: newRecord,
        },
      };
    },
    [t]
  );

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="center">{t('header.releasedJingle')}</Divider>
        <Col span={5} md={24} sm={24} xs={24}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleModal}
            modalTitle={t('header.releasedJingle')}
            showModalButtonLabel={t('header.releasedJingle')}
            fields={fields}
            postReq={CREATE_RELEASED_JINGLE_POOL}
            postResIndex="createReleasedJinglePool"
            postResFieldForUpdating="releasedJinglePool"
            willBeUpdatedQuery={RELEASED_JINGLE_POOLS}
            willBeUpdatedQueryName="releasedJinglePools"
            willBeUpdatedQueryExtraVariable={
              role === 'PARTNER' ? { where: { partnerId } } : undefined
            }
            beforeUpload={beforeUpload}
          />
        </Col>
        <Col style={{ marginTop: 10 }} md={24} lg={24} sm={24} xs={24}>
          <IntegratedEditableTable
            columns={columns}
            getReq={RELEASED_JINGLE_POOLS}
            getResIndex="releasedJinglePools"
            deleteReq={DELETE_RELEASED_JINGLE_POOL}
            deleteResIndex="deleteReleasedJinglePool"
            updateReq={UPDATE_RELEASED_JINGLE_POOL}
            updateResIndex="updateReleasedJinglePool"
            willBeUpdatedQuery={RELEASED_JINGLE_POOLS}
            willBeUpdatedQueryName="releasedJinglePools"
            afterGetFunc={afterGetFunc}
            fetchPolicy="network-only"
            beforeSave={beforeSave}
            filterColumns={{
              jinglePool: ['name'],
              partner: ['name'],
            }}
            upperGetReqCondition={
              role === 'PARTNER'
                ? {
                    limit: 10,
                    offset: 0,
                    order: [['createdAt', 'desc']],
                    where: {
                      partnerId,
                    },
                  }
                : {
                    limit: 10,
                    offset: 0,
                    order: [['createdAt', 'desc']],
                  }
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default ReleasedJinglePool;
