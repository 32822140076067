import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SEARCH_PLAYLIST from './gql';

const { Option } = Select;

const PlaylistSelector = ({ onChange, initialrecord, customWhere = {} }) => {
  // const { role } = userInfoGetter();
  // hooks
  const { t } = useTranslation();
  const [selectedOption, setSelectedOption] = useState();

  const [condition, setCondition] = useState({
    limit: -1,
    where: customWhere,
  });

  const { loading, error, data } = useQuery(SEARCH_PLAYLIST, {
    variables: { condition },
    fetchPolicy: 'network-only',
  });

  // useEffect(() => {
  //   if (role === 'PARTNER') {
  //     setCondition({ where: { partnerId } });
  //   }
  // }, [role, partnerId]);

  const handleSearch = useCallback(
    (input) => {
      if (input) {
        setCondition({
          limit: -1,
          where: {
            ...customWhere,
            _or: {
              name: { _iLike: `%${input}%` },
            },
          },
        });
      }
    },
    [customWhere]
  );

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <Select
      defaultValue={initialrecord?.playlistId}
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.selectPlaylistKind')}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
      filterOption={false}
    >
      {data?.playlists?.data &&
        data.playlists.data.map((playlist) => (
          <Option value={playlist.id} key={playlist.id}>
            {playlist.name}
          </Option>
        ))}
    </Select>
  );
};

export default PlaylistSelector;
