/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import TopThreeSongs from './TopThreeSongs';
import SoundTypesPublishCount from './SoundTypesPublishCounts';
import AdsTypesPublishCount from './AdsTypesPublishCount';

const InfoBoxes = () => {
  const { t } = useTranslation();
  const [totalSongPublishCount, setTotalSongPublishCount] = useState();
  const [totalAdsPublishCount, setTotalAdsPublishCount] = useState();
  const [
    totalSoundTypesPublishCount,
    setTotalSoundTypesPublishCount,
  ] = useState();
  const [totalPublicationsCount, setTotalPublicationsCount] = useState();

  useEffect(() => {
    const safeTotalSongPublishCount = totalSongPublishCount || 0;
    const safeTotalSoundTypesPublishCount = totalSoundTypesPublishCount || 0;
    const safeTotalAdsPublishCount = totalAdsPublishCount || 0;

    setTotalPublicationsCount(
      safeTotalSongPublishCount +
        safeTotalSoundTypesPublishCount +
        safeTotalAdsPublishCount
    );
  }, [
    setTotalPublicationsCount,
    totalSongPublishCount,
    totalSoundTypesPublishCount,
    totalAdsPublishCount,
  ]);

  return (
    <Row gutter={24}>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} lg={6} xxl={6}>
        <TopThreeSongs setTotalSongPublishCount={setTotalSongPublishCount} />
      </Col>
      <Col xs={{ span: 24 }} sm={24} md={{ span: 6 }} lg={6} xxl={6}>
        <SoundTypesPublishCount
          setTotalSoundTypesPublishCount={setTotalSoundTypesPublishCount}
        />
      </Col>
      <Col xs={{ span: 24 }} sm={24} md={{ span: 6 }} lg={6} xxl={6}>
        <AdsTypesPublishCount
          setTotalAdsPublishCount={setTotalAdsPublishCount}
        />
      </Col>
      <Col xs={{ span: 24 }} sm={24} md={{ span: 6 }} lg={6} xxl={6}>
        <Card style={styles.totalInfoBox} className="totalInfoBox">
          <Row>
            <Col sm={{ span: 7 }} md={{ span: 6 }}>
              <FontAwesomeIcon icon="calculator" size="4x" />
            </Col>
            <Col xs={{ span: 15 }} sm={{ span: 16 }} md={{ span: 18 }}>
              <p className="p">{t('titles.totalReleased')}</p>
              <ul>
                <li className="p">{`${t('labels.songs')}: ${
                  totalSongPublishCount || t('warnings.noData')
                }`}</li>
                <li className="p">{`${t('labels.soundTypes')}: ${
                  totalSoundTypesPublishCount || t('warnings.noData')
                }`}</li>
                <li className="p">{`${t('labels.advertisements')}: ${
                  totalAdsPublishCount || t('warnings.noData')
                }`}</li>
                <li className="p">{`${t('labels.totalPublicationsCount')}: ${
                  totalPublicationsCount || t('warnings.noData')
                }`}</li>
              </ul>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default InfoBoxes;

const styles = {
  totalInfoBox: {
    maxHeight: 220,
    fontSize: 16,
    color: '#fff',
    borderRadius: 10,
    boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
    background: 'linear-gradient(150deg, #fcdf8a, #f38381 100%)',
  },
};
