import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Row, Col, Spin } from 'antd';
import VERTICAL_TIMELINE from './gql';
import ServerWrong from '../../components/Result/ServerWrong';
import VerticalTimelineView from './VerticalTimelineView';
import VerticalTimelineFilter from './VerticalTimelineFilter';

const VerticalTimeline = () => {
  const [condition, setCondition] = useState();
  const { loading, error, data } = useQuery(VERTICAL_TIMELINE, {
    variables: condition,
    skip: !condition,
    fetchPolicy: 'network-only',
  });

  const handleOnSelect = useCallback(({ date, partnerBranchId }) => {
    setCondition({ date, partnerBranchId });
  }, []);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Row>
      <Col sm={24} xs={24} md={{ span: 24 }}>
        <Row>
          <Col xs={24}>
            <VerticalTimelineFilter handleOnSelect={handleOnSelect} />
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row style={{ marginTop: 30 }}>
            <Col span={24}>
              {data && (
                <VerticalTimelineView
                  data={data?.verticalTimeline?.timelines}
                />
              )}
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
};

export default VerticalTimeline;
