import React, { useState, useCallback } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PartnerAndBranchSelector,
  DateSelector,
} from '../../components/Selectors';
import '../../components/MainLayout/style.css';

const TimelineFilter = ({ handleOnSelect }) => {
  const { t } = useTranslation();
  const [selectedBranch, setSelectedBranch] = useState();
  const [selectedDate, setSelectedDate] = useState();

  const handleFilterButton = useCallback(() => {
    handleOnSelect({ partnerBranchId: selectedBranch, date: selectedDate });
  }, [handleOnSelect, selectedBranch, selectedDate]);

  return (
    <Row>
      <Col xs={24} md={24} lg={24} xxl={24}>
        <Card>
          <Row gutter={8}>
            <Col xs={24} sm={24} md={15} lg={16}>
              <PartnerAndBranchSelector
                onChange={(branchId) => setSelectedBranch(branchId)}
              />
            </Col>
            <Col xs={18} sm={18} md={6} lg={6}>
              <DateSelector onChange={(date) => setSelectedDate(date)} />
            </Col>
            <Col xs={6} sm={6} md={3} lg={2}>
              <Button
                style={{ width: '100%' }}
                disabled={!(selectedBranch && selectedDate)}
                ghost
                type="primary"
                onClick={handleFilterButton}
              >
                {t('buttons.filter')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default TimelineFilter;
