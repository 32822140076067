import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';

import { CREATE_SECTOR, SECTORS, UPDATE_SECTOR, DELETE_SECTOR } from './gql';

import ModalFormBuilder from '../../components/ModalFormBuilder';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.sectorName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.sectorName'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
];

const Sector = () => {
  const { t } = useTranslation();

  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.partnerSectors?.data?.map((sector) => ({
      id: sector?.id,
      name: sector?.name,
      createdAt: humanizeTimestamp(sector.createdAt),
      updatedAt: humanizeTimestamp(sector.updatedAt),
    }));

    return {
      ...response,
      partnerSector: { ...response?.partnerSectors, data: newData },
    };
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="center">{t('header.partnerSector')}</Divider>
        <Col span={24} style={{ marginTop: '20px' }}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleTrueFalse}
            modalTitle={t('titles.newSector')}
            showModalButtonLabel={t('titles.newSector')}
            fields={fields}
            postReq={CREATE_SECTOR}
            postResIndex="createPartnerSector"
            postResFieldForUpdating="partnerSector"
            willBeUpdatedQuery={SECTORS}
            willBeUpdatedQueryName="partnerSectors"
          />
        </Col>
      </Row>
      <Row>
        <Col md={24} lg={24} sm={24} xs={24}>
          <IntegratedEditableTable
            columns={columns}
            getReq={SECTORS}
            getResIndex="partnerSectors"
            deleteReq={DELETE_SECTOR}
            deleteResIndex="deletePartnerSector"
            updateReq={UPDATE_SECTOR}
            updateResIndex="updatePartnerSector"
            afterGetFunc={afterGetFunc}
            filterColumns={{
              partnerSector: ['name'],
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default Sector;
