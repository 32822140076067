import { gql } from 'apollo-boost';

export const CREATE_SONG_POOL = gql`
  mutation createSongPool($data: SongPoolInput!) {
    createSongPool(data: $data) {
      __typename
      ... on SongPoolCreateSuccess {
        songPool {
          id
          name
          songCount
          createdAt
          updatedAt
        }
      }
      ... on SongPoolInvalidInputError {
        name
      }
    }
  }
`;

export const SONG_POOLS = gql`
  query songPools($condition: Condition) {
    songPools(condition: $condition) {
      __typename

      ... on PaginatedSongPoolResponse {
        data {
          id
          name
          songCount
          createdAt
          updatedAt
        }

        totalCount
      }
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;
export const UPDATE_SONG_POOL = gql`
  mutation updateSongPool($where: JSON!, $newValues: SongPoolInput!) {
    updateSongPool(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on SongPoolInvalidInputError {
        name
      }
      ... on SongPoolUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          createdAt
          updatedAt
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_SONG_POOL = gql`
  mutation deleteSongPool($where: JSON!) {
    deleteSongPool(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on SongPoolDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
