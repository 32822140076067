import { gql } from 'apollo-boost';

export const CREATE_JINGLE = gql`
  mutation createJingle($data: JingleInput!, $file: Upload!) {
    createJingle(data: $data, file: $file) {
      __typename
      ... on JingleInvalidInputError {
        name
        jinglePoolId
        file
      }
      ... on JingleCreateSuccess {
        jingle {
          id
          name
          jinglePool {
            id
            name
          }
          file {
            id
            filename
            path
            duration
            address
          }

          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const JINGLES = gql`
  query jingles($condition: Condition) {
    jingles(condition: $condition) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on PaginatedJingleResponse {
        data {
          id
          name

          jinglePool {
            id
            name
          }
          file {
            id
            filename
            path
            duration
            address
          }
          createdAt
          updatedAt
        }
        totalCount
      }
    }
  }
`;

export const UPDATE_JINGLE = gql`
  mutation updateJingle($where: JSON!, $newValues: JingleUpdateInput!) {
    updateJingle(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on JingleInvalidInputError {
        name
        jinglePoolId
        file
      }
      ... on JingleUpdateSuccess {
        affectedRows
        newValues {
          id
          name

          jinglePool {
            id
            name
          }

          file {
            id
            filename
            path
            duration
            address
          }

          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_JINGLE = gql`
  mutation deleteJingle($where: JSON!) {
    deleteJingle(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on JingleDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          jinglePool {
            id
            name
          }
          file {
            id
            filename
            path
            duration
            address
          }

          createdAt
          updatedAt
        }
      }
    }
  }
`;
