import { gql } from 'apollo-boost';

export const SONGS = gql`
  query songs($condition: Condition) {
    songs(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedSongResponse {
        data {
          id
          name
          songPool {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on SongPool {
              id
              partners {
                id
              }
            }
          }
          file {
            id
            address
          }
        }
        totalCount
      }
    }
  }
`;

export const ADD_SONG_TO_PLAYLIST = gql`
  mutation addSongToPlaylist($data: PlaylistSongAppendRelation!) {
    addSongToPlaylist(data: $data) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;
