/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const SEARCH_PARTNERS = gql`
  query searchPartners($condition: Condition) {
    partners(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerResponse {
        data {
          id
          name
          songPools {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`;
