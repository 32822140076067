import { gql } from 'apollo-boost';

const SEARCH_ADVERTISEMENT_SETS = gql`
  query searchAdvertisementSets($condition: Condition) {
    advertisementSets(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedAdvertisementSetResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;

export default SEARCH_ADVERTISEMENT_SETS;
