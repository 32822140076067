/* eslint-disable no-new */
import React from 'react';
import { Row, Col } from 'antd';
import ServiceLayerOfCharts from './ServiceLayerOfCharts';
import { useTranslation } from 'react-i18next';

const SoundTypesStatistic = () => {
  const { t } = useTranslation();
  return (
    <Row style={{ marginTop: 10 }} gutter={24}>
      <Col xs={24} sm={24} md={8} style={{ marginTop: 10 }}>
        <ServiceLayerOfCharts
          tooltipLabel={t('columns.teaser')}
          backgroundColor="#66d1bc"
          title={t('titles.teaserMountlyStatistic')}
          type="TEASER"
        />
      </Col>
      <Col xs={24} sm={24} md={8} style={{ marginTop: 10 }}>
        <ServiceLayerOfCharts
          tooltipLabel={t('columns.jingle')}
          backgroundColor="#e7e1ee"
          title={t('titles.jingleMounlyStatistic')}
          type="JINGLE"
        />
      </Col>
      <Col xs={24} sm={24} md={8} style={{ marginTop: 10 }}>
        <ServiceLayerOfCharts
          tooltipLabel={t('columns.announce')}
          backgroundColor="#b2e7fd"
          title={t('titles.announceMontlyStatictic')}
          type="ANNOUNCE"
        />
      </Col>
    </Row>
  );
};

export default SoundTypesStatistic;
