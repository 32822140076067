/* eslint-disable no-new */
import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { Card } from 'antd';

const LineChart = ({
  labels,
  chartData,
  tooltipLabel,
  backgroundColor,
  title,
}) => {
  const chartRef = useRef();

  useEffect(() => {
    if (chartRef.current) {
      new Chart(chartRef.current, {
        type: 'line',
        data: {
          labels,
          datasets: [
            {
              label: tooltipLabel,
              backgroundColor,
              data: chartData,
            },
          ],
        },
        options: {
          title: {
            display: true,
            text: title,
          },
        },
      });
    }
  }, [labels, chartData, backgroundColor, tooltipLabel, title]);

  return (
    <Card className="canvas">
      <canvas height={200} ref={chartRef} />
    </Card>
  );
};

export default LineChart;
