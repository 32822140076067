import { gql } from 'apollo-boost';

export const CREATE_PARTNER_BRANCH = gql`
  mutation createPartnerBranch($data: PartnerBranchInput!) {
    createPartnerBranch(data: $data) {
      __typename
      ... on PartnerBranchInvalidInputError {
        name
        partnerId
        userId
      }
      ... on PartnerBranchCreateSuccess {
        partnerBranch {
          id
          name
          createdAt
          updatedAt
          partner {
            id
            name
          }
          user {
            __typename

            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on User {
              id
              username
            }
          }
        }
      }
    }
  }
`;

export const PARTNER_BRANCHES = gql`
  query partnerBranches($condition: Condition) {
    partnerBranches(condition: $condition) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerBranchResponse {
        data {
          id
          name
          createdAt
          updatedAt
          partner {
            id
            name
          }
          user {
            __typename

            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on User {
              id
              username
            }
          }
        }
        totalCount
      }
    }
  }
`;

export const UPDATE_PARTNER_BRANCH = gql`
  mutation updatePartnerBranch(
    $where: JSON!
    $newValues: PartnerBranchUpdateInput!
  ) {
    updatePartnerBranch(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on PartnerBranchInvalidInputError {
        name
        partnerId
        userId
      }
      ... on PartnerBranchUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          createdAt
          updatedAt
          partner {
            id
            name
          }
          user {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on User {
              id
              username
            }
          }
        }
      }
    }
  }
`;

export const DELETE_PARTNER_BRANCH = gql`
  mutation deletePartnerBranch($where: JSON!) {
    deletePartnerBranch(where: $where) {
      __typename

      ... on DeleteError {
        message
      }

      ... on PartnerBranchDeleteSuccess {
        affectedRows
        deletedRows {
          id
        }
      }
    }
  }
`;
