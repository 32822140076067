import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import { CREATE_PARTNER_SONG_POOL_RELATION } from './gql';
import SongPoolPartnerRelationTable from './SongPoolPartnerRelationTable';
import { PartnerSelector } from '../../components/Selectors';

const fieldCreator = (t) => [
  {
    name: 'partnerId',
    label: t('titles.partnerName'),
    inputType: 'partnerSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'songPoolIds',
    label: t('titles.songPoolName'),
    inputType: 'songPoolSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
];

const SongPoolPartnerRelation = () => {
  // hooks
  const { t } = useTranslation();
  const [partnerId, setPartnerId] = useState();
  const [visibility, toggleModal] = useState(false);

  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldCreator(t);

  // handles
  const handlePartnerSelect = (selectedPartnerId) => {
    setPartnerId(selectedPartnerId);
  };

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="center">
          {t('header.songPoolPartnerRelation')}
        </Divider>
        <Col span={24} style={{ marginTop: '20px', marginBottom: '20px' }}>
          <ModalFormBuilder
            showModalButtonLabel={t('buttons.createNewRelation')}
            visibility={visibility}
            toggleModal={toggleTrueFalse}
            modalTitle={t('titles.createNewRelation')}
            fields={fields}
            postReq={CREATE_PARTNER_SONG_POOL_RELATION}
            postResIndex="addSongPoolToPartner"
            // postResFieldForUpdating="data"
            // willBeUpdatedQuery={PARTNER_SONG_POOLS}
            // willBeUpdatedQueryName="partnerSongPools"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <h4>{t('messages.relationSongPartner')}</h4>
          <PartnerSelector onChange={handlePartnerSelect} />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          {partnerId && <SongPoolPartnerRelationTable partnerId={partnerId} />}
        </Col>
      </Row>
    </>
  );
};

export default SongPoolPartnerRelation;
