import { gql } from 'apollo-boost';

const SONGS_OF_RELEASED_PLAYLIST = gql`
  query partnerBranches($condition: Condition, $days: [DaysOfWeek!]) {
    partnerBranches(condition: $condition) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerBranchResponse {
        data {
          id
          releasedPlaylists(days: $days) {
            id
            start
            end
            days
            playlist {
              id
              name
              songs {
                id
                name

                artist {
                  __typename
                  ... on Artist {
                    id
                    firstName
                    lastName
                  }
                }
                file {
                  id
                  duration
                  address
                }
              }
            }
          }
        }
        totalCount
      }
    }
  }
`;

export default SONGS_OF_RELEASED_PLAYLIST;
