import { gql } from 'apollo-boost';

export const CREATE_ADVERTISEMENT_SET = gql`
  mutation createAdvertisementSet($data: AdvertisementSetInput!) {
    createAdvertisementSet(data: $data) {
      __typename
      ... on AdvertisementSetCreateSuccess {
        advertisementSet {
          id
          name
          type
          hours
          createdAt
          updatedAt
        }
      }
      ... on AdvertisementSetInvalidInputError {
        name
        type
        hours
      }
    }
  }
`;

export const ADVERTISEMENT_SETS = gql`
  query advertisementSets($condition: Condition) {
    advertisementSets(condition: $condition) {
      __typename
      ... on PaginatedAdvertisementSetResponse {
        data {
          id
          name
          type
          hours
          createdAt
          updatedAt
        }
        totalCount
      }
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_ADVERTISEMENT_SET = gql`
  mutation updateAdvertisementSet(
    $where: JSON!
    $newValues: AdvertisementSetUpdateInput!
  ) {
    updateAdvertisementSet(where: $where, newValues: $newValues) {
      __typename
      ... on AdvertisementSetUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          type
          hours
          createdAt
          updatedAt
        }
      }
      ... on AdvertisementSetInvalidInputError {
        name
        type
        hours
      }
    }
  }
`;

export const DELETE_ADVERTISEMENT_SET = gql`
  mutation deleteAdvertisementSet($where: JSON!) {
    deleteAdvertisementSet(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on AdvertisementSetDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
