import { gql } from 'apollo-boost';

export const MONTHLY_TOTAL_STATISTIC = gql`
  query monthlyTopByTotalOfType($type: StatisticTypes) {
    monthlyTopByTotalOfType(type: $type) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on MonthlyTotalStatistic {
        statistic {
          month
          value
        }
      }
    }
  }
`;

export const MONTHLY_STATISTIC = gql`
  query monthlyTopByEachRecords($topNumber: Int, $type: StatisticTypes) {
    monthlyTopByEachRecords(topNumber: $topNumber, type: $type) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on MonthsStatistics {
        statistic {
          month
          statistic {
            name
            value
          }
        }
      }
    }
  }
`;
