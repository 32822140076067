import React from 'react';
import { Row, Col, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import VerticalTimeline from '../VerticalTimeline';
import Calendar from '../Calander';
import userInfoGetter from '../../utils.js/userInfoGetter';

const { TabPane } = Tabs;

const Dashboard = () => {
  const { t } = useTranslation();
  const { role } = userInfoGetter();

  return (
    <div>
      {role === 'PARTNER' ? (
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <FontAwesomeIcon icon="calendar-alt" size="1x" />{' '}
                {t('titles.verticalTimeline')}
              </span>
            }
            key="2"
          >
            <VerticalTimeline />
          </TabPane>
        </Tabs>
      ) : (
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                <FontAwesomeIcon icon="calendar-day" size="1x" />{' '}
                {t('titles.timeline')}
              </span>
            }
            key="1"
          >
            <Row>
              <Col sm={24} xs={24} md={{ span: 24 }}>
                <Calendar />
              </Col>
            </Row>
          </TabPane>

          <TabPane
            tab={
              <span>
                <FontAwesomeIcon icon="calendar-alt" size="1x" />{' '}
                {t('titles.verticalTimeline')}
              </span>
            }
            key="2"
          >
            <VerticalTimeline />
          </TabPane>
        </Tabs>
      )}
    </div>
  );
};

export default Dashboard;
