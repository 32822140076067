import React, { useCallback, useState } from 'react';
import { Form, message, Spin, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
// eslint-disable-next-line import/no-extraneous-dependencies
import FormBuilder from './FormBuilder';
import Load from '../Load';

const IntegratedModalFormBuilder = ({
  fields,
  postReq,
  postResIndex,
  postResFieldForUpdating,
  willBeUpdatedQuery,
  willBeUpdatedQueryName,
  willBeUpdatedQueryExtraVariable,
  beforeUpload,
  extraFormButtons,
  showResetButton,
  executeFuncAfterSave,
}) => {
  // hooks
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [createFuncWithUpdateCache] = useMutation(postReq, {
    update(cache, { data }) {
      const responseFromMutation = data[postResIndex][postResFieldForUpdating];

      const queryData = cache.readQuery({
        query: willBeUpdatedQuery,
        variables: {
          condition: {
            limit: 10,
            offset: 0,
            order: [['createdAt', 'desc']],
            ...willBeUpdatedQueryExtraVariable,
          },
        },
      });
      cache.writeQuery({
        query: willBeUpdatedQuery,
        variables: {
          condition: {
            limit: 10,
            offset: 0,
            order: [['createdAt', 'desc']],
            ...willBeUpdatedQueryExtraVariable,
          },
        },
        data: {
          [willBeUpdatedQueryName]: {
            ...queryData[willBeUpdatedQueryName],
            data: [
              responseFromMutation,
              ...queryData[willBeUpdatedQueryName].data,
            ],
          },
        },
      });
    },
  });
  const [createFunc] = useMutation(postReq);
  const [errorMessages, setErrorMessages] = useState({});
  const [loading, setLoading] = useState(false);

  const handleReset = useCallback(() => {
    form.resetFields();
  }, [form]);

  const handleFinish = useCallback(async () => {
    setLoading(true);
    let response;
    await form.validateFields();
    const fieldsValue = await form.getFieldsValue();

    try {
      // trigger graphql create function without updating cache
      if (!willBeUpdatedQuery) {
        if (beforeUpload) {
          const customReq = beforeUpload(fieldsValue);
          if (!customReq) {
            setLoading(false);
            return;
          }
          response = await createFunc(customReq);
        } else {
          response = await createFunc({
            variables: { data: fieldsValue },
          });
        }
      }
      // trigger update cache of graphql
      else {
        // eslint-disable-next-line no-lonely-if
        if (beforeUpload) {
          const customReq = beforeUpload(fieldsValue);
          if (!customReq) {
            setLoading(false);
            return;
          }
          response = await createFuncWithUpdateCache(customReq);
        } else {
          response = await createFuncWithUpdateCache({
            variables: { data: fieldsValue },
          });
        }
      }
      const data = response.data[postResIndex];

      if (
        data.__typename === 'ReleasedPlaylistInvalidInputError' ||
        data.__typename === 'ReleasedSoundInvalidInputError'
      ) {
        message.error(
          'Girilen değerlerde çakışma olabilir. Yayına alınmak istenen değerler daha önceden kayıt edilmiş olabilir listeden  kontrol edip tekrar deneyiniz.'
        );
      }

      if (data.__typename.endsWith('Error')) {
        delete data.__typename;
        setErrorMessages(data);
        setLoading(false);
        message.error(t('errors.requestFailed'));
      } else {
        setLoading(false);
        message.success(t('messages.successMessage'), 5);
        handleReset();
      }

      if (executeFuncAfterSave) {
        executeFuncAfterSave();
      }
    } catch (error) {
      setLoading(false);
      console.log('ERROR', error);
      message.error(t('errors.requestFailed'));
    }
  }, [
    form,
    setErrorMessages,
    t,
    handleReset,
    createFuncWithUpdateCache,
    postResIndex,
    beforeUpload,
    createFunc,
    willBeUpdatedQuery,
    setLoading,
    executeFuncAfterSave,
  ]);

  return (
    <Spin spinning={loading} indicator={<Load />}>
      <Col sm={24} xs={24} md={24}>
        <FormBuilder
          form={form}
          fields={fields}
          errorData={errorMessages}
          onReset={handleReset}
          onFinish={handleFinish}
          extraFormButtons={extraFormButtons}
          showResetButton={showResetButton}
        />
      </Col>
    </Spin>
  );
};

export default IntegratedModalFormBuilder;
