import { gql } from 'apollo-boost';

export const STANDARD_ADVERTISEMENTS = gql`
  query periodicAdvertisements($condition: Condition) {
    periodicAdvertisements(condition: $condition) {
      ... on PaginatedPeriodicAdvertisementResponse {
        data {
          id
          createdAt
          updatedAt
          isReleased
          startDate
          endDate
          type
          advertisementSet {
            id
            name
            hours
            releasedCount
            plannedCount
            releasedDuration
            plannedDuration
          }
          partnerBranches {
            id
            name
            partner {
              id
            }
          }
          areAllBranchesSelected
          advertisementPool {
            id
            name
            isPlanned
            file {
              id
              filename
              mimetype
              path
              duration
              address
            }
            releasedCount
            releasedDuration
            plannedDuration
          }
        }
        totalCount
      }
    }
  }
`;

export const UPDATE_STANDARD_ADVERTISEMENT = gql`
  mutation updatePeriodicAdvertisement(
    $where: JSON!
    $newValues: PeriodicAdvertisementUpdateInput!
  ) {
    updatePeriodicAdvertisement(where: $where, newValues: $newValues) {
      __typename
      ... on PeriodicAdvertisementInvalidInputError {
        startDate
        endDate
        type
        advertisementPoolId
        advertisementSetId
        partnerBranchIds
      }
      ... on PeriodicAdvertisementUpdateSuccess {
        affectedRows
        newValues {
          id
          createdAt
          updatedAt
          isReleased
          startDate
          endDate
          type
          advertisementSet {
            id
            name
            hours
            releasedCount
            plannedCount
            releasedDuration
            plannedDuration
          }
          partnerBranches {
            id
            name
            partner {
              id
            }
          }
          areAllBranchesSelected
          advertisementPool {
            id
            name
            isPlanned
            file {
              id
              filename
              mimetype
              path
              duration
              address
            }
            releasedCount
            releasedDuration
            plannedDuration
          }
        }
      }
    }
  }
`;

export const DELETE_STANDARD_ADVERTISEMENT = gql`
  mutation deletePeriodicAdvertisement($where: JSON!) {
    deletePeriodicAdvertisement(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on PeriodicAdvertisementDeleteSuccess {
        affectedRows
        deletedRows {
          id
          createdAt
          updatedAt
          isReleased
          startDate
          endDate
          type
          advertisementSet {
            id
            name
            hours
            releasedCount
            plannedCount
            releasedDuration
            plannedDuration
          }
          partnerBranches {
            id
            name
            partner {
              id
            }
          }
          areAllBranchesSelected
          advertisementPool {
            id
            name
            isPlanned
            file {
              id
              filename
              mimetype
              path
              duration
              address
            }
            releasedCount
            releasedDuration
            plannedDuration
          }
        }
      }
    }
  }
`;

export const RELEASE_STANDARD_ADVERTISEMENT = gql`
  mutation releasePeriodicAdvertisement($id: Int!) {
    releasePeriodicAdvertisement(id: $id) {
      __typename
      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;

export const UN_RELEASE_STANDARD_ADVERTISEMENT = gql`
  mutation unReleasePeriodicAdvertisement($id: Int!) {
    unReleasePeriodicAdvertisement(id: $id) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;
