/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback } from 'react';
import { Row, Col, Divider, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import {
  CREATE_ADVERTISEMENT_POOL,
  ADVERTISEMENT_POOLS,
  UPDATE_ADVERTISEMENT_POOL,
  DELETE_ADVERTISEMENT_POOL,
} from './gql';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';
import { BACKEND_URL } from '../../constants';
import useToggle from '../../hooks/useToggle';
import PeriodicAdvertisementPlanModal from './PeriodicAdvertisementPlanModal';
import SpecialAdvertisementPlanModal from './SpecialAdvertisementPlanModal';
import userInfoGetter from '../../utils.js/userInfoGetter';

const mimeTypeGroups = [
  'audio/aac',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
];
const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.name'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'partnerId',
    label: t('titles.partnerName'),
    inputType: 'partnerSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },

  {
    name: 'file',
    label: t('titles.file'),
    inputType: 'songFileUploadInput',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        validator: (_, value) => {
          const fileType = value.type;
          return mimeTypeGroups.includes(fileType)
            ? Promise.resolve()
            : Promise.reject(t('messages.invalideFile'));
        },
      },
      {
        validator: (_, value) => {
          const fileSize = value.size;
          return fileSize < 104857600
            ? Promise.resolve()
            : Promise.reject(t('messages.fileSmaller'));
        },
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('columns.advertisementName'),
    dataIndex: 'name',
    key: 'name',
    width: '13%',
    editable: true,
  },
  {
    title: t('titles.partnerName'),
    dataIndex: 'partnerId',
    key: 'partnerId',
    inputType: 'partnerSelector',
    width: '12%',
    render: (partnerId, record) => {
      return record.partnerName || t('warnings.noData');
    },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    title: t('titles.file'),
    dataIndex: 'fileId',
    key: 'fileId',
    inputType: 'songFileUploadInput',
    editable: false,
    width: '13%',
    render: (fileId, record) => {
      return (
        <audio
          controls
          controlsList="nodownload"
          style={{ width: '210px' }}
          src={BACKEND_URL + record?.file?.address}
        />
      );
    },
  },
  {
    title: t('columns.time'),
    dataIndex: 'fileId',
    key: 'fileId',
    width: '8%',
    render: (file, record) => {
      if (record?.file?.duration) {
        const durations = record?.file.duration / 1000;
        return moment
          .duration(durations.toFixed(2), 'seconds')
          .format(
            `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
              'labels.seconds'
            )}]`
          );
      }

      return t('warnings.noData');
    },
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '14%',
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    width: '14%',
  },
  {
    title: t('columns.status'),
    dataIndex: 'isPlanned',
    key: 'isPlanned',
    width: '13%',
    filters: [
      {
        text: t('columns.planned'),
        value: true,
      },
      {
        text: t('columns.unPlanned'),
        value: false,
      },
    ],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.isPlanned === value,
    render: (isPlanned) =>
      isPlanned ? (
        <div>
          <span style={{ color: '#228B22' }}> {t('columns.planned')}</span>{' '}
        </div>
      ) : (
        <span style={{ color: '#0000CD' }}> {t('columns.unPlanned')} </span>
      ),
  },
];

export default function Ads() {
  const { role, partnerId } = userInfoGetter();
  const token = localStorage.getItem('token');
  // hooks
  const { t } = useTranslation();
  const [visibility, toggleModal] = useToggle(false);
  const [periodicPlanModalVisibility, togglePeriodicPlanModal] = useToggle(
    false
  );
  const [specialPlanModalVisibility, toggleSpecialPlanModal] = useToggle(false);

  const [
    willBePlannedAdvertisement,
    setWillBePlannedAdvertisement,
  ] = useState();

  const beforeUpload = useCallback((formValues) => {
    const { file } = formValues;
    delete formValues.file;

    return { variables: { data: formValues, file } };
  }, []);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t, token);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.advertisementPools?.data?.map(
      (advertisementPool) => ({
        id: advertisementPool.id,
        key: advertisementPool.key,
        partnerName: advertisementPool.partner.name,
        partnerId: advertisementPool.partner.id,
        name: advertisementPool.name,
        file: advertisementPool.file,
        duration: advertisementPool.duration,
        isPlanned: advertisementPool.isPlanned,
        createdAt: humanizeTimestamp(advertisementPool.createdAt),
        updatedAt: humanizeTimestamp(advertisementPool.updatedAt),
      })
    );

    return {
      ...response,
      advertisementPools: { ...response?.advertisementPools, data: newData },
    };
  }, []);

  const handlePeriodicAdsPlanButton = useCallback(
    (record) => {
      setWillBePlannedAdvertisement(record);
      togglePeriodicPlanModal();
    },
    [setWillBePlannedAdvertisement, togglePeriodicPlanModal]
  );

  const handleSpecialAdsPlanButton = useCallback(
    (record) => {
      setWillBePlannedAdvertisement(record);
      toggleSpecialPlanModal();
    },
    [setWillBePlannedAdvertisement, toggleSpecialPlanModal]
  );

  const planButton = (record) => {
    return (
      <>
        <Row>
          <Col md={24}>
            <Button
              style={{ marginRight: '5px', marginBottom: 5 }}
              ghost
              type="primary"
              onClick={() => handlePeriodicAdsPlanButton(record)}
            >
              {t('buttons.makePeriodicPlan')}
            </Button>
            <Button
              style={{ marginRight: '5px', marginBottom: 5 }}
              ghost
              type="primary"
              onClick={() => handleSpecialAdsPlanButton(record)}
            >
              {t('buttons.makeSpecialPlan')}
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  const upperGetReqConditionCreator = useCallback(() => {
    if (role === 'PARTNER') {
      return {
        limit: 10,
        offset: 0,
        order: [['createdAt', 'desc']],
        where: {
          partnerId,
        },
      };
    }
    return {
      limit: 10,
      offset: 0,
      order: [['createdAt', 'desc']],
    };
  }, [role, partnerId]);

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="center">{t('header.advertisementPool')}</Divider>
        <Col span={5} md={24} sm={24} xs={24}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleModal}
            modalTitle={t('titles.newAdvertisement')}
            showModalButtonLabel={t('titles.newAdvertisement')}
            fields={fields}
            postReq={CREATE_ADVERTISEMENT_POOL}
            postResIndex="createAdvertisementPool"
            postResFieldForUpdating="advertisementPool"
            willBeUpdatedQuery={ADVERTISEMENT_POOLS}
            willBeUpdatedQueryName="advertisementPools"
            beforeUpload={beforeUpload}
            showOpenButton={role !== 'PARTNER'}
          />
        </Col>
        <Col style={{ marginTop: 10 }} md={24} lg={24} sm={24} xs={24}>
          <IntegratedEditableTable
            columns={columns}
            upperGetReqCondition={upperGetReqConditionCreator()}
            getReq={ADVERTISEMENT_POOLS}
            getResIndex="advertisementPools"
            updateReq={UPDATE_ADVERTISEMENT_POOL}
            updateResIndex="updateAdvertisementPool"
            deleteReq={DELETE_ADVERTISEMENT_POOL}
            deleteResIndex="deleteAdvertisementPool"
            afterGetFunc={afterGetFunc}
            filterColumns={{ advertisementPool: ['name'], partner: ['name'] }}
            extraOperation={planButton}
            operationsDisabledStatus={role === 'PARTNER'}
          />
        </Col>
      </Row>
      {willBePlannedAdvertisement && (
        <>
          <PeriodicAdvertisementPlanModal
            visibility={periodicPlanModalVisibility}
            toggleVisibility={togglePeriodicPlanModal}
            advertisementRecord={willBePlannedAdvertisement}
          />
          <SpecialAdvertisementPlanModal
            visibility={specialPlanModalVisibility}
            toggleVisibility={toggleSpecialPlanModal}
            advertisementRecord={willBePlannedAdvertisement}
          />
        </>
      )}
    </>
  );
}
