import React, { useState, useCallback } from 'react';
import { Row, Col, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import {
  CREATE_LEGAL_TRACKING,
  LEGAL_TRACKINGS,
  UPDATE_LEGAL_TRACKING,
  DELETE_LEGAL_TRACKING,
} from './gql';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.legalPercuitName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.legalPercuitName'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
];

export default function LegalPersuit() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.data?.map((legals) => ({
      id: legals.id,
      name: legals.name,
    }));

    return {
      ...response,
      legals: { ...response?.legals, data: newData },
    };
  }, []);
  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('titles.legalPercuit')}</Divider>

      <Col span={24} style={{ marginTop: '20px' }}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newlegalPercuit')}
          showModalButtonLabel={t('titles.newlegalPercuit')}
          fields={fields}
          postReq={CREATE_LEGAL_TRACKING}
          postResIndex="createLegalTracking"
          postResFieldForUpdating="legalTracking"
          willBeUpdatedQuery={LEGAL_TRACKINGS}
          willBeUpdatedQueryName="legalTrackings"
        />
      </Col>
      <Col style={{ marginTop: 10 }} md={24} lg={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={LEGAL_TRACKINGS}
          getResIndex="legalTrackings"
          deleteReq={DELETE_LEGAL_TRACKING}
          deleteResIndex="deleteLegalTracking"
          updateReq={UPDATE_LEGAL_TRACKING}
          updateResIndex="updateLegalTracking"
          afterGetFunc={afterGetFunc}
          filterColumns={{ legalTracking: ['name'] }}
        />
      </Col>
    </Row>
  );
}
