import React, { useState, useEffect } from 'react';
import { Modal, Descriptions, Row, Col } from 'antd';
import { differenceInCalendarDays } from 'date-fns';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import dateParser from '../utils.js/dateParser';
import { msToTime } from '../utils.js/adsSetHelper';

momentDurationFormatSetup(moment);

const DetailOfAdsModal = ({ data, visibility, toggleModal, title }) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState();

  useEffect(() => {
    const duration = data?.advertisementPool?.file?.duration.toFixed(2);
    const hours = data?.advertisementSet?.hours.map((hour) => msToTime(hour));
    const dailyRepeat = hours.length;
    const totalRepeat =
      (1 +
        differenceInCalendarDays(
          new Date(data?.endDate),
          new Date(data?.startDate)
        )) *
      hours.length;

    const dailyTotalDuration = moment
      .duration(((duration * dailyRepeat) / 1000).toFixed(2), 'seconds')
      .format(
        `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
          'labels.seconds'
        )}]`
      );
    const totalDuration = moment
      .duration(((duration * totalRepeat) / 1000).toFixed(2), 'seconds')
      .format(
        `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
          'labels.seconds'
        )}]`
      );

    setModalData({
      name: data?.advertisementPool?.name,
      startDate: dateParser(data?.startDate).date,
      endDate: dateParser(data?.endDate).date,
      duration: moment
        .duration(
          (data?.advertisementPool?.file?.duration / 1000).toFixed(2),
          'seconds'
        )
        .format(
          `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
            'labels.seconds'
          )}]`
        ),
      dailyRepeat,
      totalRepeat,
      dailyTotalDuration,
      totalDuration,
      hours,
    });
  }, [data, t]);

  return (
    <>
      {modalData && (
        <Modal
          title={title}
          visible={visibility}
          onOk={toggleModal}
          onCancel={toggleModal}
          width={720}
          footer={null}
        >
          <Descriptions
            title={`${modalData.name}`}
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label={t('labels.advertisementName')}>
              {modalData.name || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.startDate')}>
              {modalData.startDate || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.endDate')}>
              {modalData.endDate || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.duration')}>
              {modalData.duration || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.dailyRepeat')}>
              {modalData.dailyRepeat || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.totalRepeat')}>
              {modalData.totalRepeat || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.dailyTotalDuration')}>
              {modalData.dailyTotalDuration || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.totalDuration')}>
              {modalData.totalDuration || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.broadcastTimes')}>
              <Row gutter={16}>
                {modalData.hours?.map((hour) => (
                  <Col
                    md={{ span: 6 }}
                    style={{ marginBottom: '10px' }}
                    key={hour}
                  >
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'inline-block',
                        padding: '10px 20px',
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderRadius: '8px',
                        borderColor: '#40a9ff',
                      }}
                    >
                      {hour}
                    </div>
                  </Col>
                ))}
              </Row>
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      )}
    </>
  );
};

export default DetailOfAdsModal;
