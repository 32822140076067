import React, { useState, useCallback, useEffect } from 'react';
import { Form, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import inputCreator from '../../utils.js/inputCreator';
import '../../containers/AddNewSong/form.css';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const FormBuilder = ({
  form,
  fields,
  errorData,
  onReset,
  onFinish,
  extraFormButtons,
  showResetButton = true,
}) => {
  const [errorMessages, setErrorMessages] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    setErrorMessages(errorData);
  }, [errorData]);

  const handleChange = useCallback(
    (inputName) => {
      const newErrorMessages = { ...errorMessages };
      delete newErrorMessages[inputName];
      setErrorMessages(newErrorMessages);
    },
    [errorMessages]
  );

  return (
    <Form form={form} name="control-hooks" onFinish={onFinish}>
      {fields &&
        fields.map((field) => {
          const InputComp = inputCreator(field.inputType);
          const { name } = field;
          return (
            <Row key={name} gutter={16} className="songInput">
              <Col xs={24} sm={24} md={{ span: 24 }} lg={24} xl={20}>
                <Form.Item
                  className="createForm"
                  {...layout}
                  onChange={() => handleChange(name)}
                  key={field.name}
                  name={field.name}
                  label={field.label}
                  rules={field.rules}
                  validateStatus={errorMessages?.[name] ? 'error' : 'success'}
                  help={
                    errorMessages?.[name]
                      ? t(`errors.${errorMessages?.[name]}`)
                      : null
                  }
                  style={{ borderRadius: '30px' }}
                >
                  <InputComp propsForSelector={field.propsForSelector} />
                </Form.Item>
              </Col>
            </Row>
          );
        })}
      <Row className="buttonInput">
        <Col md={{ offset: 8 }} sm={{ offset: 4 }} xs={{ offset: 4 }}>
          <Form.Item className="button-reset-save">
            {extraFormButtons}
            {showResetButton && (
              <Button
                key="reset"
                htmlType="button"
                ghost
                style={{
                  marginRight: 10,
                  borderRadius: 10,
                  color: '#ff4d4f',
                  borderColor: '#ff4d4f',
                }}
                onClick={onReset}
              >
                {t('buttons.clear')}
              </Button>
            )}
            <Button
              key="submit"
              htmlType="submit"
              ghost
              type="primary"
              style={{
                marginRight: 10,
                borderRadius: 10,
              }}
            >
              {t('buttons.save')}
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormBuilder;
