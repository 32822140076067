const address = 'panel.havaliajans.com'

const backendUrl =
  process.env.REACT_APP_BACKEND_URL || `https://${address}/api`;
const gqlUrl =
  process.env.REACT_APP_GRAPHQL_URL || `https://${address}/api/graphql`;

export const BACKEND_URL = backendUrl;
export const GQL_URL = gqlUrl;

// export const BACKEND_URL = 'http://localhost:3000';
// export const GQL_URL = 'http://localhost:3000/graphql';

export const monthsMappedByIndex = {
  '1': 'Jan',
  '2': 'Feb',
  '3': 'Mar',
  '4': 'Apr',
  '5': 'May',
  '6': 'Jun',
  '7': 'Jul',
  '8': 'Aug',
  '9': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
};

export const daysOfWeek = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

export const weekdays = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
];

export const monthsArray = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];
