/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Tooltip } from 'antd';
import moment from 'moment';
import {
  FileSearchOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
} from '@ant-design/icons';
import FilterForAdvertisementPlan from '../../components/FilterForAdvertisementPlan';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import {
  INTER_ADVERTISEMENTS,
  UPDATE_INTER_ADVERTISEMENT,
  DELETE_INTER_ADVERTISEMENT,
  RELEASE_INTER_ADVERTISEMENT,
  UN_RELEASE_INTER_ADVERTISEMENT,
} from './gql';
import DetailOfAdsModal from '../../components/DetailOfAdsModal';
import useToggle from '../../hooks/useToggle';
import { BACKEND_URL } from '../../constants';
import dateParser from '../../utils.js/dateParser';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';
import userInfoGetter from '../../utils.js/userInfoGetter';
const columnsConfig = (t) => [
  {
    title: t('labels.advertisementName'),
    dataIndex: 'advertisementPoolId',
    key: 'advertisementPoolId',
    editable: false,
    render: (value, record) => record?.advertisementPool?.name,
  },
  {
    title: t('labels.partnerBranch'),
    dataIndex: 'partnerBranchIds',
    key: 'partnerBranchIds',
    inputType: 'partnerBranchSelector',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    propsForSelector: { searchWithService: false },
    render: (value, record) => {
      if (record?.areAllBranchesSelected) {
        return <h4 key="all">{t('labels.allBranchesSelected')}</h4>;
      }
      return record?.partnerBranches?.map((branch) => (
        <span style={{ display: 'block' }} key={branch.id}>
          {branch.name}
        </span>
      ));
    },
  },
  {
    title: t('titles.song'),
    dataIndex: 'address',
    key: 'address',
    editable: false,
    render: (fileId, record) => {
      return (
        <audio
          controls
          controlsList="nodownload"
          style={{ width: '180px' }}
          src={BACKEND_URL + record.advertisementPool.file.address}
        />
      );
    },
  },
  {
    title: t('columns.time'),
    dataIndex: 'file',
    key: 'file',
    render: (file, record) => {
      if (record?.advertisementPool?.file?.duration) {
        const durations = record?.advertisementPool?.file?.duration / 1000;
        return moment
          .duration(durations.toFixed(2), 'seconds')
          .format(
            `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
              'labels.seconds'
            )}]`
          );
      }

      return t('warnings.noAdvertisement');
    },
  },
  {
    title: t('labels.advertisementSet'),
    dataIndex: 'advertisementSetId',
    key: 'advertisementSetId',
    inputType: 'advertisementSetSelector',
    editable: true,
    propsForSelector: { setType: 'INTER' },
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value, record) => record?.advertisementSet?.name,
  },
  {
    title: t('labels.startDate'),
    dataIndex: 'startDate',
    key: 'startDate',
    inputType: 'dateSelector',
    propsForSelector: { fieldName: 'startDate' },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      const { date } = dateParser(value);

      return `${date} `;
    },
  },
  {
    title: t('labels.endDate'),
    dataIndex: 'endDate',
    key: 'endDate',
    inputType: 'dateSelector',
    propsForSelector: { fieldName: 'endDate' },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      const { date } = dateParser(value);

      return `${date}`;
    },
  },
  {
    title: t('columns.isReleased'),
    dataIndex: 'advertisementPool',
    key: 'advertisementPool',
    width: '12%',
    render: (advertisementPool) => {
      return `${advertisementPool.releasedCount} ${t('labels.advertisemet')}`;
    },
  },
  {
    title: t('columns.displayTime'),
    dataIndex: 'advertisementPool',
    key: 'advertisementPool',
    render: (advertisementPool, record) => {
      if (record?.advertisementPool?.releasedDuration) {
        const durations = record?.advertisementPool?.releasedDuration / 1000;
        return moment
          .duration(durations.toFixed(2), 'seconds')
          .format(
            `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
              'labels.seconds'
            )}]`
          );
      }

      return t('warnings.noAdvertisement');
    },
  },
  {
    title: t('columns.totalTime'),
    dataIndex: 'advertisementPool',
    key: 'advertisementPool',
    width: '120px',
    render: (advertisementPool, record) => {
      const reDuration = record?.advertisementPool?.releasedDuration;

      const planDuration = record?.advertisementPool?.file?.duration;
      const total = reDuration + planDuration;
      if (total) {
        const durations = total / 1000;
        return moment
          .duration(durations.toFixed(2), 'seconds')
          .format(
            `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
              'labels.seconds'
            )}]`
          );
      }

      return t('warnings.noAdvertisement');
    },
  },
  {
    title: t('columns.status'),
    dataIndex: 'isReleased',
    key: 'isReleased',
    filters: [
      {
        text: t('columns.released'),
        value: true,
      },
      {
        text: t('columns.notReleased'),
        value: false,
      },
    ],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.isReleased === value,
    render: (isReleased) =>
      isReleased ? t('columns.released') : t('columns.notReleased'),
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
];

const InterAdvertisement = () => {
  const { role, partnerId } = userInfoGetter();

  const { t } = useTranslation();
  const [readCondition, setReadCondition] = useState();
  const [modalData, setModalData] = useState();
  const [visibility, toggleModal] = useToggle(false);
  useEffect(() => {
    if (role === 'PARTNER')
      setReadCondition({
        limit: 10,
        offset: 0,
        order: [['createdAt', 'desc']],
        where: { type: 'INTER', partnerId },
      });
    else
      setReadCondition({
        limit: 10,
        offset: 0,
        order: [['createdAt', 'desc']],
        where: { type: 'INTER' },
      });
  }, [role, partnerId]);
  // #region handle filter result
  const handleFilterResult = useCallback(
    ({ partnerId, startDate, endDate }) => {
      let search;
      if (startDate && endDate) {
        search = {
          startDate,
          endDate,
          periodicAdvertisement: ['startDate', 'endDate'],
        };
      } else if (startDate && !endDate) {
        search = {
          startDate,
          periodicAdvertisement: ['startDate'],
        };
      } else if (endDate && !startDate) {
        search = {
          endDate,
          periodicAdvertisement: ['endDate'],
        };
      }

      setReadCondition({
        limit: 10,
        offset: 0,
        order: [['createdAt', 'desc']],
        where: {
          type: 'INTER',
          partnerId,
        },
        search,
      });
    },
    []
  );

  // #endregion

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.periodicAdvertisements?.data?.map((ads) => ({
      ...ads,
      partnerBranchIds: ads?.partnerBranches?.map((branch) => branch.id),
      partnerId: ads?.partnerBranches?.[0].partner?.id,
      advertisementSetId: ads?.advertisementSet?.id,
      address: ads.advertisementSet?.file?.address,
    }));

    return {
      ...response,
      periodicAdvertisements: {
        ...response?.periodicAdvertisements,
        data: newData,
      },
    };
  }, []);

  const handleDetailButton = useCallback(
    (record) => {
      setModalData(record);
      toggleModal();
    },
    [toggleModal, setModalData]
  );

  const extraOperationCreator = (record, toggleRelease) => (
    <>
      <Tooltip title={t('buttons.detail')}>
        <Button
          style={{ marginBottom: 5, marginRight: 5 }}
          ghost
          type="primary"
          icon={<FileSearchOutlined />}
          onClick={() => {
            handleDetailButton(record);
          }}
        />
      </Tooltip>
      <Tooltip
        title={
          record?.isReleased ? t('buttons.unRelease') : t('buttons.release')
        }
      >
        <Button
          style={{ marginBottom: 5, marginRight: 5 }}
          ghost
          type="primary"
          icon={
            record?.isReleased ? (
              <PauseCircleOutlined />
            ) : (
              <PlayCircleOutlined />
            )
          }
          onClick={() =>
            record?.isReleased
              ? toggleRelease(record?.id, 'unRelease')
              : toggleRelease(record?.id, 'release')
          }
        />
      </Tooltip>
    </>
  );

  return (
    <>
      <Divider orientation="center">{t('buttons.interAdvertisement')}</Divider>
      <FilterForAdvertisementPlan handleFilterResult={handleFilterResult} />

      <IntegratedEditableTable
        columns={columns}
        getReq={INTER_ADVERTISEMENTS}
        getResIndex="periodicAdvertisements"
        deleteReq={DELETE_INTER_ADVERTISEMENT}
        deleteResIndex="deletePeriodicAdvertisement"
        updateReq={UPDATE_INTER_ADVERTISEMENT}
        updateResIndex="updatePeriodicAdvertisement"
        upperGetReqCondition={readCondition}
        afterGetFunc={afterGetFunc}
        extraOperation={extraOperationCreator}
        fetchPolicy="network-only"
        emptyDescription={t('warnings.interAds')}
        releaseReq={RELEASE_INTER_ADVERTISEMENT}
        releaseResIndex="releasePeriodicAdvertisement"
        unReleaseReq={UN_RELEASE_INTER_ADVERTISEMENT}
        unReleaseResIndex="unReleasePeriodicAdvertisement"
      />

      {modalData && (
        <DetailOfAdsModal
          toggleModal={toggleModal}
          visibility={visibility}
          data={modalData}
          title={t('titles.interAds')}
        />
      )}
    </>
  );
};

export default InterAdvertisement;
