import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import { msToTimeWithSecond } from '../../utils.js/adsSetHelper';
import dateParser from '../../utils.js/dateParser';

const SoundElement = ({ sound, start, end }) => {
  const { t } = useTranslation();

  const startOfSound = dateParser(sound.start);
  const endOfSound = dateParser(sound.end);
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      date={`${msToTimeWithSecond(start)} - ${msToTimeWithSecond(end)}`}
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      icon={<FontAwesomeIcon icon="volume-up" />}
    >
      <h3 className="vertical-timeline-element-title">
        {sound?.sound?.name || t('warnings.noData')}
      </h3>
      <h4
        className="vertical-timeline-element-subtitle"
        style={{ fontWeight: 'bold' }}
      >
        {sound?.sound?.type || t('warnings.noData')}
      </h4>

      <Descriptions
        size="small"
        title={<h4 style={{ marginTop: 20 }}>{t('titles.soundDetail')}</h4>}
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}
      >
        {/* <Descriptions.Item label="Tekrar Sayısı">
          {sound?.repeat || t('warnings.noData')}
        </Descriptions.Item>

        <Descriptions.Item label="Periyot">
          {sound?.period || t('warnings.noData')}
        </Descriptions.Item> */}

        <Descriptions.Item label="Kaç Şarkıda Bir">
          {sound?.period || t('warnings.noData')}
        </Descriptions.Item>

        <Descriptions.Item label="" />

        <Descriptions.Item label="Başlangıç Saati">
          {startOfSound?.time || t('warnings.noData')}
        </Descriptions.Item>

        <Descriptions.Item label="" />

        <Descriptions.Item label="Bitiş Saati">
          {endOfSound?.time || t('warnings.noData')}
        </Descriptions.Item>

        <Descriptions.Item label="" />

        <Descriptions.Item label="Şube">
          {sound?.partnerBranches.map((branch) => (
            <h4 style={{ marginTop: 5 }} key={branch.id}>
              {branch.name}
            </h4>
          ))}
        </Descriptions.Item>
      </Descriptions>
    </VerticalTimelineElement>
  );
};

export default SoundElement;
