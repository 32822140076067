import { gql } from 'apollo-boost';

export const USER_DETAIL = gql`
  query user($where: JSON!) {
    user(where: $where) {
      __typename
      ... on User {
        id
        firstName
        lastName
        email
        username
        role
        description
        primaryPhone
        secondaryPhone
        partnerBranch {
          __typename
          ... on PartnerBranch {
            id
            name
          }
        }
        partner {
          __typename
          ... on Partner {
            id
            name
          }
        }
      }
    }
  }
`;
export const UPDATE_USER = gql`
  mutation updateUser($where: JSON!, $newValues: UserUpdateInput!) {
    updateUser(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on UserInvalidInputError {
        firstName
        lastName
        email
        username
        role
        description
        primaryPhone
        secondaryPhone
        password
      }

      ... on UserUpdateSuccess {
        affectedRows
        newValues {
          id
          firstName
          lastName
          email
          username
          role
          description
          primaryPhone
          secondaryPhone
          password
        }
      }
    }
  }
`;
