/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable import/no-cycle */
/* eslint-disable import/no-extraneous-dependencies */

import {
  AuditOutlined,
  CalendarOutlined,
  ContactsOutlined,
  CustomerServiceOutlined,
  FileAddOutlined,
  FormOutlined,
  GroupOutlined,
  HomeOutlined,
  IdcardOutlined,
  InfoCircleOutlined,
  NotificationOutlined,
  PieChartOutlined,
  PlayCircleOutlined,
  PlaySquareOutlined,
  SisternodeOutlined,
  SoundOutlined,
  SubnodeOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import routes from '../../config/routes';
import './style.css';

const { SubMenu } = Menu;
const icons = {
  home: HomeOutlined,
  user: UserOutlined,
  calendar: CalendarOutlined,
  pieChart: PieChartOutlined,
  wifi: WifiOutlined,
  headphone: CustomerServiceOutlined,
  notification: NotificationOutlined,
  sound: SoundOutlined,
  soundpool: PlaySquareOutlined,
  discribing: UnorderedListOutlined,
  schedule: FormOutlined,
  userInfo: InfoCircleOutlined,
  userCreate: UserAddOutlined,
  partner: ContactsOutlined,
  branch: IdcardOutlined,
  allUser: TeamOutlined,
  sector: AuditOutlined,
  music: PlayCircleOutlined,
  newSong: FileAddOutlined,
  songArea: SubnodeOutlined,
  relation: SisternodeOutlined,
  pool: GroupOutlined,
};
const { Sider } = Layout;

export default function MenuSiders() {
  const location = useLocation();
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(true);
  const [roleBasedRoutes, setRoleBasedRoutes] = useState([]);

  useEffect(() => {
    const userRole = JSON.parse(window.localStorage.getItem('user')).role;

    setRoleBasedRoutes(routes(t).privateRoutes[userRole]);
  }, [t]);

  return (
    <Sider
      className="hamburgerMenu"
      width={250}
      theme="light"
      collapsible
      collapsed={collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'sticky',
        top: 0,
        left: 0,
      }}
    >
      <Menu
        defaultSelectedKeys={['1']}
        selectedKeys={[location.pathname]}
        className="menuToggle"
        mode="inline"
        style={{
          backgroundColor: 'rgba(0,0,0,0)',
        }}
      >
        {roleBasedRoutes.map((data) => {
          let SpecificIcon = icons[data.icon];

          SpecificIcon = SpecificIcon || HomeOutlined;

          if (data.subRoutes) {
            return (
              <SubMenu
                key={data.id}
                title={
                  <div>
                    <SpecificIcon style={{ marginRight: '10px' }} /> {data.name}
                  </div>
                }
              >
                {data.subRoutes.map((subItem, i) => {
                  const SpecificIcon = icons[subItem.icon];
                  if (subItem.childRoutes) {
                    return (
                      <SubMenu
                        style={{
                          width: 251,
                        }}
                        key={subItem.id}
                        title={
                          <div>
                            <SpecificIcon style={{ marginRight: '10px' }} />
                            {''} {subItem.name}
                          </div>
                        }
                      >
                        {subItem.childRoutes.map((subChild, i) => {
                          const SpecificIcon = icons[subChild.icon];
                          return (
                            <Menu.Item key={subChild.link}>
                              <Link to={subChild.link}>
                                <SpecificIcon style={{ marginRight: '10px' }} />{' '}
                                {subChild.name}
                              </Link>
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  }
                  return (
                    <Menu.Item key={subItem.link}>
                      <Link to={subItem.link}>
                        <SpecificIcon style={{ marginRight: '10px' }} />{' '}
                        {subItem.name}
                      </Link>
                    </Menu.Item>
                  );
                })}
              </SubMenu>
            );
          }
          return (
            <Menu.Item key={data.link}>
              <Link to={data.link}>
                <SpecificIcon style={{ marginRight: '10px' }} /> {data.name}
              </Link>
            </Menu.Item>
          );
        })}
      </Menu>
    </Sider>
  );
}
