import { gql } from 'apollo-boost';

export const CREATE_PRODUCTION_COMPANY = gql`
  mutation createProductionCompany($data: ProductionCompanyInput!) {
    createProductionCompany(data: $data) {
      __typename

      ... on ProductionCompanyInvalidInputError {
        name
      }

      ... on ProductionCompanyCreateSuccess {
        productionCompany {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const PRODUCTION_COMPANIES = gql`
  query productionCompanies($condition: Condition) {
    productionCompanies(condition: $condition) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedProductionCompanyResponse {
        totalCount
        data {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPDATE_PRODUCTION_COMPANY = gql`
  mutation updateProductionCompany(
    $where: JSON!
    $newValues: ProductionCompanyUpdateInput!
  ) {
    updateProductionCompany(where: $where, newValues: $newValues) {
      __typename

      ... on ProductionCompanyInvalidInputError {
        name
      }

      ... on ProductionCompanyUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const DELETE_PRODUCTION_COMPANY = gql`
  mutation deleteProductionCompany($where: JSON!) {
    deleteProductionCompany(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on ProductionCompanyDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
