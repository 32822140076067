import { gql } from 'apollo-boost';

const SEARCH_ARTIST = gql`
  query artists($condition: Condition) {
    artists(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedArtistResponse {
        data {
          id
          firstName
          lastName
        }
        totalCount
      }
    }
  }
`;

export default SEARCH_ARTIST;
