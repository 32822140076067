/* eslint-disable consistent-return */
import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Lottie from 'react-lottie';
import animationData from './animation.json';

const NotAuthorized = () => {
  // hooks
  const { t } = useTranslation();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return (
    <>
      <Row gutter={16}>
        <Col md={24} sm={24} xs={24}>
          <Lottie options={defaultOptions} width="250px" height="200px" />
        </Col>
        <Col md={24} xs={24} sm={24}>
          <h4 style={{ textAlign: 'center' }}>{t('messages.notAuthorized')}</h4>
        </Col>

        <Col md={{ offset: 11 }} xs={{ offset: 7 }} sm={{ offset: 8 }}>
          <Link to="/home">{t('titles.backHome')}</Link>
        </Col>
      </Row>
    </>
  );
};

export default NotAuthorized;
