/* eslint-disable prefer-template */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SongElement from './SongElement';
import PeriodicAdsElement from './PeriodicAdsElement';
import SoundElement from './SoundElement';
import SpecialAdsElement from './SpecialAdsElement';

const VerticalTimelineComp = ({ data }) => {
  return (
    <div style={{ backgroundColor: 'rgb(227, 227, 227)' }}>
      <VerticalTimeline style={{ width: 200 }}>
        <VerticalTimelineElement
          iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
          icon={<FontAwesomeIcon icon="hourglass-start" size="lg" />}
        />

        {data &&
          data.map((datum, index) => {
            if (datum.type === 'song') {
              return (
                <SongElement
                  key={index}
                  song={datum?.song}
                  start={datum?.start}
                  end={datum?.end}
                />
              );
            }
            if (
              datum.type === 'standardAdvertisement' ||
              datum.type === 'interAdvertisement'
            ) {
              return (
                <PeriodicAdsElement
                  key={index}
                  advertisement={datum?.periodicAdvertisement}
                  start={datum?.start}
                  end={datum?.end}
                  type={datum?.type}
                />
              );
            }
            if (datum.type === 'specialAdvertisement') {
              return (
                <SpecialAdsElement
                  key={index}
                  advertisement={datum?.specialAdvertisement}
                  start={datum?.start}
                  end={datum?.end}
                />
              );
            }
            if (datum.type === 'sound') {
              return (
                <SoundElement
                  key={index}
                  sound={datum?.releasedSound}
                  start={datum?.start}
                  end={datum?.end}
                />
              );
            }
            return (
              <VerticalTimelineElement
                key={index}
                iconStyle={{ background: 'red', color: '#fff' }}
                icon={<FontAwesomeIcon icon="hourglass-end" size="lg" />}
              />
            );
          })}

        <VerticalTimelineElement
          iconStyle={{ background: 'red', color: '#fff' }}
          icon={<FontAwesomeIcon icon="hourglass-end" size="lg" />}
        />
      </VerticalTimeline>
    </div>
  );
};

export default VerticalTimelineComp;
