/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, message } from 'antd';
import FilterForPlaylistModal from './FilterForPlaylistModal';
import TransferComp from './TransferComp';
import userInfoGetter from '../../utils.js/userInfoGetter';

const Playlist = () => {
  const { role, partnerId } = userInfoGetter();
  // hooks
  const { t } = useTranslation();
  const [readCondition, setReadCondition] = useState();
  const [songPoolId, setSongPoolId] = useState();

  // #region handle filter
  const handleFilterResult = useCallback(
    (filterResult) => {
      if (!filterResult.songPoolId) {
        return message.warning(t('warnings.chooseSongPool'));
      }
      if (filterResult) {
        if (role === 'PARTNER') {
          setReadCondition({
            limit: -1,
            where: {
              partnerId,
              ...filterResult,
            },
          });
        } else
          setReadCondition({
            limit: -1,
            where: {
              ...filterResult,
            },
          });
      }
      setSongPoolId(filterResult.songPoolId);
    },
    [t, setReadCondition, partnerId, role]
  );
  // #endregion

  return (
    <Row gutter={16}>
      <Col md={5} sm={24} xs={24}>
        <FilterForPlaylistModal handleFilterResult={handleFilterResult} />
      </Col>
      <Col md={19} sm={24} xs={24}>
        {readCondition && songPoolId && (
          <TransferComp readCondition={readCondition} songPoolId={songPoolId} />
        )}
      </Col>
    </Row>
  );
};

export default Playlist;
