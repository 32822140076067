// eslint-disable-next-line import/no-extraneous-dependencies
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import React from 'react';
import IntegratedFormBuilder from '../IntegratedFormBuilder';

const ModalFormBuilder = ({
  visibility,
  toggleModal,
  modalTitle,
  showModalButtonLabel,
  showModalButtonStyle,
  customFooter,
  handleOk,
  handleCancel,
  modalStyle,
  showOpenButton = true,
  ...rest
}) => {
  // hooks
  return (
    <>
      {showOpenButton && (
        <Button
          ghost
          style={showModalButtonStyle}
          type="primary"
          onClick={toggleModal}
          icon={<PlusOutlined />}
        >
          {showModalButtonLabel}
        </Button>
      )}
      <Modal
        width={720}
        style={modalStyle}
        visible={visibility}
        title={modalTitle}
        onOk={handleOk || toggleModal}
        onCancel={handleCancel || toggleModal}
        footer={[customFooter]}
      >
        <IntegratedFormBuilder {...rest} />
      </Modal>
    </>
  );
};

export default ModalFormBuilder;
