import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SEARCH_PARTNERS } from './gql';

const { Option } = Select;

const PartnerSelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  // hooks
  const [selectedOption, setSelectedOption] = useState();
  const [condition, setCondition] = useState({ limit: 5 });
  const { loading, error, data } = useQuery(SEARCH_PARTNERS, {
    variables: { condition },
  });

  const handleSearch = useCallback((input) => {
    if (input) {
      setCondition({
        limit: 5,
        where: {
          _or: {
            name: { _iLike: `%${input}%` },
          },
        },
      });
    }
  }, []);

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <Select
      defaultValue={initialrecord?.partnerName}
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.selectPartner')}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
    >
      {data?.partners?.data &&
        data.partners.data.map((partner) => (
          <Option value={partner.id} key={partner.id}>
            {partner.name}
          </Option>
        ))}
    </Select>
  );
};

export default PartnerSelector;
