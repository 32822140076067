import React, { useState, useCallback, useEffect } from 'react';
import { Select, Checkbox, Row, Col } from 'antd';
import { timeToMS } from '../../utils.js/adsSetHelper';

const { Option } = Select;

// #region renderMinutes
const renderMinutes = () => {
  const minutes = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 60; i++) {
    const optionValue = i < 10 ? `0${i}` : i;
    minutes.push(
      <Option key={i} value={optionValue}>
        {`${optionValue} Geçe`}
      </Option>
    );
  }

  return minutes;
};
// #endregion

const SetSelector = ({ onChange: formOnChange, initialrecord }) => {
  // hooks
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (initialrecord) {
      const minute = initialrecord?.formattedHours[0]?.split(':')[1];
      setSelectedOption(minute);
    }
  }, [initialrecord]);

  // handles

  // #region renderHours
  const renderHours = useCallback(() => {
    const hours = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 8; i < 23; i++) {
      const checkboxValue =
        i < 10 ? `0${i}:${selectedOption}` : `${i}:${selectedOption}`;

      hours.push(
        <Col key={i} span={6} xs={{ span: 10 }}>
          <Checkbox value={checkboxValue}>{checkboxValue}</Checkbox>
        </Col>
      );
    }

    return hours;
  }, [selectedOption]);
  // #endregion

  const handleSelectChange = useCallback(
    (value) => {
      setSelectedOption(value);
    },
    [setSelectedOption]
  );

  const handleCheckboxSelect = useCallback(
    (selectedCheckboxes) => {
      const formattedValues = selectedCheckboxes.map((hour) =>
        String(timeToMS(hour))
      );
      formOnChange(formattedValues);
    },
    [formOnChange]
  );

  return (
    <>
      <Select
        value={selectedOption}
        style={{
          width: '100%',
          marginBottom: 10,
        }}
        onChange={handleSelectChange}
      >
        {renderMinutes()}
      </Select>
      {selectedOption !== undefined && (
        <>
          <h4
            style={{
              marginBottom: 10,
            }}
          >
            Saat seçiniz:
          </h4>
          <Checkbox.Group
            defaultValue={initialrecord ? initialrecord.formattedHours : null}
            onChange={handleCheckboxSelect}
          >
            <Row>{renderHours()}</Row>
          </Checkbox.Group>
        </>
      )}
    </>
  );
};

export default SetSelector;
