import { gql } from 'apollo-boost';

const SEARCH_WRITER = gql`
  query writers($condition: Condition) {
    writers(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedWriterResponse {
        data {
          id
          firstName
          lastName
        }
        totalCount
      }
    }
  }
`;

export default SEARCH_WRITER;
