import {
  WifiOutlined,
  NotificationOutlined,
  CustomerServiceOutlined,
  PieChartOutlined,
  LogoutOutlined,
  SoundOutlined,
  UserOutlined,
  CalendarOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  UserAddOutlined,
  PlaySquareOutlined,
  UnorderedListOutlined,
  FormOutlined,
  ContactsOutlined,
  IdcardOutlined,
  TeamOutlined,
  AuditOutlined,
  PlayCircleOutlined,
  FileAddOutlined,
  SubnodeOutlined,
  SisternodeOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import React, { useState, useEffect, useCallback } from 'react';
import { Menu, Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import routes from '../../config/routes';

const { SubMenu } = Menu;
const icons = {
  home: HomeOutlined,
  user: UserOutlined,
  calendar: CalendarOutlined,
  pieChart: PieChartOutlined,
  wifi: WifiOutlined,
  headphone: CustomerServiceOutlined,
  notification: NotificationOutlined,
  sound: SoundOutlined,
  soundpool: PlaySquareOutlined,
  discribing: UnorderedListOutlined,
  schedule: FormOutlined,
  userInfo: InfoCircleOutlined,
  userCreate: UserAddOutlined,
  partner: ContactsOutlined,
  branch: IdcardOutlined,
  allUser: TeamOutlined,
  sector: AuditOutlined,
  music: PlayCircleOutlined,
  newSong: FileAddOutlined,
  songArea: SubnodeOutlined,
  relation: SisternodeOutlined,
  pool: GroupOutlined,
};
export default function LeftMenu() {
  const history = useHistory();
  const { t } = useTranslation();
  const defaultLang = i18next.language;
  const [currentLang, setCurrentLang] = useState(defaultLang);
  const [roleBasedRoutes, setRoleBasedRoutes] = useState([]);
  const handleSignOut = useCallback(() => {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');

    history.push('/');
  }, [history]);

  const user = JSON.parse(window.localStorage.getItem('user'));
  useEffect(() => {
    const userRole = JSON.parse(window.localStorage.getItem('user')).role;
    setRoleBasedRoutes(routes(t).privateRoutes[userRole]);
  }, [setRoleBasedRoutes, t]);

  const handleChangeLang = (lang) => {
    i18next.changeLanguage(lang);
    setCurrentLang(lang);
  };
  return (
    <Menu
      mode="inline"
      style={{
        backgroundColor: 'rgba(0,0,0,0)',
        width: '253px',
        marginTop: '40px',
        marginLeft: '-24px',
      }}
    >
      <SubMenu
        style={{ backgroundColor: '#fff' }}
        className="headerflagmenu"
        key="sub1"
        title={
          <Avatar
            size="small"
            src={require(`../../config/img/${currentLang}.svg`)}
          />
        }
      >
        <Menu.Item key="tr" onClick={() => handleChangeLang('tr')}>
          <Avatar size="small" src={require('../../config/img/tr.svg')} />
        </Menu.Item>
        <Menu.Item key="en" onClick={() => handleChangeLang('en')}>
          <Avatar size="small" src={require('../../config/img/en.svg')} />
        </Menu.Item>
        <Menu.Item key="de" onClick={() => handleChangeLang('de')}>
          <Avatar
            size="small"
            style={{ background: '#595959' }}
            src={require('../../config/img/de.svg')}
          />
        </Menu.Item>
      </SubMenu>
      <SubMenu
        className="headerusermenu"
        key="sub2"
        title={
          <span>
            <UserOutlined style={{ color: '#595959' }} /> {user.username}
          </span>
        }
      >
        <Menu.Item key="menu1">
          <UserOutlined style={{ color: '#595959' }} />
          <Link to="/userDetail" style={{ color: '#595959' }}>
            {t('titles.userInfo')}
          </Link>
        </Menu.Item>
        <Menu.Item onClick={handleSignOut} key="menu2">
          <LogoutOutlined style={{ color: '#595959' }} />{' '}
          <span style={{ color: '#595959' }}> {t('buttons.logout')}</span>
        </Menu.Item>
      </SubMenu>

      {roleBasedRoutes.map((data) => {
        let SpecificIcon = icons[data.icon];

        SpecificIcon = SpecificIcon || HomeOutlined;

        if (data.subRoutes) {
          return (
            <SubMenu
              style={{ background: '#fff' }}
              key={data.id}
              title={
                <div>
                  <SpecificIcon style={{ color: '#595959' }} /> {data.name}
                </div>
              }
            >
              {data.subRoutes.map((subItem, i) => {
                const SpecificIcon = icons[subItem.icon];
                if (subItem.childRoutes) {
                  return (
                    <SubMenu
                      style={{ background: '#fff' }}
                      key={subItem.id}
                      title={
                        <div style={{ color: '#595959' }}>
                          <SpecificIcon style={{ color: '#595959' }} />{' '}
                          {subItem.name}
                        </div>
                      }
                    >
                      {subItem.childRoutes.map((subChild, i) => {
                        const SpecificIcon = icons[subChild.icon];
                        return (
                          <Menu.Item
                            key={subChild.id}
                            style={{ background: '#fff' }}
                          >
                            <Link
                              to={subChild.link}
                              style={{ color: '#595959' }}
                            >
                              <SpecificIcon style={{ color: '#595959' }} />{' '}
                              {subChild.name}
                            </Link>
                          </Menu.Item>
                        );
                      })}
                    </SubMenu>
                  );
                }
                return (
                  <Menu.Item key={subItem.id} style={{ background: '#fff' }}>
                    <Link to={subItem.link} style={{ color: '#595959' }}>
                      <SpecificIcon style={{ color: '#595959' }} />{' '}
                      {subItem.name}
                    </Link>
                  </Menu.Item>
                );
              })}
            </SubMenu>
          );
        }
        return (
          <Menu.Item key={data.id} style={{ background: '#fff' }}>
            <Link to={data.link} style={{ color: '#595959' }}>
              <SpecificIcon style={{ color: '#595959' }} /> {data.name}
            </Link>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}
