/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';

import dateParser from '../../../utils.js/dateParser';
import StartEndFilter from '../../../components/StartEndFilter';
import RELEASED_SOUNDS_OF_BRANCH from './gql';
import ServerWrong from '../../../components/Result/ServerWrong';

const columnsConfig = (t) => [
  
 
  {
    title: t('columns.jinglePools'),
    dataIndex: 'jinglePoolId',
    key: 'jinglePoolId',
    width: '15%',
    inputType: 'jinglePoolSelector',
    editable: false,
    render: (_, record) => {
      console.log(record)
      return record?.jinglePool.name || t('warnings.noData');
    },
  },
  {
    title: t('labels.inHowManySongs'),
    dataIndex: 'period',
    key: 'period',
    width: '11%',
    inputType: 'inputNumberSelector',
    propsForSelector: {
      min: 1,
      max: 50,
    },
    editable: true,
  },

  {
    title: t('labels.startDate'),
    dataIndex: 'start',
    key: 'start',
    inputType: 'dateWithTimeSelector',
    propsForSelector: { fieldName: 'start' },
    editable: true,

    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      const { date, time } = dateParser(value);

      return [date, '  ', time];
    },
  },
  {
    title: t('labels.endDate'),
    dataIndex: 'end',
    key: 'end',
    inputType: 'dateWithTimeSelector',
    propsForSelector: { fieldName: 'end' },
    editable: true,

    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      const { date, time } = dateParser(value);

      return [date, '  ', time];
    },
  },
];

const BranchSounds = () => {
  // hooks
  const { t } = useTranslation();
  const [request, setRequest] = useState();

  const { loading, error, data } = useQuery(RELEASED_SOUNDS_OF_BRANCH, {
    variables: request,
    skip: !request,
    fetchPolicy: 'network-only',
  });

  // #region handles
  const handleFilterResult = useCallback(
    ({ startDate, endDate }) => {
      const user = JSON.parse(localStorage.getItem('user'));

      setRequest({
        condition: {
          limit: -1,
          order: [['createdAt', 'desc']],
          where: {
            id: user?.partnerBranch?.id,
          },
        },
        start: startDate,
        end: endDate,
      });
    },
    [setRequest]
  );
  // #endregion

  const columns = columnsConfig(t);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading}>
      <StartEndFilter handleFilterResult={handleFilterResult} />
      <Table
        dataSource={data?.partnerBranches?.data[0]?.releasedJinglePool || []}
        rowKey={(record, key) => {
          // eslint-disable-next-line no-param-reassign
          record.key = key;
          return key;
        }}
        columns={columns}
        pagination={{
          defaultCurrent: 1,
          total: data?.partnerBranches?.totalCount,
          pageSize: 10,
          responsive: true,
        }}
      />
    </Spin>
  );
};
export default BranchSounds;
