/* eslint-disable no-new */
import React, { useRef, useEffect } from 'react';
import Chart from 'chart.js';

const AdvertisementPieChart = ({ labels, chartData, title }) => {
  const pieChartRef = useRef();

  useEffect(() => {
    if (pieChartRef.current) {
      new Chart(pieChartRef.current, {
        type: 'pie',
        data: {
          labels,
          datasets: chartData,
        },
        options: {
          title: {
            display: true,
            text: title,
          },
        },
      });
    }
  }, [labels, chartData, title]);

  return <canvas height={150} ref={pieChartRef} />;
};

export default AdvertisementPieChart;
