/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import {
  Calendar,
  Drawer,
  Collapse,
  Alert,
  Spin,
  Divider,
  Row,
  Col,
  Tooltip,
  Avatar,
} from 'antd';
import './calander.css';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import CALENDAR_VIEW from './gql';
import { monthsArray } from '../../constants';
import ServerWrong from '../../components/Result/ServerWrong';

const { Panel } = Collapse;

const Dashboard = () => {
  // hooks
  const { t } = useTranslation();
  const [condition, setCondition] = useState();
  const [visible, setVisible] = useState(false);
  const { loading, error, data } = useQuery(CALENDAR_VIEW, {
    variables: condition,
    skip: !condition,
    fetchPolicy: 'network-only',
  });
  const [selectedDate, setSelectedDate] = useState();
  const [currentMonthData, setCurrentMonthData] = useState();
  const [currentDayData, setCurrentDayData] = useState();

  useEffect(() => {
    const monthIndex = new Date().getMonth();
    const monthName = monthsArray[monthIndex];
    const year = new Date().getFullYear();

    setCondition({ year, month: monthName });
  }, [setCondition]);

  useEffect(() => {
    setCurrentMonthData(data?.calendarView?.calendar);
  }, [data, setCurrentMonthData]);

  // handles
  const handleOnSelect = useCallback(
    (currentSelectedDate) => {
      const formattedDate = currentSelectedDate.format('YYYY-M');
      const year = Number(formattedDate.split('-')[0]);
      const monthIndex = formattedDate.split('-')[1] - 1;
      const monthName = monthsArray[monthIndex];

      setCondition({ year, month: monthName });
      setSelectedDate(currentSelectedDate);
      setVisible(true);

      const rawCurrentDayData =
        currentMonthData[currentSelectedDate.format('D-M-YYYY')];

      const formattedCurrentDayData = rawCurrentDayData?.reduce((acc, cur) => {
        acc[cur.partner.id] = acc[cur.partner.id]
          ? [...acc[cur.partner.id], cur]
          : [cur];
        return acc;
      }, {});

      setCurrentDayData(formattedCurrentDayData);
    },
    [setSelectedDate, setCondition, setVisible, currentMonthData]
  );

  const onClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleDateCellRender = useCallback(
    (date) => {
      if (currentMonthData) {
        const rawCurrentDayData = currentMonthData[date.format('D-M-YYYY')];

        const formattedCurrentDayData = rawCurrentDayData?.reduce(
          (acc, cur) => {
            acc[cur.partner.id] = acc[cur.partner.id]
              ? [...acc[cur.partner.id], cur]
              : [cur];
            return acc;
          },
          {}
        );

        if (!formattedCurrentDayData) {
          return '';
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar size={70}>
              <h4>{`+ ${Object.keys(formattedCurrentDayData)?.length}`}</h4>
            </Avatar>
          </div>
        );
      }
      return '';
    },
    [currentMonthData]
  );

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading}>
      {selectedDate && (
        <Alert
          message={`${t('messages.uSelectedDate')}: ${selectedDate.format(
            'YYYY-MM-DD'
          )}`}
        />
      )}
      <Calendar
        className="dashboardCalendar"
        mode="month"
        value={selectedDate}
        onSelect={handleOnSelect}
        dateCellRender={handleDateCellRender}
      />

      <Drawer
        className="drawer"
        title={selectedDate ? selectedDate.format('DD-MM-YYYY') : ''}
        placement="right"
        closable
        onClose={onClose}
        visible={visible}
      >
        {currentDayData && (
          <Collapse>
            {Object.keys(currentDayData).map((partnerId) => (
              <Panel
                header={currentDayData?.[partnerId][0]?.partner?.name || ''}
                key={partnerId}
              >
                {currentDayData[partnerId].map((datum, index) => (
                  <div key={index}>
                    <p>
                      {`${t(`titles.${datum?.type}`)} ${t(
                        'labels.wasDefined'
                      )}.`}
                    </p>
                    <p>{`${t('labels.affectedBranches')}:`}</p>
                    <Row>
                      {datum?.partnerBranches.map((branch) => (
                        <Col key={branch?.id} md={8} sm={16} xs={16}>
                          <p>
                            <Tooltip title={branch?.name}>
                              {branch?.name}
                            </Tooltip>
                          </p>
                        </Col>
                      ))}
                    </Row>
                    <Divider />
                  </div>
                ))}
              </Panel>
            ))}
          </Collapse>
        )}
      </Drawer>
    </Spin>
  );
};
export default Dashboard;
