import { Select } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const CustomSelector = ({
  onChange,
  initialrecord,
  getReq,
  customWhereCreator,
  placeholder,
  getResIndex,
  mode,
  customLimit = -1,
  defaultValueIndex,
  searchWithService = true,
}) => {
  const { t } = useTranslation();
  // hooks
  const [selectedOption, setSelectedOption] = useState();
  const [condition, setCondition] = useState();
  const { loading, error, data } = useQuery(getReq, {
    variables: { condition },
    skip: !condition,
  });

  useEffect(() => {
    setCondition({
      limit: customLimit,
      where: customWhereCreator(),
    });
  }, [customWhereCreator, customLimit, setCondition]);

  const handleSearch = useCallback(
    (input) => {
      if (input) {
        setCondition({
          limit: customLimit,
          where: customWhereCreator(input),
        });
      }
    },
    [customLimit, customWhereCreator, setCondition]
  );

  const handleChange = useCallback(
    (value) => {
      if (mode === 'multiple' && value.includes('all')) {
        // deselect all
        if (
          selectedOption &&
          selectedOption.length === data?.[getResIndex].data.length
        ) {
          setSelectedOption();
          onChange();
        }
        // select all
        else {
          const ids = data?.[getResIndex]?.data.map((datum) => datum.id);
          setSelectedOption(ids);
          onChange(ids);
        }
      }
      // select one
      else {
        setSelectedOption(value);
        onChange(value);
      }
    },
    [onChange, data, selectedOption, getResIndex, mode]
  );

  const handleFilter = useCallback((input, option) => {
    return option.children.toLowerCase().includes(input.toLowerCase());
  }, []);

  if (error) {
    return t('messages.somethingBaad');
  }

  let searchProps = { filterOption: false, onSearch: handleSearch };
  if (!searchWithService) {
    searchProps = { filterOption: handleFilter };
  }

  return (
    <Select
      mode={mode}
      defaultValue={initialrecord?.[defaultValueIndex]}
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t(`placeholders.${placeholder || 'selectValue'}`)}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      {...searchProps}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
    >
      {mode === 'multiple' && (
        <Option key="all" value="all">
          {`---${t('buttons.selectAll')}---`}
        </Option>
      )}
      {data?.[getResIndex]?.data &&
        data[getResIndex].data.map((datum) => (
          <Option value={datum.id} key={datum.id}>
            {datum.name}
          </Option>
        ))}
    </Select>
  );
};

export default CustomSelector;
