import { Select } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SEARCH_SONG_POOLS, SEARCH_PARTNERS } from './gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const { Option } = Select;

const SongPoolSelector = ({ onChange, initialrecord, propsForSelector }) => {
  const { t } = useTranslation();
  const { role, partnerId } = userInfoGetter();

  // hooks
  const [songPoolSelectedOption, setSongPoolSelectedOption] = useState();
  const [songPoolQueryCondition, setSongPoolQueryCondition] = useState({
    limit: -1,
    where: { partnerId },
  });
  const { loading, error, data: songPoolsData } = useQuery(SEARCH_SONG_POOLS, {
    variables: { condition: songPoolQueryCondition },
  });

  const [selectedPartners, setSelectedPartners] = useState();
  const [partnersQueryCondition, setPartnersQueryCondition] = useState();
  const {
    loading: loadingForPartnerQuery,
    errorForPartnersQuery,
    data: partnersData,
  } = useQuery(SEARCH_PARTNERS, {
    variables: { condition: partnersQueryCondition },
    skip: !partnersQueryCondition,
  });

  useEffect(() => {
    if (propsForSelector?.initialSongPoolId) {
      setSongPoolSelectedOption(propsForSelector.initialSongPoolId);
      setPartnersQueryCondition({
        limit: -1,
        where: {
          songPoolId: propsForSelector.initialSongPoolId,
        },
      });
      setSelectedPartners(propsForSelector.initialPartnerIds);
      onChange({
        songPoolId: propsForSelector.initialSongPoolId,
        partnerIds: propsForSelector.initialPartnerIds,
      });
    } else {
      setSongPoolSelectedOption();
      setPartnersQueryCondition();
      setSelectedPartners();
      onChange();
    }
  }, [propsForSelector]); // eslint-disable-line

  // handles
  const handleSongPoolSearch = useCallback(
    (input) => {
      if (input) {
        setSelectedPartners();
        setSongPoolQueryCondition({
          limit: -1,
          where: {
            partnerId,
            _or: {
              name: { _iLike: `%${input}%` },
            },
          },
        });
      }
    },
    [partnerId]
  );

  const handleSongPoolChange = useCallback(
    (value) => {
      setSongPoolSelectedOption(value);
      setSelectedPartners();
      setPartnersQueryCondition({
        limit: -1,
        where: {
          songPoolId: value,
        },
      });

      const partnerIds = role === 'PARTNER' ? [partnerId] : [];
      onChange({
        songPoolId: value,
        partnerIds,
      });
    },
    [onChange, role, partnerId]
  );

  const handlePartnersSearch = useCallback(
    (input) => {
      if (input) {
        setPartnersQueryCondition({
          limit: -1,
          where: {
            songPoolId: songPoolSelectedOption,
            _or: {
              name: { _iLike: `%${input}%` },
            },
          },
        });
      }
    },
    [songPoolSelectedOption]
  );

  const handlePartnersChange = useCallback(
    (value) => {
      setSelectedPartners(value);
      onChange({
        songPoolId: songPoolSelectedOption,
        partnerIds: value,
      });
    },
    [onChange, songPoolSelectedOption]
  );

  if (error || errorForPartnersQuery) {
    return t('messages.somethingBad');
  }

  return (
    <>
      <Select
        loading={loading}
        showSearch
        value={songPoolSelectedOption}
        placeholder={t('messages.selectSongPool')}
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSongPoolSearch}
        onChange={handleSongPoolChange}
        allowClear
        notFoundContent={t('messages.notFoundContent')}
      >
        {songPoolsData?.songPools?.data?.map((pool) => (
          <Option value={Number(pool.id)} key={pool.id}>
            {pool.name}
          </Option>
        ))}
      </Select>

      {role !== 'PARTNER' && songPoolSelectedOption && (
        <Select
          mode="multiple"
          loading={loadingForPartnerQuery}
          showSearch
          value={selectedPartners}
          placeholder={t('messages.selectPartner')}
          style={{ width: '100%', marginTop: 20 }}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handlePartnersSearch}
          onChange={handlePartnersChange}
          notFoundContent={t('messages.notFoundContent')}
        >
          {partnersData?.partners?.data?.map((partner) => (
            <Option value={partner.id} key={partner.id}>
              {partner.name}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
};

export default SongPoolSelector;
