import { Select, Row, Col, Button, notification } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SEARCH_PARTNERS } from '../PartnerSelector/gql';
import SEARCH_PARTNER_BRANCHES from '../PartnerBranchSelector/gql';
import JINGLE_POOLS_BY_PARTNER from './gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const { Option } = Select;
const openNotification = (t) => {
  const key = `open${Date.now()}`;
  const btn = (
    <Button type="primary" size="small" onClick={() => notification.close(key)}>
      {t('buttons.confirm')}
    </Button>
  );
  notification.warning({
    duration: 0,
    message: t('warnings.allBranchesSelected'),
    description: t('warnings.chosenJingleAffectAllBranches'),
    btn,
    key,
  });
};
const PartnerAndBranchMultiSelector = ({ onChange }) => {
  // #region hooks
  const { t } = useTranslation();
  const { role, partnerId } = userInfoGetter();
  const [selectedPartner, setSelectedPartner] = useState();

  const [branchCondition, setBranchCondition] = useState();
  const [selectedBranches, setSelectedBranches] = useState([]);
  const [jinglePoolCondition, setJinglePoolCondition] = useState();
  const [selectedJinglePool, setSelectedJinglePool] = useState();
  // #endregion hooks

  // #region  gql queries
  const {
    error: partnerError,
    loading: partnerLoading,
    data: partnerData,
  } = useQuery(SEARCH_PARTNERS, {
    variables: { condition: { limit: -1 } },
  });

  const {
    error: branchError,
    loading: branchLoading,
    data: branchData,
  } = useQuery(SEARCH_PARTNER_BRANCHES, {
    variables: { condition: branchCondition },
    skip: !branchCondition,
  });

  const {
    error: jinglePoolError,
    loading: jinglePoolLoading,
    data: jinglePoolData,
  } = useQuery(JINGLE_POOLS_BY_PARTNER, {
    variables: { condition: jinglePoolCondition },
    skip: !jinglePoolCondition,
  });

  // #endregion gql queries

  // select handles
  const handlePartnerChange = useCallback((value) => {
    setSelectedJinglePool();
    setSelectedBranches();
    setSelectedPartner(value);

    const conditionsQuery = {
      limit: -1,
      where: {
        partnerId: value,
      },
    };
    setBranchCondition(conditionsQuery);
    setJinglePoolCondition(conditionsQuery);
  }, []);

  const handleJinglePoolChange = useCallback(
    (value) => {
      setSelectedJinglePool(value);

      onChange({
        partnerId: selectedPartner,
        partnerBranchIds: selectedBranches,
        jinglePoolId: value,
      });
    },
    [onChange, selectedPartner, selectedBranches]
  );
  useEffect(() => {
    if (role === 'PARTNER') {
      handlePartnerChange(partnerId);
    }
  }, [handlePartnerChange, partnerId, role]);
  const handleBranchChange = useCallback(
    (values) => {
      if (values.includes('all')) {
        // deselect all
        if (
          selectedBranches &&
          selectedBranches.length === branchData?.partnerBranches?.data?.length
        ) {
          setSelectedBranches();
        }
        // select all
        else {
          openNotification(t);
          const ids = branchData?.partnerBranches?.data?.map(
            (datum) => datum.id
          );
          setSelectedBranches(ids);
        }
      }
      // select one
      else {
        setSelectedBranches(values);
      }
    },

    [t, branchData, selectedBranches, setSelectedBranches]
  );

  if (partnerError || branchError || jinglePoolError) {
    return t('messages.somethingBad');
  }

  return (
    <Row gutter={16}>
      <Col md={12} sm={24} xs={24}>
        {role === 'PARTNER' ? (
          <Select
            disabled
            loading={partnerLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            value={selectedPartner}
            placeholder={t('placeholders.selectPartner')}
            style={{ width: '100%' }}
            onChange={handlePartnerChange}
          >
            {partnerData?.partners?.data?.map((partner) => (
              <Option value={partner.id} key={partner.id}>
                {partner.name}
              </Option>
            ))}
          </Select>
        ) : (
          <Select
            loading={partnerLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            value={selectedPartner}
            placeholder={t('placeholders.selectPartner')}
            style={{ width: '100%' }}
            onChange={handlePartnerChange}
          >
            {partnerData?.partners?.data &&
              partnerData?.partners?.data?.map((partner) => (
                <Option value={partner.id} key={partner.id}>
                  {partner.name}
                </Option>
              ))}
          </Select>
        )}
      </Col>

      <Col md={12} sm={24} xs={24}>
        {selectedPartner && (
          <>
            <Select
              showSearch
              mode="multiple"
              loading={branchLoading}
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              value={selectedBranches}
              placeholder={t('placeholders.selectBranch')}
              style={{ width: '100%' }}
              onChange={handleBranchChange}
            >
              <Option key="all" value="all">
                {`---${t('buttons.selectAll')}---`}
              </Option>
              {branchData?.partnerBranches?.data?.map((branch) => (
                <Option value={branch.id} key={branch.id}>
                  {branch.name}
                </Option>
              ))}
            </Select>

            <Select
              defaultActiveFirstOption={false}
              loading={jinglePoolLoading}
              showArrow={false}
              filterOption={false}
              value={selectedJinglePool}
              placeholder={t('placeholders.selectJingle')}
              style={{ width: '100%', marginTop: 20 }}
              onChange={handleJinglePoolChange}
            >
              {jinglePoolData?.jinglePools?.data?.map((jinglePools) => (
                <Option value={jinglePools.id} key={jinglePools.id}>
                  {jinglePools.name}
                </Option>
              ))}
            </Select>
          </>
        )}
      </Col>
    </Row>
  );
};

export default PartnerAndBranchMultiSelector;
