import { gql } from 'apollo-boost';

export const CREATE_SONG_CATEGORY = gql`
  mutation createSongCategory($data: SongCategoryInput!) {
    createSongCategory(data: $data) {
      __typename
      ... on SongCategoryCreateSuccess {
        songCategory {
          id
          name
          createdAt
          updatedAt
          songCount
        }
      }
      ... on SongCategoryInvalidInputError {
        name
      }
    }
  }
`;

export const CATEGORIES = gql`
  query songCategories($condition: Condition) {
    songCategories(condition: $condition) {
      __typename

      ... on PaginatedSongCategoryResponse {
        data {
          id
          name
          createdAt
          updatedAt
          songCount
        }
        totalCount
      }
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;
export const UPDATE_SONG_CATEGORY = gql`
  mutation updateSongCategory($where: JSON!, $newValues: SongCategoryInput!) {
    updateSongCategory(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on SongCategoryInvalidInputError {
        name
      }
      ... on SongCategoryUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          createdAt
          updatedAt
          songCount
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_SONG_CATEGORY = gql`
  mutation deleteSongCategory($where: JSON!) {
    deleteSongCategory(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on SongCategoryDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          createdAt
          updatedAt
          songCount
        }
      }
    }
  }
`;
