import React, { useState, useCallback } from 'react';
import { Avatar, Menu } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const { SubMenu } = Menu;

const Flags = () => {
  const defaultLang = i18next.language;

  const [currentLang, setCurrentLang] = useState(defaultLang);
  const { t } = useTranslation();

  const handleChangeLang = useCallback(lang => {
    i18next.changeLanguage(lang);
    setCurrentLang(lang);
  }, []);

  return (
    <Menu
      mode="horizontal"
      defaultSelectedKeys={['0']}
      style={{
        backgroundColor: 'rgba(0,0,0,0)',
        borderBottom: 0
      }}
    >
      <SubMenu
        key="sub2"
        title={
          <Avatar
            size="small"
            src={require(`../../config/img/${currentLang}.svg`)}
          />
        }
      >
        <Menu.Item key="tr" onClick={() => handleChangeLang('tr')}>
          <Avatar size="small" src={require('../../config/img/tr.svg')} />
          <span> {t('titles.tr')}</span>
        </Menu.Item>
        <Menu.Item key="en" onClick={() => handleChangeLang('en')}>
          <Avatar size="small" src={require('../../config/img/en.svg')} />
          <span> {t('titles.en')}</span>
        </Menu.Item>
        <Menu.Item key="de" onClick={() => handleChangeLang('de')}>
          <Avatar size="small" src={require('../../config/img/de.svg')} />
          <span> {t('titles.de')}</span>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};

export default Flags;
