import { gql } from 'apollo-boost';

export const SONGS_OF_PLAYLIST = gql`
  query songsOfPlaylist($where: JSON!) {
    playlist(where: $where) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on Playlist {
        id
        name
        createdBy {
          id
        }
        songs {
          id
          name
          artist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Artist {
              id
              firstName
              lastName
            }
          }
          album {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Album {
              id
              name
            }
          }
          file {
            id
            address
          }
        }
      }
    }
  }
`;

export const REMOVE_SONG_FROM_PLAYLIST = gql`
  mutation deleteSongFromPlaylist($data: PlaylistSongDeleteRelation!) {
    deleteSongFromPlaylist(data: $data) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;
