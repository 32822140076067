/* eslint-disable no-undef */
/* eslint-disable consistent-return */
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'antd';
import IntegratedEditableTable from '../../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../../components/ModalFormBuilder';
import {
  JINGLE_POOLS,
  UPDATE_JINGLE_POOL,
  DELETE_JINGLE_POOL,
  CREATE_JINGLE_POOL,
} from './gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const fieldsCreator = (t) => {
  return [
    {
      name: 'name',
      label: t('titles.jingle'),
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
        {
          type: 'string',
          message: t('warnings.string'),
        },
        {
          min: 2,
          message: t('warnings.min'),
        },
        {
          max: 150,
          message: t('warnings.max'),
        },
      ],
    },
    {
      name: 'partnerIds',
      label: t('labels.partner'),
      inputType: 'partnerMultiSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
    },
  ];
};
const columnsConfig = (t) => [
  {
    title: t('columns.jinglePoolName'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
  },
  {
    title: t('titles.partnerName'),
    dataIndex: 'partnerIds',
    key: 'partnerIds',
    inputType: 'partnerMultipleSelector',
    render: (_, record) => {
      return record?.partners?.map((partner) => (
        <span style={{ display: 'block' }} key={partner.id}>
          {partner.name}
        </span>
      ));
    },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    width: 120,
  },
  {
    title: t('titles.jingleCount'),
    dataIndex: 'jingleCount',
    key: 'jingleCount',
  },
];

export default function JinglePool() {
  const { role, partnerId } = userInfoGetter();
  const token = localStorage.getItem('token');

  // hooks
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t, token);

  const upperGetReqConditionCreator = () => {
    if (role === 'PARTNER') {
      return {
        limit: 10,
        offset: 0,
        order: [['createdAt', 'desc']],
        where: {
          partnerId,
        },
      };
    }
    return {
      limit: 10,
      offset: 0,
      order: [['createdAt', 'desc']],
    };
  };

  const afterGetFunc = useCallback((response) => {
    const newData = response?.jinglePools?.data?.map((jinglePool) => ({
      id: jinglePool.id,
      name: jinglePool.name,

      partnerIds: jinglePool?.partners?.map((partner) => partner.id),
    }));

    return {
      ...response,
      jinglePool: { ...response?.jinglePool, data: newData },
    };
  }, []);

  return (
    <Row>
      <Col span={24} style={{ marginTop: '20px' }}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newJinglePoolName')}
          showModalButtonLabel={t('titles.newJinglePoolName')}
          fields={fields}
          postReq={CREATE_JINGLE_POOL}
          postResIndex="createJinglePool"
          postResFieldForUpdating="jinglePool"
          willBeUpdatedQuery={JINGLE_POOLS}
          willBeUpdatedQueryName="jinglePools"
        />
      </Col>

      <Col span={24}>
        <IntegratedEditableTable
          columns={columns}
          upperGetReqCondition={upperGetReqConditionCreator()}
          getReq={JINGLE_POOLS}
          getResIndex="jinglePools"
          updateReq={UPDATE_JINGLE_POOL}
          updateResIndex="updateJinglePool"
          deleteReq={DELETE_JINGLE_POOL}
          deleteResIndex="deleteJinglePool"
          afterGetFunc={afterGetFunc}
          filterColumns={{
            jinglePool: ['name'],
          }}
          operationsDisabledStatus={role === 'PARTNER'}
        />
      </Col>
    </Row>
  );
}
