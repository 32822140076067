import React, { useState, useCallback } from 'react';
import { Row, Col, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import {
  CREATE_SONG_POOL,
  SONG_POOLS,
  UPDATE_SONG_POOL,
  DELETE_SONG_POOL,
} from './gql';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.categoryName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.categoryName'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
  },
  {
    title: t('titles.totalFiles'),
    dataIndex: 'songCount',
    key: 'songCount',
    editable: false,
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
];

export default function MusicPool() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.data?.map((songPools) => ({
      id: songPools.id,
      name: songPools.name,
    }));

    return {
      ...response,
      songPoolCategory: { ...response?.songPoolCategory, data: newData },
    };
  }, []);
  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('titles.songPoolCategory')}</Divider>

      <Col span={24} style={{ marginTop: '20px' }}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newSongPoolAdd')}
          showModalButtonLabel={t('titles.newSongPoolAdd')}
          fields={fields}
          postReq={CREATE_SONG_POOL}
          postResIndex="createSongPool"
          postResFieldForUpdating="songPool"
          willBeUpdatedQuery={SONG_POOLS}
          willBeUpdatedQueryName="songPools"
        />
      </Col>
      <Col style={{ marginTop: 10 }} md={24} lg={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={SONG_POOLS}
          getResIndex="songPools"
          deleteReq={DELETE_SONG_POOL}
          deleteResIndex="deleteSongPool"
          updateReq={UPDATE_SONG_POOL}
          updateResIndex="updateSongPool"
          afterGetFunc={afterGetFunc}
          filterColumns={{ songPool: ['name'] }}
        />
      </Col>
    </Row>
  );
}
