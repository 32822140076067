import { gql } from 'apollo-boost';

const SEARCH_MELODIST = gql`
  query melodists($condition: Condition) {
    melodists(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedMelodistResponse {
        data {
          id
          firstName
          lastName
        }
        totalCount
      }
    }
  }
`;

export default SEARCH_MELODIST;
