import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import { ApolloProvider } from '@apollo/react-hooks';
import './i18n';
import i18next from 'i18next';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faCoffee,
  faAd,
  faAlignJustify,
  faArrowRight,
  faArrowLeft,
  faMusic,
  faVolumeUp,
  faHourglassStart,
  faHourglassEnd,
  faTrash,
  faMicrophone,
  faCalculator,
  faPenSquare,
  faCalendarAlt,
  faCalendarDay,
  faHandshake,
  faCodeBranch,
  faIceCream,
  faRss,
  faPlayCircle,
  faBookOpen,
} from '@fortawesome/free-solid-svg-icons';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import trTR from 'antd/es/locale/tr_TR';
import enUS from 'antd/es/locale/en_US';
import deDE from 'antd/es/locale/de_DE';
import * as serviceWorker from './serviceWorker';
import AppRouter from './appRouter';
import store from './redux/store/store';
import client from './client';
import 'moment/locale/tr';
import 'moment/locale/en-au';
import 'moment/locale/de';

library.add(
  faSpinner,
  faCoffee,
  faAd,
  faAlignJustify,
  faArrowRight,
  faMusic,
  faVolumeUp,
  faHourglassStart,
  faHourglassEnd,
  faTrash,
  faMicrophone,
  faCalculator,
  faArrowLeft,
  faPenSquare,
  faCalendarAlt,
  faCalendarDay,
  faHandshake,
  faCodeBranch,
  faIceCream,
  faRss,
  faPlayCircle,
  faBookOpen
);

let locale;
switch (i18next.language) {
  case 'tr':
    locale = trTR;
    break;
  case 'en':
    locale = enUS;
    break;
  case 'de':
    locale = deDE;
    break;
  default:
    locale = trTR;
    break;
}

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <Router>
        <ConfigProvider locale={locale}>
          <AppRouter />
        </ConfigProvider>
      </Router>
    </Provider>
  </ApolloProvider>,

  // eslint-disable-next-line no-undef
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
