import React, { useCallback } from 'react';

import MaskedInput from 'antd-mask-input';

const PhoneSelector = ({ onChange, initialrecord }) => {
  const handleChange = useCallback(
    (phone) => {
      onChange(phone);
    },
    [onChange]
  );

  return (
    <MaskedInput
      mask="+90(111)1111111"
      size="20"
      defaultValue={initialrecord?.primaryPhone}
      onChange={handleChange}
    />
  );
};

export default PhoneSelector;
