/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Table,
  Spin,
  DatePicker,
  message,
  Tooltip,
  Card,
  Row,
  Col,
} from 'antd';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { BACKEND_URL, daysOfWeek } from '../../../constants';
import SONGS_OF_RELEASED_PLAYLIST from './gql';
import ServerWrong from '../../../components/Result/ServerWrong';

const columnsConfig = (t) => [
  {
    title: t('titles.songName'),
    dataIndex: 'name',
    key: 'songName',
    editable: false,
  },

  {
    title: t('titles.Artist'),
    dataIndex: 'artist',
    key: 'artist',
    editable: false,
    render: (_, record) => {
      const firstName = record.artist.firstName;
      const lastName = record.artist.lastName;

      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
      return t('warnings.noData');
    },
  },
  {
    title: t('labels.duration'),
    dataIndex: 'file',
    key: 'duration',
    editable: false,
    render: (file) => {
      if (file?.duration) {
        const durations = file.duration / 1000;
        return moment
          .duration(durations.toFixed(2), 'seconds')
          .format(
            `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
              'labels.seconds'
            )}]`
          );
      }

      return t('warnings.noData');
    },
  },
  {
    title: t('titles.song'),
    dataIndex: 'file',
    key: 'address',
    editable: false,
    render: (file) => {
      return (
        <audio
          controls
          controlsList="nodownload"
          style={{ width: '190px' }}
          src={BACKEND_URL + file?.address}
        />
      );
    },
  },
];

const BranchSongs = () => {
  // hooks
  const { t } = useTranslation();
  const [request, setRequest] = useState();
  const [songsOfReleasedPlaylists, setSongsOfReleasedPlaylists] = useState();

  const { loading, error, data } = useQuery(SONGS_OF_RELEASED_PLAYLIST, {
    variables: request,
    skip: !request,
    fetchPolicy: 'network-only',
  });

  // #region handles
  const handleFilterResult = useCallback(
    (_, dateString) => {
      const user = JSON.parse(localStorage.getItem('user'));

      const selectedDayIndex = moment(dateString).day();
      const selectedDayName = daysOfWeek[selectedDayIndex];

      setRequest({
        condition: {
          limit: -1,
          order: [['createdAt', 'desc']],
          where: {
            id: user?.partnerBranch?.id,
          },
        },
        days: [selectedDayName, 'MONDAY'],
      });
    },
    [setRequest]
  );
  // #endregion

  const columns = columnsConfig(t);

  useEffect(() => {
    if (data === undefined) {
      return;
    }

    const releasedPlaylists = data?.partnerBranches?.data[0]?.releasedPlaylists;

    if (releasedPlaylists?.length > 0) {
      const mergedSongsOfPlaylists = releasedPlaylists.reduce((acc, cur) => {
        acc = [...acc, ...cur?.playlist?.songs];
        return acc;
      }, []);
      console.log(mergedSongsOfPlaylists);

      setSongsOfReleasedPlaylists(mergedSongsOfPlaylists);
    } else {
      message.warning(t('warnings.noData'));
    }
  }, [t, data]);

  if (error) {
    return <ServerWrong />;
  }
  const text = <span>{t('labels.dateTooltip')}</span>;
  const title = <h4>{t('labels.dateTooltip')}</h4>;
  return (
    <Spin spinning={loading}>
      <Card>
        <Row>
          <Col md={24}>{title}</Col>
          <Col md={24} style={{ marginTop: '10px' }}>
            <Tooltip title={text}>
              <DatePicker
                placeholder={t('placeholders.selectDate')}
                onChange={handleFilterResult}
              />
            </Tooltip>
          </Col>
        </Row>
      </Card>

      {data && (
        <Table
          dataSource={songsOfReleasedPlaylists || []}
          rowKey={(record, key) => {
            // eslint-disable-next-line no-param-reassign
            record.key = key;
            return key;
          }}
          columns={columns}
          pagination={{
            defaultCurrent: 1,
            total: data?.partnerBranches?.totalCount,
            pageSize: 10,
            responsive: true,
          }}
        />
      )}
    </Spin>
  );
};
export default BranchSongs;
