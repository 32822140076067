import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, Button } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import {
  PARTNER_BRANCH_SESSION_SUMMARIES,
  ALL_PARTNER_BRANCH_STATUS,
  PARTNER_TOTAL_SESSION_SUMMARY,
  PLACEHOLDER,
} from './gql';
import userInfoGetter from '../../utils.js/userInfoGetter';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const columnsConfig = (t) => [
  {
    title: t('columns.partner'),
    dataIndex: 'partner',
    key: 'partner',
    editable: false,
    render: (_, record) => {
      return record.partner.name || t('warnings.noData');
    },
  },
  {
    title: t('columns.onlineCount'),
    dataIndex: 'onlineCount',
    key: 'onlineCount',
    editable: false,
  },

  {
    title: t('columns.offlineCount'),
    dataIndex: 'offlineCount',
    key: 'offlineCount',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
];
const columnsBranch = (t) => [
  {
    title: t('columns.partnerBranch'),
    dataIndex: 'user',
    key: 'user',
    editable: false,
    render: (_, record) => {
      return record.partnerBranch.name || t('warnings.noData');
    },
  },
  {
    title: t('columns.lastOnline'),
    dataIndex: 'lastOnline',
    key: 'lastOnline',
    editable: false,
    render: (_, record) => {
      return humanizeTimestamp(record.lastOnline);
    },
  },

  {
    title: t('columns.lastOffline'),
    dataIndex: 'lastOffline',
    key: 'lastOffline',
    editable: true,
    render: (_, record) => {
      return humanizeTimestamp(record.lastOffline);
    },
  },
  {
    title: t('columns.status'),
    dataIndex: 'status',
    key: 'status',
    inputType: 'status',
    editable: true,

    sorter: (a, b) => a.status.length - b.status.length,
    sortDirections: ['descend', 'ascend'],
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],

    render: (status) => {
      console.log(status);
      if (status === 'ONLINE') {
        return <span style={{ color: '#228B22' }}> {t('header.online')}</span>;
      }
      return <span style={{ color: 'red' }}> {t('header.offline')} </span>;
    },

    // specify the condition of filtering result
    // here is that finding the name started with `value`
  },
  {
    title: t('columns.version'),
    dataIndex: 'version',
    key: 'version',
    inputType: 'version',
    editable: true,

    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],

    render: (_, record) => {
      if (record.version === null || record.version === 'Unknown') {
        return t('warnings.noApp');
      }
      return record?.version;
    },
  },
];
const OnOff = () => {
  const { role, partnerId } = userInfoGetter();

  const { t } = useTranslation();
  const [selectedPartnerId, setSelectedPartnerId] = useState();
  const { data } = useQuery(ALL_PARTNER_BRANCH_STATUS, {
    variables: { partnerId },
  });

  // const [onlineCount, offlineCount] = useMemo(() => {
  //   console.log(data?.allPartnerBranchStatus)
  //   if (data?.allPartnerBranchStatus?.data) {
  //     let testOnlineCount = 0;
  //     let testOfflineCount = 0;

  //     data.allPartnerBranchStatus.data.forEach((entry) => {
  //       if (
  //         entry.partner &&
  //         new Date(entry.partner.createdAt).getTime() === 0
  //       ) {
  //         testOnlineCount += entry.onlineCount;
  //         testOfflineCount += entry.OfflineCount;
  //       }
  //     });

  //     return [
  //       data.allPartnerBranchStatus.onlineCount - testOnlineCount,
  //       data.allPartnerBranchStatus.offlineCount - testOfflineCount,
  //     ];
  //   }

  //   return [0, 0];
  // }, [data]);

  const columns = columnsConfig(t);
  const branchColumn = columnsBranch(t);

  const upperGetReqConditionCreator = () => {
    if (role === 'PARTNER') {
      return {
        limit: 10,
        offset: 0,
        order: [['createdAt', 'desc']],
        where: {
          partnerId,
        },
      };
    }
    return {
      limit: 10,
      offset: 0,
      order: [['createdAt', 'desc']],
      where: {
        partnerId: selectedPartnerId,
      },
    };
  };

  const extraOperationCreator = useCallback(
    (record) => {
      if (role === 'PARTNER') {
        return '';
      }
      return (
        <Button
          style={{ marginBottom: 5, marginRight: 5 }}
          ghost
          type="primary"
          onClick={() => {
            setSelectedPartnerId(record?.partner?.id);
          }}
        >
          {t('buttons.seeBranches')}
        </Button>
      );
    },
    [setSelectedPartnerId, t, role]
  );

  return (
    <>
      <Divider orientation="center">{t('header.onlineOfflineInfo')}</Divider>
      <Row>
        {role === 'PARTNER' ? (
          <Col md={24}>
            <h3>
              {t('header.totalBranch')}:{' '}
              {data?.allPartnerBranchStatus?.onlineCount} {t('header.online')}{' '}
              {data?.allPartnerBranchStatus?.offlineCount} {t('header.offline')}
            </h3>
          </Col>
        ) : (
          <Col md={24}>
            <h3>
              {t('header.totalPartnerBranch')}:{' '}
              {data?.allPartnerBranchStatus?.onlineCount} {t('header.online')}{' '}
              {data?.allPartnerBranchStatus?.offlineCount} {t('header.offline')}
            </h3>
          </Col>
        )}
      </Row>
      <Row gutter={16}>
        {role === 'PARTNER' ? (
          ''
        ) : (
          <Col md={12}>
            <Divider orientation="center">{t('columns.partner')}</Divider>

            <IntegratedEditableTable
              columns={columns}
              getReq={PARTNER_TOTAL_SESSION_SUMMARY}
              getResIndex="partnerTotalSessionSummary"
              deleteReq={PLACEHOLDER}
              deleteResIndex="placeholder"
              updateReq={PLACEHOLDER}
              updateResIndex="placeholder"
              updatable={false}
              deletable={false}
              extraOperation={extraOperationCreator}
              filterColumns={{
                partner: ['name'],
              }}
            />
          </Col>
        )}
        {role === 'PARTNER' ? (
          <Col md={24}>
            <Divider orientation="center">{t('columns.branch')}</Divider>

            <IntegratedEditableTable
              upperGetReqCondition={upperGetReqConditionCreator()}
              columns={branchColumn}
              getReq={PARTNER_BRANCH_SESSION_SUMMARIES}
              getResIndex="partnerBranchSessionSummaries"
              deleteReq={PLACEHOLDER}
              deleteResIndex="placeholder"
              updateReq={PLACEHOLDER}
              updateResIndex="placeholder"
              updatable={false}
              deletable={false}
              filterColumns={{
                partnerBranch: ['name'],
              }}
            />
          </Col>
        ) : (
          selectedPartnerId && (
            <Col md={12}>
              <Divider orientation="center">{t('columns.branch')}</Divider>
              <IntegratedEditableTable
                upperGetReqCondition={upperGetReqConditionCreator()}
                columns={branchColumn}
                getReq={PARTNER_BRANCH_SESSION_SUMMARIES}
                getResIndex="partnerBranchSessionSummaries"
                deleteReq={PLACEHOLDER}
                deleteResIndex="placeholder"
                updateReq={PLACEHOLDER}
                updateResIndex="placeholder"
                updatable={false}
                deletable={false}
                filterColumns={{
                  partnerBranch: ['name'],
                }}
              />
            </Col>
          )
        )}
      </Row>
    </>
  );
};

export default OnOff;
