/* eslint-disable import/prefer-default-export */
import { gql } from 'apollo-boost';

export const SEARCH_USERS = gql`
  query searchUsers($condition: Condition) {
    users(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedUserResponse {
        data {
          id
          username
          role
        }
      }
    }
  }
`;
