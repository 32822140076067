import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SEARCH_PRODUCTION_COMPANY from './gql';

const { Option } = Select;

const ProductionCompanySelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  // hooks
  const [selectedOption, setSelectedOption] = useState();
  const [condition, setCondition] = useState({ limit: -1 });
  const { loading, error, data } = useQuery(SEARCH_PRODUCTION_COMPANY, {
    variables: { condition },
  });

  const handleSearch = useCallback((input) => {
    if (input) {
      setCondition({
        limit: -1,
        where: {
          name: { _iLike: `%${input}%` },
        },
      });
    }
  }, []);

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <Select
      defaultValue={initialrecord?.productionCompanyName}
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.selectProductionCompany')}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Option value={null} key={null}>
        {t('buttons.cancelOption')}
      </Option>
      {data?.productionCompanies?.data &&
        data.productionCompanies.data.map((company) => (
          <Option value={company.id} key={company.id}>
            {company.name}
          </Option>
        ))}
    </Select>
  );
};

export default ProductionCompanySelector;
