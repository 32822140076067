import { gql } from 'apollo-boost';

export const CREATE_RELEASED_PLAYLIST = gql`
  mutation createReleasedPlaylist($data: ReleasedPlaylistInput!) {
    createReleasedPlaylist(data: $data) {
      __typename
      ... on ReleasedPlaylistInvalidInputError {
        start
        end
        playlistId
        partnerId
        partnerBranchIds
        timelineConflict
      }
      ... on ReleasedPlaylistCreateSuccess {
        releasedPlaylist {
          id
          start
          end
          days
          playlist {
            id
            name
          }
          partner {
            id
            name
            createdAt
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
      }
    }
  }
`;

export const RELEASED_PLAYLISTS = gql`
  query releasedPlaylists($condition: Condition) {
    releasedPlaylists(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedReleasedPlaylistResponse {
        data {
          id
          start
          end
          days
          playlist {
            id
            name
          }
          partner {
            id
            name
            createdAt
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
        totalCount
      }
    }
  }
`;
export const UPDATE_RELEASED_PLAYLIST = gql`
  mutation updateReleasedPlaylist(
    $where: JSON!
    $newValues: ReleasedPlaylistUpdateInput!
  ) {
    updateReleasedPlaylist(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on ReleasedPlaylistInvalidInputError {
        start
        end
        playlistId
        partnerId
        partnerBranchIds
      }

      ... on ReleasedPlaylistUpdateSuccess {
        affectedRows
        newValues {
          id
          start
          end
          days
          playlist {
            id
            name
          }
          partner {
            id
            name
            createdAt
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
      }
    }
  }
`;

export const DELETE_RELEASED_PLAYLIST = gql`
  mutation deleteReleasedPlaylist($where: JSON!) {
    deleteReleasedPlaylist(where: $where) {
      __typename

      ... on DeleteError {
        message
      }

      ... on ReleasedPlaylistDeleteSuccess {
        affectedRows
        deletedRows {
          id
          start
          end
          days
          playlist {
            id
            name
          }
          partner {
            id
            name
            createdAt
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
      }
    }
  }
`;
