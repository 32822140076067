import { useQuery } from '@apollo/react-hooks';
import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import ServerWrong from '../../../components/Result/ServerWrong';
import { MONTHLY_TOTAL_STATISTIC } from '../gql';
import LineChart from '../../../components/Charts/LineChart';
import { monthsMappedByIndex } from '../../../constants';

const ServiceLayerOfCharts = ({
  type,
  tooltipLabel,
  backgroundColor,
  title,
}) => {
  const [labels, setLabels] = useState();
  const [chartData, setChartData] = useState();

  const { loading, error, data: statisticData } = useQuery(
    MONTHLY_TOTAL_STATISTIC,
    {
      variables: { type },
      skip: !type,
    }
  );

  useEffect(() => {
    const statistics = statisticData?.monthlyTopByTotalOfType?.statistic;

    const tempLabels = [];
    const tempChartData = [];

    if (statistics) {
      statistics.forEach((datum) => {
        const month = monthsMappedByIndex[datum.month];
        tempLabels.push(month);
        tempChartData.push(datum.value);
      });

      setLabels(tempLabels);
      setChartData(tempChartData);
    }
  }, [statisticData, setLabels, setChartData]);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading}>
      <LineChart
        tooltipLabel={tooltipLabel}
        backgroundColor={backgroundColor}
        title={title}
        labels={labels}
        chartData={chartData}
      />
    </Spin>
  );
};

export default ServiceLayerOfCharts;
