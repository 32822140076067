/* eslint-disable no-undef */
/* eslint-disable import/no-extraneous-dependencies */
import { ApolloClient } from 'apollo-client';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { createUploadLink } from 'apollo-upload-client';
import { ApolloLink } from 'apollo-link';
import introspectionQueryResultData from './fragmentTypes.json';
import { GQL_URL } from './constants';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
});

const uploadLink = createUploadLink({
  uri: GQL_URL,
});

const middlewareTokenControlLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      authorization: localStorage.getItem('token') || '',
    },
  });
  return forward(operation);
});

const authControlLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const resolverName = Object.keys(response?.data)[0];
    const responseTypename = response?.data?.[resolverName]?.__typename;

    if (responseTypename === 'UnAuthorizedError') {
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('token');
      window.location.href = '/';
    }

    return response;
  });
});

// terminating link such as uploadLink must be last on the array
const link = ApolloLink.from([
  middlewareTokenControlLink,
  authControlLink,
  uploadLink,
]);

const client = new ApolloClient({
  link,
  cache,
});

export default client;
