import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SEARCH_SONG_CATEGORIES from './gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const { Option } = Select;

const SongCategorySelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  // hooks
  const { partnerId } = userInfoGetter();
  const [selectedOption, setSelectedOption] = useState();
  const [condition, setCondition] = useState({
    limit: -1,
    where: { partnerId },
  });
  const { loading, error, data } = useQuery(SEARCH_SONG_CATEGORIES, {
    variables: {
      condition,
    },
  });

  const handleSearch = useCallback(
    (input) => {
      if (input) {
        setCondition({
          limit: -1,
          where: {
            partnerId,
            _or: {
              name: {
                _iLike: `%${input}%`,
              },
            },
          },
        });
      }
    },
    [setCondition, partnerId]
  );

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange, setSelectedOption]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <Select
      defaultValue={initialrecord?.songCategories}
      mode="multiple"
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.selectSongCategory')}
      style={{
        width: '100%',
      }}
      defaultActiveFirstOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      {data?.songCategories?.data &&
        data.songCategories.data.map((category) => (
          <Option value={category.id} key={category.id}>
            {category.name}
          </Option>
        ))}
    </Select>
  );
};

export default SongCategorySelector;
