import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SEARCH_SONG_POOLS from './gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const { Option } = Select;

const SongPoolSelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  const { partnerId } = userInfoGetter();
  // hooks
  const [selectedOption, setSelectedOption] = useState();
  const [condition, setCondition] = useState({
    limit: -1,
    where: { partnerId },
  });
  const { loading, error, data } = useQuery(SEARCH_SONG_POOLS, {
    variables: { condition },
  });

  const handleSearch = useCallback(
    (input) => {
      if (input) {
        setCondition({
          limit: -1,
          where: {
            partnerId,
            _or: {
              name: { _iLike: `%${input}%` },
            },
          },
        });
      }
    },
    [partnerId]
  );

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <Select
      defaultValue={initialrecord?.songPoolName}
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.selectSongPool')}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
    >
      {data?.songPools?.data &&
        data.songPools.data.map((pool) => (
          <Option value={pool.id} key={pool.id}>
            {pool.name}
          </Option>
        ))}
    </Select>
  );
};

export default SongPoolSelector;
