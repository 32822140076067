import { Input } from 'antd';
import {
  UserSelector,
  RoleSelector,
  PartnerSelector,
  PeriodicAdvertisementSelector,
  SetSelector,
  GenderSelector,
  PopularitySelector,
  CountrySelector,
  ArtistSelector,
  ProductionCompanySelector,
  SongPoolSelector,
  SongCategorySelector,
  WriterSelector,
  MelodistSelector,
  AlbumSelector,
  PartnerBranchSelector,
  AdvertisementSetSelector,
  DateSelector,
  IsCutSongSelector,
  AdvertisementSelector,
  TimeSelector,
  DateWithTimeSelector,
  SoundTypeSelector,
  InputNumberSelector,
  PeriodSelector,
  PartnerSectorSelector,
  PhoneSelector,
  PlaylistSelector,
  PartnerAndBranchWithPlaylistSelector,
  DaysOfWeekSelector,
  AdvertisementTypeAndSetSelector,
  SongPoolWithPartnersSelector,
  PartnerAndBranchSelector,
  PartnerAndBranchMultiSelector,
} from '../components/Selectors';

import SongFileUploadInput from '../components/CustomInputs/SongFileUploadInput';
import AlbumFileInput from '../components/CustomInputs/AlbumFileInput';
import JinglePoolSelector from '../components/Selectors/JinglePoolSelector';
import JingleFileUploadInput from '../components/JingleFileUploadInput';
import PartnerMultiSelector from '../components/Selectors/PartnerMultiSelector';

const inputs = {
  default: Input,
  userSelector: UserSelector,
  partnerSelector: PartnerSelector,
  role: RoleSelector,
  periodicAdvertisementSelector: PeriodicAdvertisementSelector,
  genderSelector: GenderSelector,
  setSelector: SetSelector,
  popularitySelector: PopularitySelector,
  countrySelector: CountrySelector,
  artistSelector: ArtistSelector,
  productionCompanySelector: ProductionCompanySelector,
  songPoolSelector: SongPoolSelector,
  songCategorySelector: SongCategorySelector,
  songFileUploadInput: SongFileUploadInput,
  writerSelector: WriterSelector,
  melodistSelector: MelodistSelector,
  albumSelector: AlbumSelector,
  albumFileInput: AlbumFileInput,
  partnerBranchSelector: PartnerBranchSelector,
  advertisementSetSelector: AdvertisementSetSelector,
  dateSelector: DateSelector,
  isCutSongSelector: IsCutSongSelector,
  advertisementSelector: AdvertisementSelector,
  timeSelector: TimeSelector,
  dateWithTimeSelector: DateWithTimeSelector,
  soundTypeSelector: SoundTypeSelector,
  inputNumberSelector: InputNumberSelector,
  periodSelector: PeriodSelector,
  partnerSectorSelector: PartnerSectorSelector,
  phoneSelector: PhoneSelector,
  playlistSelector: PlaylistSelector,
  partnerAndBranchWithPlaylistSelector: PartnerAndBranchWithPlaylistSelector,
  daysOfWeekSelector: DaysOfWeekSelector,
  advertisementTypeAndSetSelector: AdvertisementTypeAndSetSelector,
  songPoolWithPartnersSelector: SongPoolWithPartnersSelector,
  jinglePoolSelector: JinglePoolSelector,
  jingleFileUploadInput: JingleFileUploadInput,
  partnerMultiSelector: PartnerMultiSelector,
  partnerAndBranchSelector: PartnerAndBranchSelector,
  partnerAndBranchMultiSelector: PartnerAndBranchMultiSelector,
  partnerMultipleSelector: PartnerMultiSelector,
};

const inputCreator = (inputType) => {
  return inputType ? inputs[inputType] : inputs.default;
};

export default inputCreator;
