import React from 'react';
import { Tabs } from 'antd';
import { PlusCircleOutlined, PlusSquareOutlined } from '@ant-design/icons';
import SectorCategory from '../SectorCategory';
import Sector from '../Sector';
import { useTranslation } from 'react-i18next';

const { TabPane } = Tabs;
export default function PartnerSector() {
  const { t } = useTranslation();

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <PlusCircleOutlined />
              {t('titles.partnerSector')}
            </span>
          }
          key="1"
        >
          <Sector />
        </TabPane>
        <TabPane
          tab={
            <span>
              <PlusSquareOutlined />
              {t('titles.sectorCategory')}
            </span>
          }
          key="2"
        >
          <SectorCategory />
        </TabPane>
      </Tabs>
    </>
  );
}
