/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import { SONGS, UPDATE_SONG, DELETE_SONG } from './gql';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

import { BACKEND_URL } from '../../constants';

const columnsConfig = (t) => [
  {
    title: t('titles.name'),
    dataIndex: 'name',
    key: 'name',
    editable: true,

    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    title: t('titles.country'),
    dataIndex: 'country',
    key: 'country',

    editable: true,
    inputType: 'countrySelector',
  },
  {
    title: t('titles.song'),
    dataIndex: 'address',
    key: 'address',
    editable: false,
    render: (fileId, record) => {
      return (
        <audio
          controls
          controlsList="nodownload"
          style={{ width: '185px' }}
          src={BACKEND_URL + record.address}
        />
      );
    },
  },
  {
    title: t('titles.productionCompanyName'),
    dataIndex: 'productionCompanyId',
    key: 'productionCompanyId',
    editable: true,

    inputType: 'productionCompanySelector',
    render: (_, record) => record.productionCompanyName,
  },
  {
    title: t('titles.Artist'),
    dataIndex: 'artistId',
    key: 'artistId',
    editable: true,

    inputType: 'artistSelector',
    render: (_, record) => {
      const firstName = record.artistFirstName;
      const lastName = record.artistLastName;

      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
    },
  },
  {
    title: t('titles.album'),
    dataIndex: 'albumId',
    key: 'albumId',
    editable: true,
    inputType: 'albumSelector',
    render: (_, record) => record.albumName,
  },
  {
    title: t('titles.songWriter'),
    dataIndex: 'writerId',
    key: 'writerId',
    editable: true,
    inputType: 'writerSelector',
    render: (_, record) => {
      const firstName = record.writerLastName;
      const lastName = record.writerFirstName;

      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
    },
  },
  {
    title: t('titles.melodists'),
    dataIndex: 'melodistId',
    key: 'melodistId',
    editable: true,
    inputType: 'melodistSelector',
    render: (_, record) => {
      const firstName = record.melodistFirstName;
      const lastName = record.melodistLastName;

      if (firstName && lastName) {
        return `${firstName} ${lastName}`;
      }
    },
  },
  {
    title: t('titles.popularity'),
    dataIndex: 'popularity',
    key: 'popularity',
    editable: true,
    inputType: 'popularitySelector',
  },
  {
    title: t('titles.songPool'),
    dataIndex: 'songPoolId',
    key: 'songPoolId',
    editable: true,
    inputType: 'songPoolSelector',
    render: (_, record) => record.songPoolName,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    title: t('titles.songCategory'),
    dataIndex: 'songCategories',
    key: 'songCategories',
    editable: true,
    inputType: 'songCategorySelector',
    render: (_, record) => record?.songCategoryNames?.toString(),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',

    editable: false,
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
  },
];

const Songs = () => {
  // hooks
  const { t } = useTranslation();
  const token = localStorage.getItem('token');

  const afterGetFunc = useCallback((response) => {
    const newData = response?.songs?.data?.map((song) => ({
      id: song.id,
      key: song.key,
      createdAt: humanizeTimestamp(song.createdAt),
      updatedAt: humanizeTimestamp(song.updatedAt),
      country: song.country ? song.country : undefined,
      name: song.name,
      address: song.file?.address,
      albumId: song.album?.id,
      albumName: song.album?.name,
      artistId: song.artist?.id,
      artistFirstName: song.artist?.firstName,
      artistLastName: song.artist?.lastName,
      melodistId: song.melodist?.id,
      melodistFirstName: song.melodist?.firstName,
      melodistLastName: song.melodist?.lastName,
      productionCompanyId: song.productionCompany?.id,
      productionCompanyName: song.productionCompany?.name,
      writerId: song.writer?.id,
      writerFirstName: song.writer?.firstName,
      writerLastName: song.writer?.lastName,
      songPoolId: song.songPool?.id,
      songPoolName: song.songPool?.name,
      songCategories: song.songCategories?.data?.map((category) => category.id),
      songCategoryNames: song.songCategories?.data?.map(
        (category) => category.name
      ),
      popularity: song.popularity ? song.popularity : undefined,
    }));
    return { ...response, songs: { ...response?.songs, data: newData } };
  }, []);

  const columns = columnsConfig(t, token);
  return (
    <Row>
      <Divider orientation="center">{t('buttons.allSongs')}</Divider>
      <Col span={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={SONGS}
          getResIndex="songs"
          updateReq={UPDATE_SONG}
          updateResIndex="updateSong"
          deleteReq={DELETE_SONG}
          deleteResIndex="deleteSong"
          willBeUpdatedQuery={SONGS}
          willBeUpdatedQueryName="songs"
          afterGetFunc={afterGetFunc}
          filterColumns={{
            album: ['name'],
            writer: ['firstName', 'lastName'],
            melodist: ['firstName', 'lastName'],
            productionCompany: ['name'],
            legalTracking: ['name'],
            artist: ['firstName', 'lastName'],
            songCategory: ['name'],
            songPool: ['name'],
            song: ['name', 'country', 'popularity'],
          }}
        />
      </Col>
    </Row>
  );
};

export default Songs;
