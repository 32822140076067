import { gql } from 'apollo-boost';

export const CREATE_MELODIST = gql`
  mutation createMelodist($data: MelodistInput!) {
    createMelodist(data: $data) {
      __typename
      ... on MelodistCreateSuccess {
        melodist {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
      ... on MelodistInvalidInputError {
        firstName
        lastName
        gender
      }
    }
  }
`;

export const MELODISTS = gql`
  query melodists($condition: Condition) {
    melodists(condition: $condition) {
      __typename
      ... on PaginatedMelodistResponse {
        data {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
        totalCount
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_MELODIST = gql`
  mutation updateMelodist($where: JSON!, $newValues: MelodistUpdateInput!) {
    updateMelodist(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on MelodistInvalidInputError {
        firstName
        lastName
        gender
      }
      ... on MelodistUpdateSuccess {
        affectedRows
        newValues {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_MELODIST = gql`
  mutation deleteMelodist($where: JSON!) {
    deleteMelodist(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on MelodistDeleteSuccess {
        affectedRows
        deletedRows {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
    }
  }
`;
