import React, { useState, useCallback, useEffect } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { daysOfWeek, weekdays } from '../../constants';

const { Option } = Select;

const RoleSelector = ({ onChange, initialrecord }) => {
  const [selectedOption, setSelectedOption] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (initialrecord?.days) {
      setSelectedOption(initialrecord?.days);
    }
  }, [initialrecord]);

  const handleChange = useCallback(
    (values) => {
      // select All
      if (
        values.includes('all') &&
        selectedOption.length !== daysOfWeek.length
      ) {
        setSelectedOption(daysOfWeek);
        onChange(daysOfWeek);
      }
      // deselect all
      else if (
        values.includes('all') &&
        selectedOption.length === daysOfWeek.length &&
        !values.includes('weekend') &&
        !values.includes('weekdays')
      ) {
        setSelectedOption([]);
        onChange([]);
      }
      // select only weekend
      else if (
        values.includes('weekend') &&
        !(
          selectedOption.includes('SATURDAY') &&
          selectedOption.includes('SUNDAY')
        )
      ) {
        setSelectedOption(['SATURDAY', 'SUNDAY']);
        onChange(['SATURDAY', 'SUNDAY']);
      }
      // deselect only weekend
      else if (
        values.includes('weekend') &&
        selectedOption.includes('SATURDAY') &&
        selectedOption.includes('SUNDAY')
      ) {
        const optionsWithoutWeekend = selectedOption.filter(
          (option) => option !== 'SATURDAY' && option !== 'SUNDAY'
        );
        setSelectedOption(optionsWithoutWeekend);
        onChange(optionsWithoutWeekend);
      }
      // select only weekdays
      else if (
        values.includes('weekdays') &&
        !(
          selectedOption.includes('MONDAY') &&
          selectedOption.includes('TUESDAY') &&
          selectedOption.includes('WEDNESDAY') &&
          selectedOption.includes('THURSDAY') &&
          selectedOption.includes('FRIDAY')
        )
      ) {
        setSelectedOption(weekdays);
        onChange(weekdays);
      }
      // deselect only weeekdays
      else if (values.includes('weekdays')) {
        const optionsWithoutWeekdays = selectedOption.filter(
          (option) => !weekdays.includes(option)
        );
        setSelectedOption(optionsWithoutWeekdays);
        onChange(optionsWithoutWeekdays);
      }
      // select/deselect only one day
      else {
        setSelectedOption(values);
        onChange(values);
      }
    },
    [onChange, setSelectedOption, selectedOption]
  );

  return (
    <Select
      defaultValue={initialrecord?.days}
      placeholder={t('placeholders.days')}
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
      mode="multiple"
    >
      <Option key="all" value="all">
        {t('labels.allDays')}
      </Option>
      <Option key="weekend" value="weekend">
        {t('labels.weekend')}
      </Option>
      <Option key="weekdays" value="weekdays">
        {t('labels.weekdays')}
      </Option>
      {daysOfWeek.map((day) => (
        <Option key={day} value={day}>
          {t(`labels.${day}`)}
        </Option>
      ))}
    </Select>
  );
};

export default RoleSelector;
