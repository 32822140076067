import React, { useCallback } from 'react';
import { DatePicker } from 'antd';
import endOfDay from 'date-fns/endOfDay';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const DateWithTimeSelector = ({
  onChange,
  initialrecord,
  propsForSelector,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (dateWithTime) => {
      const startedFromMinute = moment(dateWithTime).startOf('minute').utc();
      const timestamp = new Date(startedFromMinute).getTime();

      onChange(timestamp);
    },
    [onChange]
  );

  const disabledDate = (current) => {
    // Can not select days before today
    return (
      moment().add(-1, 'days') >= current && current < endOfDay(new Date())
    );
  };

  return (
    <DatePicker
      onChange={handleChange}
      format="DD/MM/YYYY HH:mm"
      placeholder={t('placeholders.selectDate')}
      disabledDate={disabledDate}
      showTime
      defaultValue={
        initialrecord?.[propsForSelector?.fieldName]
          ? moment(initialrecord?.[propsForSelector?.fieldName]).utc()
          : null
      }
    />
  );
};

export default DateWithTimeSelector;
