import { gql } from 'apollo-boost';

export const CREATE_PLAYLIST = gql`
  mutation createPlaylist($data: PlaylistInput!) {
    createPlaylist(data: $data) {
      __typename
      ... on PlaylistInvalidInputError {
        name
        songPoolId
        partnerIds
        createdBy
      }
      ... on PlaylistCreateSuccess {
        playlist {
          id
          name
          totalDuration
          songPool {
            id
            name
          }
          partners {
            id
            name
          }
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const PLAYLISTS = gql`
  query playlists($condition: Condition) {
    playlists(condition: $condition) {
      __typename

      ... on PaginatedPlaylistResponse {
        data {
          id
          name
          totalDuration
          songPool {
            id
            name
          }
          partners {
            id
            name
          }
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
        }
        totalCount
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;
export const UPDATE_PLAYLIST = gql`
  mutation updatePlaylist($where: JSON!, $newValues: PlaylistUpdateInput!) {
    updatePlaylist(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on PlaylistInvalidInputError {
        name
        songPoolId
      }
      ... on PlaylistUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          totalDuration
          songPool {
            id
            name
          }
          partners {
            id
            name
          }
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_PLAYLIST = gql`
  mutation deletePlaylist($where: JSON!) {
    deletePlaylist(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on PlaylistDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          totalDuration
          songPool {
            id
            name
          }
          partners {
            id
            name
          }
          createdBy {
            id
            firstName
            lastName
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
