const dictionary = {
  localizations: {
    buttons: {
      uploadFile: 'Upload File',
      approve: 'Approve',
      adRequest: 'Ad Request',
      createAdRequest: 'Create Ad Request',
      login: 'Sign In',
      rememberMe: 'Remember Me',
      save: 'Save',
      clear: 'Clear',
      edit: 'Edit',
      delete: 'Delete',
      cancel: 'Cancel',
      yes: 'Yes',
      no: 'No',
      update: 'Update',
      send: 'Send',
      createNewRelation: 'Create New Relation',
      makePlan: 'Plan',
      selectAll: 'Select All',
      true: 'True',
      false: 'False',
      makePeriodicPlan: 'Periodic Plan',
      makeSpecialPlan: 'Special Plan',
      filter: 'Filter',
      detail: 'Detail',
      hourBasedRelease: 'Release Hour Based',
      minuteBasedRelease: 'Release Minute Based',
      songBasedRelease: 'Release Song Based',
      logout: 'Logout',
      hourBased: 'Hour Based',
      minuteBased: 'Minute Based',
      songBased: 'Song Based',
      home: 'Home',
      dashboard: 'Dashboard',
      dashboardPartner: 'What Happened? What will happen?',
      userManagement: 'User Management',
      createUser: 'Create User',
      users: 'Users',
      firms: 'Firms',
      sectorOfFirm: 'Sector Of Firm',
      branches: 'Branches',
      statistic: 'Statistic',
      onOf: 'Online/Offline',
      songs: 'Songs',
      fieldsForSong: 'Fields For Song',
      songCategory: 'Song Category',
      artistName: 'Artist Name',
      productionCompany: 'Production Company',
      legalTracking: 'Legal Tracking',
      melodist: 'Melodist',
      writer: 'Writer',
      album: 'Album',
      songPool: 'Song Pool',
      songPoolPartnerRelation: 'Pool-Partner Relation',
      addNewSong: 'Add New Song',
      allSongs: 'All Songs',
      addSongAndViewPlaylist: 'View Song to Playlist And Edit',
      playlist: 'Playlist',
      createPlaylist: 'Create Playlist',
      advertisement: 'Advertisement',
      createAdvertisementSet: 'Create Advertisement Set',
      advertisementPool: 'Advertisement Pool',
      standardAdvertisement: 'Standard Advertisement',
      interAdvertisement: 'Inter Advertisement',
      specialAdvertisement: 'Special Advertisement',
      soundTypes: 'Sound Types',
      createSoundType: 'Create Sound Type',
      onOff: 'Online/Offline',
      addPlaylistSong: 'Add Playlist Song',
      add: 'Add',
      addChosenSongsToPlaylist: 'Add Chosen Songs To Playlist',
      createPlaylistPublish: 'Playlist Released',
      confirm: 'Confirm',
      help: 'Help',
      iUnderstand: 'I Understand !',
      release: 'Release',
      unRelease: 'UnRelease',
      timeline: ' Timeline',
      sounds: ' Sounds',
      advertisements: ' Advertisements',
      periodicAdvertisements: 'Periodic Advertisements',
      specialAdvertisements: 'Special Advertisements',
      cancelOption: 'Cancel Option',
      seeBranches: 'See Branches',
      addToSongPlaylist: 'Add To Song Playlist',
      createPlaylistName: 'Create Playlist Name',
      createPlaylists: 'Create Your Playlist',
      createAdsSet: 'Advertisement Set',
      advertisementPlanning: 'Plan Advertisement ',
      standardAdsPublish: 'Publish Standard Ad',
      periodicAdvertisementsRelease:'Publish Periodic Ad',
      interAdsPublish: 'Publish Inter Ad',
      specialAdsPublish: 'Publish Special Ad',
      playlists: 'Playlist',
      publishSound: 'Publish Jingle',
      poolSound: 'Jingle, Teaser, Announcement Pool',
      addJingle: 'Add New Jingle',
      _button: 'button',
    },
    columns: {
      createdAt: 'Creation Date',
      updatedAt: 'Updated On',
      woman: 'Female',
      man: 'Male',
      other: 'Other',
      branch: 'Branch',
      partner: 'Partner',
      guest: 'Guest',
      manager: 'Manager',
      admin: 'Admin',
      inter: 'Inter',
      standard: 'Standard',
      special: 'Special',
      advertisementName: 'Advertisement Name',
      time: 'Time',
      status: 'Status',
      isReleased: 'Is Released',
      planned: 'Planned',
      unPlanned: 'Unplanned',
      released: 'Defined',
      notReleased: 'Undefined',
      branches: 'Branches',
      totalTime: 'Total Time',
      type: 'Type',
      name: 'Name',
      duration: 'Duration',
      soundTypes: 'Sound Types',
      jingle: 'Jingle',
      jinglePools: 'Jingle Pools',
      jinglePublish: 'Jingle Publish',

      announce: 'Announce',
      teaser: 'Teaser',
      displayTime: 'Broadcast Time',
      true: 'True',
      false: 'False',
      hour: 'Hour',
      song: 'Song',
      minute: 'Minutes',
      soundTypesName: 'Sound Type Name',
      playlist: 'Playlist',
      selectedDays: 'Selected Days',
      startHour: 'Start Hour',
      endHour: 'End Hour',
      date: 'Date',
      startDate: 'Start Date',
      endDate: 'End Date',
      onlineCount: 'Online Count',
      offlineCount: 'Offline Count',
      partnerBranch: 'Partner Branch',
      lastOnline: 'Last Onlie',
      lastOffline: 'Last Offline',
      version: 'Version',
      createdBy: 'Created By',
      partners: 'Partners',
      jingleName: 'Jingle Name',
      jinglePoolName: 'Jingle Pool Name',
      _columns: 'columns',
    },
    labels: {
      male: 'Male',
      female: 'Female',
      forgotMyPassword: 'Forgot My Password',
      downloadApp: 'Download App',
      forgotPasswordMessage:
        'If you have forgotten your password, you can renew it here.',
      partnerBranch: 'Partner Branch',
      advertisementType: 'Advertisement Type',
      isCutSong: 'Is Cut Song',
      advertisementSet: 'Advertisement Set',
      startDate: 'Start Date',
      startDateWithTime: 'Start Date - Time',
      endDate: 'End Date',
      endDateWithTime: 'End Date - Time',
      time: 'Time',
      true: 'True',
      false: 'False',
      advertisementName: 'Advertisement Name',
      partner: 'Partner',
      start: 'Start',
      end: 'End',
      duration: 'Duration',
      dailyRepeat: 'Daily Repeat',
      totalRepeat: 'Total Repeat',
      dailyTotalDuration: 'Daily Total Duration',
      totalDuration: 'Total Duration',
      broadcastTimes: 'Broadcast Times',
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
      name: 'Name',
      soundType: 'Sound Type',
      advertisemet: 'Advertisement',
      repeatNumber: 'Repeat Number',
      period: 'Period',
      onHowManyMinutes: 'How many minutes on',
      onHowManyHours: 'How many hours on',
      onHowManySongs: 'How many songs on',
      album: 'Album',
      artist: 'Artist',
      melodist: 'Melodist',
      writer: 'Song Writer',
      productionCompany: 'Production Company',
      playlistKind: 'Playlist TKind',
      popularity: 'Song Quality',
      soundName: 'Sound Name',
      repeat: 'Repeat',
      periodParameter: 'Period Parameter',
      selectPlaylistSongs: 'Select a playlist to show its songs:',
      newSelectSongWhichLsit:
        'Which playlist do you want to add songs which has just selected?',
      jan: 'January',
      feb: 'February',
      march: 'March',
      apr: 'April',
      may: 'May',
      june: 'June',
      jul: 'July',
      aug: 'August',
      sep: 'September',
      oct: 'October',
      nov: 'November',
      dec: 'December',
      startTime: 'Start Time',
      endTime: 'End Time',
      allBranchesSelected: 'All Branches Selected',
      signboard: 'Signboard Name',
      jingle: 'Jingle',
      announce: 'Announce',
      teaser: 'Teaser',
      inter: 'Inter',
      standard: 'Standard',
      special: 'Special',
      songs: 'Songs',
      advertisements: 'Advertisements',
      soundTypes: 'Sound Types',
      totalPublicationsCount: 'Total Publications',
      MONDAY: 'Monday',
      TUESDAY: 'Tuesday',
      WEDNESDAY: 'Wednesday',
      THURSDAY: 'Thursday',
      FRIDAY: 'Friday',
      SATURDAY: 'Saturday',
      SUNDAY: 'Sunday',
      days: 'Days',
      allDays: 'All Days',
      weekend: 'Weekend',
      weekdays: 'Weekdays',
      startHour: 'Start Hour',
      endHour: 'End Hour',
      song: 'Song',
      songPool: 'Song Pool',
      wasDefined: 'was defined',
      affectedBranches: 'Affected branches',
      catagory: 'Category',
      dateTooltip: 'You can see the songs identified by choosing a date.',
      inHowManySongs: 'In How Many Songs',
      _label: 'label',
    },
    placeholders: {
      multipleLinksTypo: 'Separate multiple links with newlines.',
      password: 'Password',
      username: 'Username',
      chooserole: 'Choose Role',
      search: 'Word to search...',
      selectValue: 'Select a value please',
      selectBranch: 'Select a branch please',
      selectPartner: 'Please select a partner.',
      selectPlaylist: 'Please select a playlist',
      selectAdvertisementSet: 'Select an advertisement set please',
      selectTime: 'Select a time please',
      selectDate: 'Select date',
      days: 'Select day',
      selectJingle: 'Select a jingle pool',
      _placeholder: 'placeholder',
    },
    messages: {
      requiredUsername: 'Please enter your username!',
      requiredPassword: 'Please enter your password!',
      successMessage: 'Registrierung erfolgreich.',
      successUserCreate: 'The user was created successfully.',
      notSuccessUserCreate: 'Error creating user',
      firstname: 'Please enter name!',
      lastname: 'Please enter surname!',
      email: 'Please enter email!',
      password: 'Please enter password!',
      confirmpassword: 'Please enter confrm password!',
      notvalidemail: 'The input is not valid E-mail!',
      recordNotFound: 'No related record found!',
      somethingwrong: 'Something went wrong!',
      successUpdate: 'Update successful.',
      somethingBad: 'Something happend bad!',
      sure: 'Are you sure?',
      chooseUser: 'Please choose user',
      notFoundContent: 'There are not data related with your query',
      notAuthorized: 'Sorry, you are not authorized to access this page.',
      serverWrong: 'Sorry, the server is wrong.',
      notExist: 'Sorry, the page you visited does not exist.',
      successful: 'Successful',
      selectProductionCompany: 'Please select a product company.',
      selectArtist: 'Please select a artist',
      selectAlbum: 'Please select a album',
      selectWriter: 'Please select a song writer.',
      selectMelodist: 'Please select a melodist.',
      selectSongPool: 'Please select a song pool.',
      selectSongCategory: 'Please select a song category.',
      selectPartner: 'Please select a partner.',
      clickUpload: 'Click Upload',
      invalideFile: 'Invalid file type!',
      fileSmaller: 'File must be smaller than 100MB',
      clickUploadImage: 'Click Upload Image',
      selectPopularity: 'Please select a popularity.',
      forgotMessage:
        'Your password has been sent to your e-mail address.Please check',
      selectPlaylistKind: 'Please select playlist type!',
      primaryPhone: 'Please enter your phone number!',
      secondaryPhone: 'Please enter your mobile phone number!',
      selectPartnerSector: 'Choose company sector',
      notFountContent: 'Content not found.',
      notaddSong: 'No songs have been added to the selected song pool yet.',
      fileSize: 'File size cannot be larger than 15 sec.',
      uSelectedDate: 'You selected date',
      datesGapCanNotBeGt90Days: 'Dates gap can not be greater than 90 days!',
      clickUploadJingle: 'Click Upload Jingle',
      appHasNewVersion: 'App Has New Version',
      _messages: 'messages',
    },
    header: {
      home: 'Home',
      dashboard: 'Dashboard',
      usermanagement: 'User Management',
      createuser: 'Create User',
      partner: 'Partners',
      partnerBranch: 'Branch',
      statistic: 'Statistically',
      onlineoffline: 'Online/Offline',
      playlistmanager: 'Playlist Management',
      adsmanager: 'Ads Management',
      standartads: 'Standart Ads',
      interads: 'Intermediate Ads',
      specialads: 'Special Ads',
      kindmanager: 'Species Management',
      songPoolPartnerRelation: 'Song Pool and Partner Relation',
      songUpload: 'Song Upload',
      soundTypes: 'Sound Types',
      advertisementPool: 'Advertisement Pool',
      idendifiedSoundKinds: 'Sound Type Defined',
      playlist: 'Playlist Released',
      onlineOfflineInfo: 'Online Offline Status',
      totalPartnerBranch: 'Total Partner Branch',
      online: 'Online',
      offline: 'Offline',
      totalBranch: 'Total Partner Status',
      releasedJingle: 'Released Jingle',
      _header: 'header',
    },
    form: {
      createuser: 'Create User',
      username: 'Username',
      name: 'Name',
      surname: 'Surname',
      email: 'Email',
      password: 'Password',
      confirmpassword: 'Confirm Password',
      description: 'Description',
      role: 'Role',
      _form: 'form',
    },
    titles: {
      voiceoverPerformer: 'Voice-over Performer',
      links: 'Links',
      text: 'Text',
      title: 'Title',
      adRequest: 'Ad Request',
      username: 'Username',
      name: 'Name',
      surname: 'Surname',
      email: 'Email',
      role: 'Role',
      partnerName: 'Partner',
      partnerBranchName: 'Partner Branch',
      action: 'Action',
      users: 'Users',
      userDetail: 'User Detail',
      description: 'Description',
      newPartner: 'Create New Partner',
      newPartnerBranch: 'Create New Partner Branch',
      songCategory: 'Song Category',
      songPoolCategory: 'Song Pool Category',
      categoryName: 'Category Name',
      time: 'Time',
      totalFiles: 'Total Files',
      newCategoryAdd: 'New Category',
      newSongPoolAdd: 'New Song Pool',
      artistFirstName: 'Artist Name',
      artistLastName: 'Artist Surname',
      gender: 'Gender',
      Artist: 'Artist',
      newArtist: 'New Artist',
      productionCompanyName: 'Product Company Name',
      productionCompany: 'Product Company',
      newproductionCompany: 'New Product Company',
      advertisementName: 'Legal Percuit',
      advertisementType: 'Advertisement Type',
      advertisementItems: 'Time',
      advertisementSet: 'Advertisement Set',
      newAdvertisementSet: 'New Advertisement Set',
      legalPercuitName: 'Legal Percuit Name',
      legalPercuit: 'Legal Percuit',
      newlegalPercuit: 'New Legal Percuit',
      choose: 'Choose',
      country: 'Country',
      productionDate: 'Production Date',
      album: 'Album',
      songName: 'Song Name',
      songWriter: 'Song Writer',
      composer: 'Composer',
      subCategory: 'Sub Category',
      melodists: 'Melodist',
      newMelodist: 'New Melodist',
      albums: 'Albums',
      newAlbum: 'New Album',
      writers: 'Song Writers',
      newWriter: 'New Song Writer',
      popularity: 'Popularity',
      songPool: 'Song Pool',
      song: 'Song',
      file: 'File',
      photo: 'Photo',
      planAdvertisement: 'Advertisement Plan',
      standardAds: 'Standard Advertisement',
      interAds: 'Inter Advertisement',
      logOut: 'Log Out',
      newPlaylist: 'New Playlist Kind',
      playlist: 'Playlist Kind',
      backHome: 'Back Home',
      primaryPhone: 'Phone',
      secondaryPhone: 'GSM',
      partnerSector: 'Firma Sektör',
      sectorName: 'Sector Name',
      newSector: 'New Sector',
      sectorCategory: 'Sector Category',
      newSectorCategory: 'New Sector Category',
      songPoolName: 'Song Pool Name',
      createNewRelation: 'Create New Relationship',
      createSoundType: 'Create Sound Type',
      releaseSound: 'Broadcast Sound Type',
      addSongtoPlaylist: 'Add Song To Playlist',
      activeBranchCount: 'Active Branch Count',
      totalPartnerCount: 'Total Partner Count',
      totalBranchCount: 'Total Branch Count',
      planningPlaylistCount: 'Planning Playlist Count',
      planningSpeciesCount: 'Planning Species Count',
      planningTeaserCount: 'Planning Teaser Count',
      planningAdvertisementCount: 'Planning Advertisement Count',
      totalSongCount: 'Total Song Count',
      totalCategoryCount: 'Total Category Count',
      statisticSummary: 'Summary Data of This Month',
      advertisementStatistic: 'Advertisement Statistics',
      soundTypeStatistic: 'Statistics of Song Types',
      songStatistic: 'Song Statistic',
      mountlyAdvertisementStatistic: 'Advertising Statistics by Month',
      adsvertisementPercent: 'Percentage of Ads',
      teaserMountlyStatistic: 'Statistics of Teasers by Month',
      jingleMounlyStatistic: 'Statistics of The Jingles by Months',
      announceMontlyStatictic: 'Announcements Statistics by Months',
      month: 'Month',
      most: 'Most',
      least: 'Least',
      playTenSong: '10 Songs Playing',
      beforeMonth: 'Last Month',
      afterMonth: 'Next Month',
      mostThreeSong: 'Top 3 Songs Playing:',
      advertisementReleasedCount: 'Count of Publication of Ads:',
      songBaseReleasedCount: 'Publication Numbers of Song Types:',
      totalReleased: 'Total Released:',
      timelineTable: 'Timeline Daily Detail Table',
      verticalTimeline: 'Detail Timeline',
      userInfo: 'User Detail',
      tr: 'Turkish',
      en: 'English',
      de: 'German',
      welcome: ' Havalı Ajans Player Management Panel',
      howToReleaseSoundType: 'How to release a sound type?',
      songDetail: 'Song Detail',
      standardAdvertisement: 'Standard Advertisement',
      advertisementDetail: 'Advertisement Detail',
      newAdvertisement: 'New Advertisement',
      soundDetail: 'Sound Detail',
      specialAdvertisement: 'Special Advertisement',
      announce: 'Announce',
      jingle: 'Jingle',
      teaser: 'Teaser',
      timeline: 'Timeline',
      interAdvertisement: 'Inter Advertisement',
      publishList: 'Publish List',
      standartStartDate: 'Start',
      standartEndDate: 'End',
      advertisement: 'Advertisement',
      updatePlaylist: 'Update Playlist',
      newJingle: 'New Jingle',
      jingleCount: 'Jingle Count',
      newJinglePoolName: 'New Jingle Pool Name',
      _titles: 'titles',
    },
    warnings: {
      required: 'This field is required.',
      email: 'The value is not a valid e-mail.',
      string: 'This field must be text',
      min: 'The value is lower than minimum limit',
      max: 'The value is greater than maximum limit',
      noData: 'No data',
      noAdvertisement: 'No Advertisement',
      notYetAddedSongPoolToChosenPlaylist:
        'Songs was not added on chosen playlist yet!',
      allBranchesSelected: 'All playlists was chosen!',
      chosenPlaylistAffectAllBranches:
        'Chosen playlist will affect all branches!',
      startMustBeSmallerThanEnd: 'Start value must be smaller than end value!',
      chooseOneValue: 'You must choose one of at least two values!',
      standartAds: 'Standard Ads cannot be found in the specified date ranges.',
      periodicAds:'Periodic Ads cannot be found in the specified date ranges',
      interAds: 'No Search Ads can be found in the specified date ranges.',
      specialAds: 'Special Ads cannot be found in the specified date ranges.',
      noApp: 'Kurulum Yapılmadı',
      chooseSongPool: 'Choose a song pool please!',
      _warning: 'warning',
    },
    tooltips: {
      hourBased: 'Play every X hours',
      minuteBased: 'Play every X minutes',
      songBased: 'Play one in X songs',
    },
    errors: {
      audioTooLong: 'Audio file lenght can not be greater than 5 minutes!',
      requestFailed: 'Sorry, request failed!',
      pleaseCheckInputs: 'Please check input values',
      uniqueConstraint: 'This record has already recorded before.',
      checkUsernamePassword: 'Please check your username and password',
      unsupportedMimeType: 'Please check your file type.',
      timelineConflict: 'Timeline Conflict',
      unSuccessful: 'Unsuccessful',
      _error: 'error',
      any: {
        required: "A required value wasn't present.",

        _any: 'any',
      },
      string: {
        base: "The value didn't match the regular expression.",
        empty: 'The value is empty.',
        min: 'The value is shorter than expected.',
        max: 'The value is longer than expected.',
        email: 'The value is not a valid e-mail.',
        lowercase: "The value isn't all lowercased.",
        uppercase: "The value isn't all uppercased.",
        length: 'The string is not of the expected length.',
        _string: 'srting',
        pattern: {
          base: "The value didn't match the regular expression.",
          name: "The value didn't match the named regular expression.",
          _pattern: 'pattern',
        },
      },
      number: {
        base: 'The value is not a number or could not be cast to a number.',
        integer: 'The number is not a valid integer.',
        less: 'The number is under or equal to the limit.',
        greater: 'The number is over or equal to the limit.',
        max: 'The number is over than the limit',
        min: 'The number is under than the limit.',
        negative: 'The number was positive.',
        positive: 'The number was negative.',
        _number: 'number',
      },
      date: {
        base:
          'The value is either not a date or could not be cast to a date from a string or a number.',
        format: 'The date does not match the required format.',
        greater: 'The date is over the limit.',
        less: 'The date is under the limit.',
        max: 'The date is over or equal to the limit.',
        min: 'The date is under or equal to the limit.',
        _date: 'date',
      },
    },
    country: {
      afghanistan: 'Afghanistan',
      alandIslands: 'Åland Islands',
      albania: 'Albania',
      algeria: 'Algeria',
      americanSamoa: 'American Samoa',
      andorrA: 'AndorrA',
      angola: 'Angola',
      anguilla: 'Anguilla',
      antarctica: 'Antarctica',
      antiguaBarbuda: 'Antigua and Barbuda',
      argentina: 'Argentina',
      armenia: 'Armenia',
      aruba: 'Aruba',
      australia: 'Australia',
      austria: 'Austria',
      azerbaijan: 'Azerbaijan',
      bahamas: 'Bahamas',
      bahrain: 'Bahrain',
      bangladesh: 'Bangladesh',
      barbados: 'Barbados',
      belarus: 'Belarus',
      belgium: 'Belgium',
      belize: 'Belize',
      benin: 'Benin',
      bermuda: 'Bermuda',
      bhutan: 'Bhutan',
      bolivia: 'Bolivia',
      bosniaHerzegovina: 'Bosnia and Herzegovina',
      botswana: 'Botswana',
      bouvetIsland: 'Bouvet Island',
      brazil: 'Brazil',
      britishIndianOceanTerritory: 'British Indian Ocean Territory',
      bruneiDarussalam: 'Brunei Darussalam',
      bulgaria: 'Bulgaria',
      burkinaFaso: 'Burkina Faso',
      burundi: 'Burundi',
      cambodia: 'Cambodia',
      cameroon: 'Cameroon',
      canada: 'Canada',
      capeVerde: 'Cape Verde',
      caymanIslands: 'Cayman Islands',
      centralAfricanRepublic: 'Central African Republic',
      chad: 'Chad',
      chile: 'Chile',
      china: 'China',
      christmasIsland: 'Christmas Island',
      cocosIslands: 'Cocos (Keeling) Islands',
      colombia: 'Colombia',
      comoros: 'Comoros',
      congo: 'Congo',
      congDemocraticRepublic: 'Congo, Democratic Republic',
      cookIslands: 'Cook Islands',
      costaRica: 'Costa Rica',
      coteIvoire: 'Cote D"Ivoire',
      croatia: 'Croatia',
      cuba: 'Cuba',
      cyprus: 'Cyprus',
      czechRepublic: 'Czech Republic',
      denmark: 'Denmark',
      djibouti: 'Djibouti',
      dominica: 'Dominica',
      dominicanRepublic: 'Dominican Republic',
      ecuador: 'Ecuador',
      egypt: 'Egypt',
      elSalvador: 'El Salvador',
      equatorialGuinea: 'Equatorial Guinea',
      eritrea: 'Eritrea',
      estonia: 'Estonia',
      ethiopia: 'Ethiopia',
      falklandIslands: 'Falkland Islands (Malvinas)',
      faroeIslands: 'Faroe Islands',
      fiji: 'Fiji',
      finland: 'Finland',
      france: 'France',
      frenchGuiana: 'French Guiana',
      frenchPolynesia: 'French Polynesia',
      frenchSouthernTerritories: 'French Southern Territories',
      gabon: 'Gabon',
      gambia: 'Gambia',
      georgia: 'Georgia',
      germany: 'Germany',
      ghana: 'Ghana',
      gibraltar: 'Gibraltar',
      greece: 'Greece',
      greenland: 'Greenland',
      grenada: 'Grenada',
      guadeloupe: 'Guadeloupe',
      guam: 'Guam',
      guatemala: 'Guatemala',
      guernsey: 'Guernsey',
      guinea: 'Guinea',
      guineaBissau: 'Guinea-Bissau',
      guyana: 'Guyana',
      haiti: 'Haiti',
      heardIslandMcdonaldIslands: 'Heard Island and Mcdonald Islands',
      holySee: 'Holy See (Vatican City State)',
      honduras: 'Honduras',
      hongKong: 'Hong Kong',
      hungary: 'Hungary',
      iceland: 'Iceland',
      india: 'India',
      indonesia: 'Indonesia',
      iran: 'Iran',
      iraq: 'Iraq',
      ireland: 'Ireland',
      isleofMan: 'Isle of Man',
      israel: 'Israel',
      italy: 'Italy',
      jamaica: 'Jamaica',
      japan: 'Japan',
      jersey: 'Jersey',
      jordan: 'Jordan',
      kazakhstan: 'Kazakhstan',
      kenya: 'Kenya',
      kiribati: 'Kiribati',
      koreaNorth: 'Korea (North)',
      koreaSouth: 'Korea (South)',
      kosovo: 'Kosovo',
      kuwait: 'Kuwait',
      kyrgyzstan: 'Kyrgyzstan',
      laos: 'Laos',
      latvia: 'Latvia',
      lebanon: 'Lebanon',
      lesotho: 'Lesotho',
      liberia: 'Liberia',
      libyanArabJamahiriya: 'Libyan Arab Jamahiriya',
      liechtenstein: 'Liechtenstein',
      lithuania: 'Lithuania',
      luxembourg: 'Luxembourg',
      macao: 'Macao',
      macedonia: 'Macedonia',
      madagascar: 'Madagascar',
      malawi: 'Malawi',
      malaysia: 'Malaysia',
      maldives: 'Maldives',
      mali: 'Mali',
      malta: 'Malta',
      marshallIslands: 'Marshall Islands',
      martinique: 'Martinique',
      mauritania: 'Mauritania',
      mauritius: 'Mauritius',
      mayotte: 'Mayotte',
      mexico: 'Mexico',
      micronesia: 'Micronesia',
      moldova: 'Moldova',
      monaco: 'Monaco',
      mongolia: 'Mongolia',
      montserrat: 'Montserrat',
      morocco: 'Morocco',
      mozambique: 'Mozambique',
      myanmar: 'Myanmar',
      namibia: 'Namibia',
      nauru: 'Nauru',
      nepal: 'Nepal',
      netherlands: 'Netherlands',
      netherlandsAntilles: 'Netherlands Antilles',
      newCaledonia: 'New Caledonia',
      newZealand: 'New Zealand',
      nicaragua: 'Nicaragua',
      niger: 'Niger',
      nigeria: 'Nigeria',
      niue: 'Niue',
      norfolkIsland: 'Norfolk Island',
      northernMarianaIslands: 'Northern Mariana Islands',
      norway: 'Norway',
      oman: 'Oman',
      pakistan: 'Pakistan',
      palau: 'Palau',
      palestinian: 'Palestinian',
      panama: 'Panama',
      papua: 'Papua New Guinea',
      paraguay: 'Paraguay',
      peru: 'Peru',
      philippines: 'Philippines',
      pitcairn: 'Pitcairn',
      poland: 'Poland',
      portugal: 'Portugal',
      puertoRico: 'Puerto Rico',
      qatar: 'Qatar',
      reunion: 'Reunion',
      romania: 'Romania',
      russianFederation: 'Russian Federation',
      rwanda: 'Rwanda',
      saintHelena: 'Saint Helena',
      saintKittsNevis: 'Saint Kitts and Nevis',
      saintLucia: 'Saint Lucia',
      saintPierreMiquelon: 'Saint Pierre and Miquelon',
      SainVincentGrenadines: 'Saint Vincent and the Grenadines',
      Samoa: 'Samoa',
      sanMarino: 'San Marino',
      saoTomePrincipe: 'Sao Tome and Principe',
      saudiArabia: 'Saudi Arabia',
      senegal: 'Senegal',
      serbia: 'Serbia',
      montenegro: 'Montenegro',
      seychelles: 'Seychelles',
      sierraLeone: 'Sierra Leone',
      singapore: 'Singapore',
      slovakia: 'Slovakia',
      slovenia: 'Slovenia',
      solomonIslands: 'Solomon Islands',
      somalia: 'Somalia',
      southAfrica: 'South Africa',
      southGeorgiaSouthSandwichIslands:
        'South Georgia and the South Sandwich Islands',
      spain: 'Spain',
      sriLanka: 'Sri Lanka',
      sudan: 'Sudan',
      suriname: 'Suriname',
      svalbardJanMayen: 'Svalbard and Jan Mayen',
      swaziland: 'Swaziland',
      sweden: 'Sweden',
      switzerland: 'Switzerland',
      syrianArabRepublic: 'Syrian Arab Republic',
      taiwanProvinceChina: 'Taiwan, Province of China',
      tajikistan: 'Tajikistan',
      tanzania: 'Tanzania',
      thailand: 'Thailand',
      timorLeste: 'Timor-Leste',
      togo: 'Togo',
      tokelau: 'Tokelau',
      tonga: 'Tonga',
      trinidadandTobago: 'Trinidad and Tobago',
      tunisia: 'Tunisia',
      turkey: 'Turkey',
      turkmenistan: 'Turkmenistan',
      turksandCaicosIslands: 'Turks and Caicos Islands',
      tuvalu: 'Tuvalu',
      uganda: 'Uganda',
      ukraine: 'Ukraine',
      unitedArabEmirates: 'United Arab Emirates',
      unitedKingdom: 'United Kingdom',
      unitedStates: 'United States',
      unitedStatesMinorOutlyingIslands: 'United States Minor Outlying Islands',
      uruguay: 'Uruguay',
      uzbekistan: 'Uzbekistan',
      vanuatu: 'Vanuatu',
      venezuela: 'Venezuela',
      vienam: 'Viet Nam',
      virginIslandsBritish: 'Virgin Islands, British',
      virginIslands: 'Virgin Islands, U.S.',
      wallisFutuna: 'Wallis and Futuna',
      westernSahara: 'Western Sahara',
      yemen: 'Yemen',
      zambia: 'Zambia',
      zimbabwe: 'Zimbabwe',
    },
  },
};

export default dictionary;
