import { gql } from 'apollo-boost';

export const DASHBOARD_DATA_COUNT = gql`
  query {
    onlinePartnerBranchCount
    partnerCount
    partnerBranchCount
    plannedPlaylistCount
    plannedSpeciesCount
    plannedTeaserCount
    plannedAdvertisementCount
    songCount
    songCategoryCount
    allPartnerBranchStatus {
      __typename
      ... on TotalSessionSummary {
        onlineCount

        offlineCount
      }
    }
  }
`;
