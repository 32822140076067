import React from 'react';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlaylistMonitorAndEdit from '../PlaylistMonitorAndEdit';
import AddSongToPlaylist from '../AddSongToPlaylist';

const { TabPane } = Tabs;
export default function PartnerSector() {
  const { t } = useTranslation();

  return (
    <>
      <Tabs destroyInactiveTabPane defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <FontAwesomeIcon icon="music" size="1x" />{' '}
              {t('buttons.addPlaylistSong')}
            </span>
          }
          key="1"
        >
          <AddSongToPlaylist />
        </TabPane>
        <TabPane
          tab={
            <span>
              <FontAwesomeIcon icon="pen-square" size="1x" />{' '}
              {t('buttons.addSongAndViewPlaylist')}
            </span>
          }
          key="2"
        >
          <PlaylistMonitorAndEdit />
        </TabPane>
      </Tabs>
    </>
  );
}
