import { ADD_USER, REMOVE_USER } from '../actions/user';

const initialState = {
  data: null
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case ADD_USER:
      return { ...state, user: action.payload };
    case REMOVE_USER:
      return { ...state, user: null };
    default:
      return state;
  }
};

export default user;
