import React, { useState, useCallback, useEffect } from 'react';
import { Spin, Row, Col, Button, Modal, message } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SONGS, ADD_SONG_TO_PLAYLIST } from './gql';
import ServerWrong from '../../components/Result/ServerWrong';
import { BACKEND_URL } from '../../constants';
import TableTransferComp from './TableTransferComp';
import useToggle from '../../hooks/useToggle';
import { PlaylistSelector } from '../../components/Selectors';
import userInfoGetter from '../../utils.js/userInfoGetter';

const leftFieldsTableColumns = (t) => [
  {
    dataIndex: 'name',
    title: t('titles.songName'),
  },
  {
    dataIndex: 'file',
    title: t('titles.song'),
    render: (file) => {
      if (file?.address) {
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio
            controls
            controlsList="nodownload"
            style={{ width: '210px' }}
            src={BACKEND_URL + file?.address}
          />
        );
      }
      return 'No Data';
    },
  },
];

const rightFieldsTableColumns = (t) => [
  {
    dataIndex: 'name',
    title: t('titles.songName'),
  },
];

const TransferComp = ({ readCondition, songPoolId }) => {
  const { role, userId } = userInfoGetter();
  // hooks
  const { t } = useTranslation();
  const leftTableColumns = leftFieldsTableColumns(t);
  const rightTableColumns = rightFieldsTableColumns(t);
  // hooks
  const [visible, toggleModal] = useToggle();
  const [transferData, setTransferData] = useState();
  const [targetKeys, setTargetKeys] = useState();
  const [chosenPlaylistId, setChosenPlaylistId] = useState();

  // gql hooks
  const { loading, error, data } = useQuery(SONGS, {
    variables: { condition: readCondition },
    skip: !readCondition,
    fetchPolicy: 'network-only',
  });
  const [addSongToPlaylist] = useMutation(ADD_SONG_TO_PLAYLIST);

  useEffect(() => {
    const songs = data?.songs?.data;
    if (songs) {
      const newTransferData = songs.map((song) => ({
        key: song.id,
        name: song.name,
        file: song.file,
      }));
      setTransferData(newTransferData);
    } else if (data?.songs?.__typename === 'NotFoundResult') {
      setTransferData(undefined);
      message.warning(t('messages.notaddSong'));
    }
  }, [data, setTransferData, t]);

  // handles
  const handleOnChange = useCallback(
    (nextTargetKeys) => {
      setTargetKeys(nextTargetKeys);
    },
    [setTargetKeys]
  );

  const handleSelectPlaylist = useCallback(
    (playlistId) => {
      setChosenPlaylistId(playlistId);
    },
    [setChosenPlaylistId]
  );

  const handleAddSongButton = useCallback(async () => {
    try {
      const response = await addSongToPlaylist({
        variables: {
          data: {
            playlistId: chosenPlaylistId,
            songIds: targetKeys,
          },
        },
      });
      const { success, errors } = response?.data?.addSongToPlaylist;
      if (success) {
        toggleModal();
        message.success(t('messages.successful'));
      } else {
        toggleModal();
        console.error('gql error: ', errors);
        message.error(t('errors.requestFailed'));
      }
    } catch (addSongButtonError) {
      toggleModal();
      console.error('addSongButtonError(catch): ', addSongButtonError);
      message.error(t('errors.requestFailed'));
    }
  }, [targetKeys, addSongToPlaylist, chosenPlaylistId, t, toggleModal]);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <>
      {transferData && (
        <>
          <Row>
            <Col xs={24} sm={24} span={24} className="transfer">
              <Spin spinning={loading}>
                <TableTransferComp
                  dataSource={transferData}
                  targetKeys={targetKeys}
                  // showSearch={showSearch}
                  onChange={handleOnChange}
                  // filterOption={(inputValue, item) =>
                  //   item.title.indexOf(inputValue) !== -1 ||
                  //   item.tag.indexOf(inputValue) !== -1
                  // }
                  leftColumns={leftTableColumns}
                  rightColumns={rightTableColumns}
                />
              </Spin>
            </Col>

            <Col
              md={{ span: 24 }}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              className="leftRight"
            >
              {targetKeys && (
                <Button
                  style={{ float: 'right', marginTop: 20 }}
                  ghost
                  type="primary"
                  onClick={toggleModal}
                >
                  {t('buttons.addChosenSongsToPlaylist')}
                </Button>
              )}
            </Col>
          </Row>
          <Modal
            title={t('titles.addSongtoPlaylist')}
            visible={visible}
            onOk={toggleModal}
            onCancel={toggleModal}
            footer={null}
          >
            <Row>
              <Col xs={24} sm={24} md={24}>
                <h4>{t('labels.newSelectSongWhichLsit')}</h4>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 18 }} sm={24} xs={24}>
                <PlaylistSelector
                  customWhere={
                    role === 'PARTNER'
                      ? { songPoolId, createdBy: userId }
                      : { songPoolId }
                  }
                  onChange={handleSelectPlaylist}
                />
              </Col>
            </Row>

            <Row>
              <Col md={{ span: 6, offset: 18 }}>
                <Button
                  style={{ float: 'right' }}
                  ghost
                  type="primary"
                  onClick={handleAddSongButton}
                >
                  {t('buttons.add')}
                </Button>
              </Col>
            </Row>
          </Modal>
        </>
      )}
    </>
  );
};

export default TransferComp;
