import React, { useCallback, useEffect, useState } from 'react';
import { Form, Input, Button, Divider, Select, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { useTranslation } from 'react-i18next';
import MaskedInput from 'antd-mask-input';
import image from './img/userCreaate.jpeg';

const { Option } = Select;

const validateMessages = {
  required: 'This field is required!',
  types: {
    email: 'Not a validate email!',
    number: 'Not a validate number!',
  },
  // number: {
  //   range: "Must be between ${min} and ${max}"
  // }
};

const UserForm = ({ errorData, handleSubmit, isSubmitted }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [firstPassword, setFirstPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const handleClearForm = useCallback(() => {
    form.resetFields();
  }, [form]);

  useEffect(() => {
    handleClearForm();
  }, [handleClearForm, isSubmitted]);
  useEffect(() => {
    if (
      firstPassword !== undefined &&
      confirmPassword !== undefined &&
      firstPassword === confirmPassword
    ) {
      setIsButtonDisabled(false);
    } else setIsButtonDisabled(true);
  }, [firstPassword, confirmPassword]);
  return (
    <Form
      form={form}
      name="nest-messages"
      onFinish={handleSubmit}
      validateMessages={validateMessages}
    >
      <Row gutter={(16, 16)} className="inputValue">
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={14}
          style={{
            background: '#FAFAFA',
            boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
            transition: '0.3s',
          }}
        >
          <Divider orientation="center">{t('form.createuser')}</Divider>
          <Row gutter={16} style={{ marginTop: '20px' }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.username')}</h1>
            </Col>
            <Col xs={24} sm={24} md={15}>
              <Form.Item
                name={['username']}
                rules={[
                  {
                    required: true,
                    message: t('messages.requiredUsername'),
                  },
                ]}
                validateStatus={errorData?.username ? 'error' : 'success'}
                help={
                  errorData?.username
                    ? t(`errors.${errorData?.username}`)
                    : null
                }
              >
                <Input style={{ borderRadius: 10 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.name')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['firstName']}
                rules={[
                  {
                    required: true,
                    message: t('messages.firstname'),
                  },
                ]}
                validateStatus={errorData?.firstName ? 'error' : 'success'}
                help={
                  errorData?.firstName
                    ? t(`errors.${errorData?.firstName}`)
                    : null
                }
              >
                <Input style={{ borderRadius: 10 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.surname')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['lastName']}
                rules={[
                  {
                    required: true,
                    message: t('messages.lastname'),
                  },
                ]}
                validateStatus={errorData?.lastName ? 'error' : 'success'}
                help={
                  errorData?.lastName
                    ? t(`errors.${errorData?.lastName}`)
                    : null
                }
              >
                <Input style={{ borderRadius: 10 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.email')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['email']}
                rules={[
                  {
                    type: 'email',
                    message: t('messages.notvalidemail'),
                  },
                  {
                    required: true,
                    message: t('messages.email'),
                  },
                ]}
                validateStatus={errorData?.email ? 'error' : 'success'}
                help={errorData?.email ? t(`errors.${errorData?.email}`) : null}
              >
                <Input style={{ borderRadius: 10 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('titles.primaryPhone')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['primaryPhone']}
                rules={[
                  {
                    required: true,
                    message: t('messages.primaryPhone'),
                  },
                ]}
                validateStatus={errorData?.primaryPhone ? 'error' : 'success'}
                help={
                  errorData?.primaryPhone
                    ? t(`errors.${errorData?.primaryPhone}`)
                    : null
                }
              >
                <MaskedInput
                  mask="+90(111)1111111"
                  name="card"
                  size="14"
                  style={{ borderRadius: 10 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('titles.secondaryPhone')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['secondaryPhone']}
                rules={[
                  {
                    required: true,
                    message: t('messages.secondaryPhone'),
                  },
                ]}
                validateStatus={errorData?.secondaryPhone ? 'error' : 'success'}
                help={
                  errorData?.secondaryPhone
                    ? t(`errors.${errorData?.secondaryPhone}`)
                    : null
                }
              >
                <MaskedInput
                  mask="+90(111)1111111"
                  name="card"
                  size="20"
                  style={{ borderRadius: 10 }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.password')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['password']}
                onChange={(e) => setFirstPassword(e.target.value)}
                rules={[
                  {
                    required: true,
                    message: t('messages.password'),
                  },
                ]}
                validateStatus={errorData?.password ? 'error' : 'success'}
                help={
                  errorData?.password
                    ? t(`errors.${errorData?.password}`)
                    : null
                }
              >
                <Input.Password style={{ borderRadius: 10 }} />
              </Form.Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.confirmpassword')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                onChange={(e) => setConfirmPassword(e.target.value)}
                rules={[
                  {
                    required: true,
                    message: t('messages.password'),
                  },
                ]}
              >
                <Input.Password style={{ borderRadius: 10 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.description')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['description']}
                rules={[
                  {
                    required: false,
                  },
                ]}
                validateStatus={errorData?.description ? 'error' : 'success'}
                help={
                  errorData?.description
                    ? t(`errors.${errorData?.description}`)
                    : null
                }
              >
                <TextArea style={{ borderRadius: 10 }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 6, offset: 1 }}
            >
              {' '}
              <h1> {t('form.role')}</h1>
            </Col>
            <Col xs={24} sm={10} md={15}>
              <Form.Item
                name={['role']}
                validateStatus={errorData?.role ? 'error' : 'success'}
                help={errorData?.role ? t(`errors.${errorData?.role}`) : null}
              >
                <Select showSearch placeholder={t('placeholders.chooserole')}>
                  <Option value="MANAGER">{t('columns.manager')}</Option>
                  <Option value="PARTNER">{t('columns.partner')}</Option>
                  <Option value="BRANCH">{t('columns.branch')}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: '20px', marginLeft: '50px' }}>
            <Col md={{ span: 12, offset: 8 }}>
              <Form.Item>
                <Button
                  htmlType="submit"
                  ghost
                  type="primary"
                  style={{
                    borderRadius: 10,
                  }}
                  disabled={isButtonDisabled}
                >
                  {t('buttons.save')}
                </Button>
                <Button
                  htmlType="button"
                  ghost
                  type="danger"
                  style={{
                    margin: '0 6px',
                    borderRadius: 10,
                  }}
                  onClick={handleClearForm}
                >
                  {t('buttons.clear')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={7} lg={10} sm={12} xs={24}>
          <div className="leftImg">
            <img
              src={image}
              alt="logo"
              style={{
                marginTop: '25%',
                marginLeft: '15%',
                width: '400px',
                height: '400px',
                borderRadius: '50%',
                boxShadow: '0 0 15px 5px',
              }}
            />
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
