import React from 'react';
import { InputNumber } from 'antd';

const InputNumberSelector = ({ onChange, initialrecord, propsForSelector }) => {
  return (
    <InputNumber
      min={propsForSelector?.min}
      max={propsForSelector?.max}
      defaultValue={initialrecord?.period}
      onChange={onChange}
    />
  );
};

export default InputNumberSelector;
