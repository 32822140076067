import { gql } from 'apollo-boost';

const JINGLE_POOLS_BY_PARTNER = gql`
  query jinglePools($condition: Condition) {
    jinglePools(condition: $condition) {
      __typename
      ... on UnAuthorizedError {
        message
        statusCode
      }
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on PaginatedJinglePoolResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;

export default JINGLE_POOLS_BY_PARTNER;
