import { Upload, Button, Col } from 'antd';
import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { UploadOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const SongFileUploadInput = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  const [fileListState, setFileListState] = useState();

  const props = {
    name: 'file',
    action: '',
    multiple: false,
    onRemove: () => {
      onChange(undefined);
    },
    beforeUpload: (file) => {
      onChange(file);
      return false;
    },
    onChange: (info) => {
      setFileListState([info.file]);
    },
    fileList: fileListState,
  };

  return (
    <Col md={24} xs={24} sm={24}>
      <Upload {...props}>
        <Button>
          <UploadOutlined />
          {t('messages.clickUpload')}
        </Button>
      </Upload>
    </Col>
  );
};

export default SongFileUploadInput;
