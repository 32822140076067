/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { MONTHLY_TOTAL_STATISTIC } from '../gql';
import ServerWrong from '../../../components/Result/ServerWrong';

const InfoBoxes = ({ setTotalSoundTypesPublishCount }) => {
  const { t } = useTranslation();
  const [teaserValue, setTeaserValue] = useState();
  const [jingleValue, setJingleValue] = useState();
  const [announceValue, setAnnounceValue] = useState();
  const [loading, setLoading] = useState(false);

  // #region gql queries
  const {
    loading: teaserLoading,
    error: teaserError,
    data: teaserData,
  } = useQuery(MONTHLY_TOTAL_STATISTIC, {
    variables: { type: 'TEASER' },
  });

  const {
    loading: announceLoading,
    error: announceError,
    data: announceData,
  } = useQuery(MONTHLY_TOTAL_STATISTIC, {
    variables: { type: 'ANNOUNCE' },
  });

  const {
    loading: jingleLoading,
    error: jingleError,
    data: jingleData,
  } = useQuery(MONTHLY_TOTAL_STATISTIC, {
    variables: { type: 'JINGLE' },
  });
  // #endregion

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth() + 1;
    if (
      teaserData &&
      teaserData?.monthlyTopByTotalOfType?.__typename ===
        'MonthlyTotalStatistic'
    ) {
      const monthsTeaserStatistics =
        teaserData?.monthlyTopByTotalOfType?.statistic;
      const currentMonthTeaserData = monthsTeaserStatistics.filter(
        (datum) => datum.month === currentMonthIndex
      )[0];
      setTeaserValue(currentMonthTeaserData?.value);
    }

    if (
      jingleData &&
      jingleData?.monthlyTopByTotalOfType?.__typename ===
        'MonthlyTotalStatistic'
    ) {
      const monthsJingleStatistics =
        jingleData?.monthlyTopByTotalOfType?.statistic;
      const currentMonthJingleData = monthsJingleStatistics?.filter(
        (datum) => datum.month === currentMonthIndex
      )[0];
      setJingleValue(currentMonthJingleData?.value);
    }

    if (
      announceData &&
      announceData?.monthlyTopByTotalOfType?.__typename ===
        'MonthlyTotalStatistic'
    ) {
      const monthsAnnounceStatistics =
        announceData?.monthlyTopByTotalOfType?.statistic;
      const currentMonthAnnounceData = monthsAnnounceStatistics?.filter(
        (datum) => datum.month === currentMonthIndex
      )[0];
      setJingleValue(currentMonthAnnounceData?.value);
    }
  }, [
    teaserData,
    jingleData,
    announceData,
    setTeaserValue,
    setJingleValue,
    setAnnounceValue,
  ]);

  useEffect(() => {
    if (teaserValue || jingleValue || announceValue) {
      const safeTeaserValue = teaserValue || 0;
      const safeJingleValue = jingleValue || 0;
      const safeAnnounceValue = announceValue || 0;
      setTotalSoundTypesPublishCount(
        safeTeaserValue + safeJingleValue + safeAnnounceValue
      );
    }
  }, [setTotalSoundTypesPublishCount, teaserValue, jingleValue, announceValue]);

  useEffect(() => {
    if (teaserLoading || jingleLoading || announceLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [teaserLoading, jingleLoading, announceLoading]);

  if (teaserError || jingleError || announceError) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading}>
      <Card style={styles.soundTypesInfoBox} className="soundTypesInfoBox">
        <Row>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 6 }}>
            <FontAwesomeIcon icon="microphone" size="4x" />
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 18 }}>
            <p className="p">{t('titles.songBaseReleasedCount')}</p>
            <ul>
              <li className="p">
                {`${t('labels.teaser')}: ${
                  teaserValue || t('warnings.noData')
                }`}
              </li>
              <li className="p">
                {`${t('labels.jingle')}: ${
                  jingleValue || t('warnings.noData')
                }`}
              </li>
              <li className="p">
                {`${t('labels.announce')}: ${
                  announceValue || t('warnings.noData')
                }`}
              </li>
            </ul>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default InfoBoxes;

const styles = {
  soundTypesInfoBox: {
    height: 220,
    fontSize: 16,
    color: '#fff',
    borderRadius: 10,
    boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
    background: 'linear-gradient(150deg, #fad961, #f76b1c 100%)',
  },
};
