import React, { useState, useCallback } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  AlbumSelector,
  MelodistSelector,
  ProductionCompanySelector,
  WriterSelector,
  ArtistSelector,
  PopularitySelector,
  SongCategorySelector,
  SongPoolSelector,
} from '../../components/Selectors';
import './songPlaylist.css';
import userInfoGetter from '../../utils.js/userInfoGetter';

const FilterPlanSection = ({ handleFilterResult }) => {
  const { t } = useTranslation();
  // #region states
  const [chosenAlbumId, setChosenAlbumId] = useState();
  const [chosenArtistId, setChosenArtistId] = useState();
  const [chosenMelodistId, setChosenMelodistId] = useState();
  const [chosenProductionCompanyId, setChosenProductionCompanyId] = useState();
  const [chosenWriterId, setChosenWriterId] = useState();
  const [chosenPopularity, setChosenPopularity] = useState();
  const [chosenSongCategoryId, setChosenSongCategoryId] = useState();
  const [chosenSongPoolId, setChosenSongPoolId] = useState();
  // #endregion states finish
  const { role } = userInfoGetter();
  // #region handles
  const handleSongPoolChange = useCallback((songPoolId) => {
    setChosenSongPoolId(songPoolId);
  }, []);

  const handleAlbumChange = useCallback((albumId) => {
    setChosenAlbumId(albumId);
  }, []);

  const handleArtistChange = useCallback((artistId) => {
    setChosenArtistId(artistId);
  }, []);

  const handleMelodistChange = useCallback((melodistId) => {
    setChosenMelodistId(melodistId);
  }, []);

  const handleProductionCompanyChange = useCallback((productionCompanyId) => {
    setChosenProductionCompanyId(productionCompanyId);
  }, []);

  const handleWriterChange = useCallback((writerId) => {
    setChosenWriterId(writerId);
  }, []);

  const handlePopularityChange = useCallback((popularity) => {
    setChosenPopularity(popularity);
  }, []);

  const handleSongCategoryChange = useCallback((songCategoryId) => {
    setChosenSongCategoryId(songCategoryId);
  }, []);
  // #endregion handles finish

  const checkFiltersIfSelected = useCallback((filterResult) => {
    let nothingSelected = true;

    Object.values(filterResult).forEach((value) => {
      if (value) {
        nothingSelected = false;
      }
    });
    return nothingSelected;
  }, []);

  const handleClickFilter = useCallback(() => {
    const filterResult = {
      albumId: chosenAlbumId,
      artistId: chosenArtistId,
      melodistId: chosenMelodistId,
      productionCompanyId: chosenProductionCompanyId,
      writerId: chosenWriterId,
      popularity: chosenPopularity,
      songCategoryIds: chosenSongCategoryId,
      songPoolId: chosenSongPoolId,
    };

    const nothingSelected = checkFiltersIfSelected(filterResult);

    if (!nothingSelected) {
      handleFilterResult(filterResult);
    }
  }, [
    chosenAlbumId,
    chosenArtistId,
    chosenProductionCompanyId,
    chosenWriterId,
    chosenPopularity,
    chosenMelodistId,
    chosenSongCategoryId,
    chosenSongPoolId,
    handleFilterResult,
    checkFiltersIfSelected,
  ]);

  return (
    <Row gutter={16}>
      <Col md={24}>
        <Card style={{ borderRadius: 10 }} className="filter">
          <Row gutter={16}>
            <Col md={{ span: 24 }} xs={24}>
              <span>{t('labels.songPool')}</span>
              <SongPoolSelector onChange={handleSongPoolChange} />
            </Col>
          </Row>
          {role === 'PARTNER' ? (
            ''
          ) : (
            <Row>
              <Col md={{ span: 24 }} xs={24}>
                <span>{t('labels.album')}</span>
                <AlbumSelector onChange={handleAlbumChange} />
              </Col>
            </Row>
          )}
          {role === 'PARTNER' ? (
            ''
          ) : (
            <Row>
              <Col md={{ span: 24 }} xs={{ span: 24 }}>
                <span>{t('labels.artist')}</span>
                <ArtistSelector onChange={handleArtistChange} />
              </Col>
            </Row>
          )}
          {role === 'PARTNER' ? (
            ''
          ) : (
            <Row>
              <Col md={{ span: 24 }} xs={{ span: 24 }}>
                <span>{t('labels.melodist')}</span>
                <MelodistSelector onChange={handleMelodistChange} />
              </Col>
            </Row>
          )}{' '}
          {role === 'PARTNER' ? (
            ''
          ) : (
            <Row>
              <Col md={{ span: 24 }} xs={{ span: 24 }}>
                <span>{t('labels.productionCompany')}</span>
                <ProductionCompanySelector
                  onChange={handleProductionCompanyChange}
                />
              </Col>
            </Row>
          )}
          {role === 'PARTNER' ? (
            ''
          ) : (
            <Row>
              <Col md={{ span: 24 }} xs={{ span: 24 }}>
                <span>{t('labels.writer')}</span>
                <WriterSelector onChange={handleWriterChange} />
              </Col>
            </Row>
          )}
          <Row>
            <Col md={{ span: 24 }} xs={{ span: 24 }}>
              <span>{t('titles.songCategory')}</span>
              <SongCategorySelector onChange={handleSongCategoryChange} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 24 }} xs={{ span: 24 }}>
              <span>{t('labels.popularity')}</span>
              <PopularitySelector onChange={handlePopularityChange} />
            </Col>
          </Row>
          <Row>
            <Col md={{ span: 24 }} xs={{ span: 24 }} style={{ marginTop: 21 }}>
              <Button ghost type="primary" onClick={handleClickFilter}>
                {t('buttons.filter')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default FilterPlanSection;
