import React from 'react';
import CustomSelector from '../CustomSelector';
import SEARCH_PARTNER_BRANCHES from './gql';

const PartnerBranchSelector = (props) => {
  const { propsForSelector, initialrecord } = props;

  const createCustomWhere = (input = '') => {
    return {
      name: { _like: `%${input}%` },
      partnerId: propsForSelector?.partnerId
        ? propsForSelector.partnerId // comes from formBuilder
        : initialrecord?.partnerId, // comes from integrated Table
    };
  };

  return (
    <CustomSelector
      defaultValueIndex="partnerBranchIds"
      getReq={SEARCH_PARTNER_BRANCHES}
      customWhereCreator={createCustomWhere}
      placeholder="selectBranch"
      getResIndex="partnerBranches"
      customLimit={-1}
      mode="multiple"
      searchWithService={propsForSelector?.searchWithService}
      {...props}
    />
  );
};

export default PartnerBranchSelector;
