import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Popconfirm, Button, Spin, message } from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { PARTNER_SONG_POOLS, DELETE_PARTNER_SONG_POOL_RELATION } from './gql';
import ServerWrong from '../../components/Result/ServerWrong';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const SongPoolPartnerRelationTable = ({ partnerId }) => {
  const { t } = useTranslation();
  const { loading, error: getError, data } = useQuery(PARTNER_SONG_POOLS, {
    variables: {
      where: {
        id: partnerId,
      },
    },
    fetchPolicy: 'network-only',
  });
  const [deleteFunc] = useMutation(DELETE_PARTNER_SONG_POOL_RELATION, {
    update(cache, { data: deleteData }) {
      const responseFromMutation = deleteData.deleteSongPoolFromPartner.data;

      const queryData = cache.readQuery({
        query: PARTNER_SONG_POOLS,
        variables: { where: { id: partnerId } },
      });

      const newStateAfterDelete = responseFromMutation.songPools;
      const newStateAfterDeleteWithTypename = newStateAfterDelete.map(
        (datum) => ({ ...datum, __typename: 'SongPool' })
      );

      cache.writeQuery({
        query: PARTNER_SONG_POOLS,
        variables: { where: { id: partnerId } },
        data: {
          partner: {
            ...queryData.partner,
            songPools: newStateAfterDeleteWithTypename,
          },
        },
      });
    },
  });
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data?.partner?.songPools);
  }, [data]);

  // #region  handleDelete
  const handleDelete = useCallback(
    async (songPoolId) => {
      try {
        const response = await deleteFunc({
          variables: {
            data: {
              partnerId,
              songPoolId,
            },
          },
        });
        const { errors, success } = response?.data?.deleteSongPoolFromPartner;

        if (success) {
          message.success(t('messages.successful'));
        } else {
          console.error('1', errors);
          message.error(t('errors.requestFailed'));
        }
      } catch (error) {
        console.error(2, error);
        message.error(t('errors.requestFailed'));
      }
    },
    [deleteFunc, t, partnerId]
  );
  // #endregion

  const columns = [
    {
      title: t('titles.name'),
      dataIndex: 'name',
      key: 'name',
      editable: true,
    },
    {
      title: t('columns.createdAt'),
      dataIndex: 'createdAt',
      key: 'createdAt',
      editable: false,
      render: (value) => humanizeTimestamp(value),
    },
    {
      title: t('columns.updatedAt'),
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      editable: false,
      render: (value) => humanizeTimestamp(value),
    },
    {
      title: t('titles.action'),
      dataIndex: 'operations',
      key: 'operations',
      render: (_, record) => (
        <Popconfirm
          title={t('messages.sure')}
          okText={t('buttons.yes')}
          cancelText={t('buttons.no')}
          onConfirm={() => handleDelete(record.id)}
        >
          <Button ghost type="danger">
            {t('buttons.delete')}
          </Button>
        </Popconfirm>
      ),
    },
  ];

  if (getError) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading}>
      <Table
        columns={columns}
        dataSource={tableData}
        rowKey={(record, key) => {
          // eslint-disable-next-line no-param-reassign
          record.key = key;
          return key;
        }}
      />
    </Spin>
  );
};

export default SongPoolPartnerRelationTable;
