import { gql } from 'apollo-boost';

const CALENDAR_VIEW = gql`
  query calendarView($year: Int!, $month: Month!) {
    calendarView(year: $year, month: $month) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on CalendarView {
        calendar
      }
    }
  }
`;
export default CALENDAR_VIEW;
