import { gql } from 'apollo-boost';

export const CREATE_ALBUM = gql`
  mutation createAlbum($data: AlbumInput!, $file: Upload!) {
    createAlbum(data: $data, file: $file) {
      __typename
      ... on AlbumCreateSuccess {
        album {
          id
          name
          artist {
            id
            firstName
            lastName
          }
          file {
            id
            filename
            address
          }
        }
      }
      ... on AlbumInvalidInputError {
        name
        artistId
        file
      }
    }
  }
`;

export const ALBUMS = gql`
  query albums($condition: Condition) {
    albums(condition: $condition) {
      __typename
      ... on PaginatedAlbumResponse {
        data {
          id
          name
          artist {
            id
            firstName
            lastName
          }
          file {
            id
            filename
            address
          }
        }
        totalCount
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_ALBUM = gql`
  mutation updateAlbum(
    $where: JSON!
    $newValues: AlbumUpdateInput!
    $file: Upload
  ) {
    updateAlbum(where: $where, newValues: $newValues, file: $file) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on AlbumInvalidInputError {
        name
        artistId
        file
      }
      ... on AlbumUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          artist {
            id
            firstName
            lastName
          }
          file {
            id
            filename
            address
          }
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_ALBUM = gql`
  mutation deleteAlbum($where: JSON!) {
    deleteAlbum(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on AlbumDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
        }
      }
    }
  }
`;
