import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { msToTimeWithSecond } from '../../utils.js/adsSetHelper';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

momentDurationFormatSetup(moment);

const PeriodicAdsElement = ({ advertisement, start, end, type }) => {
  const { t } = useTranslation();
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      date={`${msToTimeWithSecond(start)} - ${msToTimeWithSecond(end)}`}
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      icon={<FontAwesomeIcon icon="ad" size="lg" />}
    >
      <h3 className="vertical-timeline-element-title">
        {advertisement?.advertisementPool?.name || t('warnings.noData')}
      </h3>
      <h4
        className="vertical-timeline-element-subtitle"
        style={{ fontWeight: 'bold' }}
      >
        {type === 'standardAdvertisement'
          ? t('titles.standardAdvertisement')
          : t('titles.interAdvertisement')}
      </h4>

      <Descriptions
        size="small"
        title={
          <h4 style={{ marginTop: 20 }}>{t('titles.advertisementDetail')}</h4>
        }
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}
      >
        <Descriptions.Item label="Süre">
          {moment
            .duration(
              (advertisement?.advertisementPool?.file?.duration / 1000).toFixed(
                2
              ),
              'seconds'
            )
            .format(
              `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
                'labels.seconds'
              )}]`
            )}
        </Descriptions.Item>

        <Descriptions.Item label="Reklam Seti">
          {advertisement?.advertisementSet?.name || t('warnings.noData')}
        </Descriptions.Item>

        <Descriptions.Item label="Başlangıç Tarihi">
          {humanizeTimestamp(advertisement?.startDate, 'dd/MM/yyyy') ||
            t('warnings.noData')}
        </Descriptions.Item>

        <Descriptions.Item label="Bitiş Tarihi">
          {humanizeTimestamp(advertisement?.endDate, 'dd/MM/yyyy') ||
            t('warnings.noData')}
        </Descriptions.Item>

        <Descriptions.Item label="Şube">
          {advertisement?.partnerBranches.map((branch) => (
            <h4 key={branch.id}>{branch.name}</h4>
          ))}
        </Descriptions.Item>
      </Descriptions>
    </VerticalTimelineElement>
  );
};

export default PeriodicAdsElement;
