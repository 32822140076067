import { gql } from 'apollo-boost';

const RELEASED_SOUNDS_OF_BRANCH = gql`
query partnerBranches($condition: Condition, $start: Date!, $end: Date!) {
  partnerBranches(condition: $condition) {
    __typename

    ... on UnAuthorizedError {
      message
      statusCode
    }

    ... on NotFoundResult {
      notFoundErrorMessage
    }

    ... on PaginatedPartnerBranchResponse {
      data {
        id
        releasedJinglePool(start: $start, end: $end) {
          id
          start
          end
          period
        
          jinglePool {
            id
            name
          }
          
          }
        
        }
      totalCount
      }

    }
  }

`;

export default RELEASED_SOUNDS_OF_BRANCH;
