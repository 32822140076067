import React, { useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const { RangePicker } = DatePicker;

const RangeSelector = () => {
  const { t } = useTranslation();

  const [dates, setDates] = useState([]);
  const disabledDate = (current) => {
    if (!dates || dates.length === 0) {
      const past = current && current.valueOf() < moment().subtract(1, 'days');
      return past;
    }

    const tooLate = dates[0] && current.diff(dates[0], 'days') > 90;
    const tooEarly = dates[1] && dates[1].diff(current, 'days') > 90;

    return tooEarly || tooLate;
  };

  return (
    <RangePicker
      placeholder={t('placeholders.selectDate')}
      disabledDate={disabledDate}
      onCalendarChange={(value) => {
        setDates(value);
      }}
    />
  );
};

export default RangeSelector;
