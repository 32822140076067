/* eslint-disable no-undef */
import React, { useCallback, useState } from 'react';
import { Row, Col, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import BranchVerticalTimelineFilter from './BranchVerticalTimelineFilter';
import ServerWrong from '../../../components/Result/ServerWrong';
import VERTICAL_TIMELINE from '../../VerticalTimeline/gql';
import VerticalTimelineView from '../../VerticalTimeline/VerticalTimelineView';

const BranchVerticalTimeline = () => {
  const [condition, setCondition] = useState();

  const handleDateSelect = useCallback((selectedDate) => {
    const { partnerBranch } = JSON.parse(localStorage.getItem('user'));

    setCondition({
      date: selectedDate,
      partnerBranchId: Number(partnerBranch?.id),
    });
  }, []);

  const { loading, error, data } = useQuery(VERTICAL_TIMELINE, {
    variables: condition,
    skip: !condition,
  });

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={24}>
        <Row>
          <Col xs={24}>
            <BranchVerticalTimelineFilter handleOnSelect={handleDateSelect} />
          </Col>
        </Row>
        <Spin spinning={loading}>
          <Row style={{ marginTop: 30 }}>
            <Col span={24}>
              {data && (
                <VerticalTimelineView
                  data={data?.verticalTimeline?.timelines}
                />
              )}
            </Col>
          </Row>
        </Spin>
      </Col>
    </Row>
  );
};

export default BranchVerticalTimeline;
