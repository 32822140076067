import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const IsCutSongSelector = ({ onChange, initialrecord }) => {
  const [selectedOption, setSelectedOption] = useState();
  const { t } = useTranslation();
  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      defaultValue={
        initialrecord ? initialrecord.isCutSong : t('titles.choose')
      }
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
    >
      <Option value>{t('buttons.true')}</Option>
      <Option value={false}>{t('buttons.false')}</Option>
    </Select>
  );
};

export default IsCutSongSelector;
