import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const AdvertisementSelector = ({ onChange, initialrecord }) => {
  const [selectedOption, setSelectedOption] = useState();
  const { t } = useTranslation();

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      defaultValue={initialrecord ? initialrecord.type : t('titles.choose')}
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
    >
      <Option value="STANDARD">{t('columns.standard')}</Option>
      <Option value="INTER">{t('columns.inter')}</Option>
      <Option value="SPECIAL">{t('columns.special')}</Option>
    </Select>
  );
};

export default AdvertisementSelector;
