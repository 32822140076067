import React, { useState, useCallback } from 'react';
import { Layout, Menu, Avatar } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import SubMenu from 'antd/lib/menu/SubMenu';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import './header.css';
import { useTranslation } from 'react-i18next';
import { BACKEND_URL } from '../../constants';

const { Header } = Layout;

const Headers = () => {
  const defaultLang = i18next.language;
  const [currentLang, setCurrentLang] = useState(defaultLang);
  const history = useHistory();

  // hooks
  const handleChangeLang = (lang) => {
    i18next.changeLanguage(lang);
    setCurrentLang(lang);
  };

  const handleSignOut = useCallback(() => {
    window.localStorage.removeItem('user');
    window.localStorage.removeItem('token');

    history.push('/');
  }, [history]);

  const user = JSON.parse(window.localStorage.getItem('user'));
  const { t } = useTranslation();

  return (
    <Header className="site-layout-background headerWrapper" style={{lineHeight: 'revert'}}>
      <Link to="/welcome" style={{ marginLeft: '30px' }}>
        <img className="logo" src={require('./img/logo.png')} alt="logo" />
      </Link>

      <a
        href={`${BACKEND_URL}/static/uploads/client-v0.1.24.exe`}
        style={{ color: '#f68f09', textShadow: '0 -1px 0 rgba(0, 0, 0, 0.12)', margin: 'auto' }}
      >
        {t('labels.downloadApp')}
      </a>

      <Menu className="headermenu" mode="horizontal" style={{}}>
        <Menu.Item key="20" />
        <SubMenu
          className="headerflagmenu"
          key="sub1"
          title={
            <Avatar
              size="small"
              src={require(`../../config/img/${currentLang}.svg`)}
            />
          }
        >
          <Menu.Item key="tr" onClick={() => handleChangeLang('tr')}>
            <Avatar
              size="small"
              src={require('../../config/img/tr.svg')}
              style={{ marginRight: 5 }}
            />{' '}
            <span> {t('titles.tr')}</span>
          </Menu.Item>
          <Menu.Item key="en" onClick={() => handleChangeLang('en')}>
            <Avatar
              size="small"
              src={require('../../config/img/en.svg')}
              style={{ marginRight: 5 }}
            />{' '}
            <span> {t('titles.en')}</span>
          </Menu.Item>
          <Menu.Item key="de" onClick={() => handleChangeLang('de')}>
            <Avatar
              size="small"
              src={require('../../config/img/de.svg')}
              style={{ marginRight: 5 }}
            />{' '}
            <span> {t('titles.de')}</span>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          style={{ lineHeight: '23px' }}
          key="sub2"
          title={
            <span>
              <UserOutlined style={{ marginRight: 2, fontSize: 20 }} />
              {user.username}
            </span>
          }
        >
          <Menu.Item key="menu1">
            <UserOutlined style={{ marginRight: 5 }} /> {''}
            <Link to="/userDetail">{t('titles.userInfo')}</Link>
          </Menu.Item>

          <Menu.Item onClick={handleSignOut} key="menu2">
            <LogoutOutlined style={{ marginRight: 5 }} /> {''}
            <Link to="/">{t('buttons.logout')}</Link>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  );
};
export default Headers;
