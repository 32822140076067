import React from 'react';
import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Descriptions, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { msToTimeWithSecond } from '../../utils.js/adsSetHelper';

const SongElement = ({ song, start, end }) => {
  const { t } = useTranslation();
  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      date={`${msToTimeWithSecond(start)} - ${msToTimeWithSecond(end)}`}
      iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
      icon={<FontAwesomeIcon icon="music" />}
    >
      <h3 className="vertical-timeline-element-title">
        {song?.name || t('warnings.noData')}
      </h3>
      <h4 className="vertical-timeline-element-subtitle">
        {song?.artist
          ? `${song?.artist?.firstName} ${song?.artist?.lastName}`
          : t('warnings.noData')}
      </h4>
      <h4
        className="vertical-timeline-element-subtitle"
        style={{ fontWeight: 'bold' }}
      >
        {t('labels.song')}
      </h4>

      <Descriptions
        size="small"
        title={<h4 style={{ marginTop: 20 }}>{t('titles.songDetail')}</h4>}
        bordered
        column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }}
      >
        <Descriptions.Item label={t('labels.album')}>
          {song?.album?.name || t('warnings.noData')}
        </Descriptions.Item>
        <Descriptions.Item label={t('labels.productionCompany')}>
          {song?.productionCompany?.name || t('warnings.noData')}
        </Descriptions.Item>
        <Descriptions.Item label={t('labels.melodist')}>
          {song?.melodist
            ? `${song?.melodist?.firstName} ${song?.melodist?.lastName}`
            : t('warnings.noData')}
        </Descriptions.Item>
        <Descriptions.Item label={t('labels.writer')}>
          {song?.writer
            ? `${song?.writer?.firstName} ${song?.writer?.lastName}`
            : t('warnings.noData')}
        </Descriptions.Item>
        <Descriptions.Item label={t('labels.popularity')}>
          {song?.popularity || t('warnings.noData')}
        </Descriptions.Item>
        <Descriptions.Item label={t('labels.songPool')}>
          {song?.songPool?.name || t('warnings.noData')}
        </Descriptions.Item>
        <Descriptions.Item label={t('labels.catagory')}>
          {song?.songCategories
            ? song?.songCategories.map((category) => (
                <Tag key={category.id} color="blue">
                  {category?.name}
                </Tag>
              ))
            : t('warnings.noData')}
        </Descriptions.Item>
      </Descriptions>
    </VerticalTimelineElement>
  );
};

export default SongElement;
