export const MANAGER_ROUTES = [
  'home',
  'dashboard',
  'createUser',
  'allUsers',
  'partners',
  'partnerBranches',
  'statistic',
  'onOff',
  'advertisementSet',
  'songCategory',
  'songPoolDesc',
  'artistName',
  'productionCompany',
  'legalTracking',
  // 'adRequests',
  'advertisementPool',
  'standardAdvertisement',
  'interAdvertisement',
  'specialAdvertisement',
  'addSongToPlaylist',
  'scheduleSounds',
  'releasedSounds',
  'playlist',
  'allSongs',
  'addSong',
  'detail',
  'userDetail',
  'forgotPassword',
  'melodist',
  'writer',
  'album',
  'songPoolPartnerRelation',
  'verticalTimeline',
  'partnerSector',
  'partnerSectorCategory',
  'sector',
  'sectorCategory',
  'monitorAndEditPlaylist',
  'playlistPublish',
  'welcome',
  'calendar',
  'jinglePool',
  'jingle',
  'releasedJingle',
];

export const PARTNER_ROUTES = [
  'welcome',
  'dashboard',
  'partnerBranches',
  'userDetail',
  'playlistPublish',
  'playlist',
  'addSongToPlaylist',
  // 'adRequests',
  'advertisementSet',
  'advertisementPool',
  'standardAdvertisement',
  'interAdvertisement',
  'specialAdvertisement',
  'scheduleSounds',
  'releasedSounds',
  'onOff',
  'jinglePool',
  'jingle',
  'releasedJingle',
  'periodicAdvertisement'
];

export const BRANCH_ROUTES = [
  'welcome',
  'userDetail',
  'branchVerticalTimeline',
  'branchSounds',
  'branchAdvertisements',
  'branchSongs',
];
