import React, { useState, useCallback, useEffect } from 'react';
import { message, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import CREATE_USER from './gql';
import UserForm from './UserForm';
import Load from '../../components/Load';
import { USERS } from '../User/gql';

export default function User() {
  const { t } = useTranslation();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [addUser, { data, loading }] = useMutation(CREATE_USER, {
    update(cache, { data: responseAfterUpdate }) {
      const responseFromMutation = responseAfterUpdate.createUser.user;

      const queryData = cache.readQuery({
        query: USERS,
        variables: {
          condition: {
            limit: 10,
            offset: 0,
            order: [['createdAt', 'desc']],
          },
        },
      });
      cache.writeQuery({
        query: USERS,
        variables: {
          condition: {
            limit: 10,
            offset: 0,
            order: [['createdAt', 'desc']],
          },
        },
        data: {
          users: {
            ...queryData.users,
            data: [responseFromMutation, ...queryData.users.data],
          },
        },
      });
    },
  });

  const handleSubmit = useCallback(
    (inputValues) => {
      addUser({ variables: { data: inputValues } });
    },
    [addUser]
  );

  useEffect(() => {
    const __typename = data?.createUser?.__typename;
    if (__typename === 'UserCreateSuccess') {
      message.success(t('messages.successUserCreate'));
      setIsSubmitted(true);
    } else if (__typename === 'UserInvalidInputError') {
      message.error(t('messages.notSuccessUserCreate'));
    }
  }, [data, t, loading]);

  return (
    <Spin spinning={loading} indicator={<Load />}>
      <UserForm
        isSubmitted={isSubmitted}
        errorData={data?.createUser}
        handleSubmit={handleSubmit}
      />
    </Spin>
  );
}
