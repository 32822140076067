import { gql } from 'apollo-boost';

export const CREATE_WRITER = gql`
  mutation createWriter($data: WriterInput!) {
    createWriter(data: $data) {
      __typename
      ... on WriterCreateSuccess {
        writer {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
      ... on WriterInvalidInputError {
        firstName
        lastName
        gender
      }
    }
  }
`;

export const WRITERS = gql`
  query writers($condition: Condition) {
    writers(condition: $condition) {
      __typename
      ... on PaginatedWriterResponse {
        data {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
        totalCount
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_WRITER = gql`
  mutation updateWriter($where: JSON!, $newValues: WriterUpdateInput!) {
    updateWriter(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on WriterInvalidInputError {
        firstName
        lastName
        gender
      }
      ... on WriterUpdateSuccess {
        affectedRows
        newValues {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_WRITER = gql`
  mutation deleteWriter($where: JSON!) {
    deleteWriter(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on WriterDeleteSuccess {
        affectedRows
        deletedRows {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
    }
  }
`;
