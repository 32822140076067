import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Button, Card } from 'antd';
import { useTranslation } from 'react-i18next';
import { PartnerSelector, DateSelector } from './Selectors';
import '../components/MainLayout/style.css';
import userInfoGetter from '../utils.js/userInfoGetter';

const FilterSection = ({ handleFilterResult }) => {
  const { role, partnerId } = userInfoGetter();
  const { t } = useTranslation();
  const [chosenPartnerId, setChosenPartnerId] = useState();
  const [chosenStartDate, setChosenStartDate] = useState();
  const [chosenEndDate, setChosenEndDate] = useState();

  useEffect(() => {
    if (role === 'PARTNER') {
      setChosenPartnerId(partnerId);
      handleFilterResult({
        partnerId
      })
    }
  }, [role, partnerId,handleFilterResult]);

  const handlePartnerChange = useCallback((currentPartnerId) => {
  setChosenPartnerId(currentPartnerId);
  }, []);

  const handleStartDateChange = useCallback((startDate) => {
    setChosenStartDate(startDate);
  }, []);

  const handleEndDateChange = useCallback((endDate) => {
    setChosenEndDate(endDate);
  }, []);

  const handleClickFilter = useCallback(() => {
    handleFilterResult({
      partnerId: chosenPartnerId,
      startDate: chosenStartDate,
      endDate: chosenEndDate,
    });
  }, [chosenPartnerId, chosenStartDate, chosenEndDate, handleFilterResult]);  

  return (
    <Row gutter={(16, 16)}>
      <Col md={24} lg={24} xxl={24}>
        <Card>
          <Row gutter={(16, 16)}>
            {role !== 'PARTNER' && (
              <Col md={{ span: 4 }} xs={24} lg={{ span: 4 }} xxl={{ span: 2 }}>
                <div className="partnerSelector">
                  <span>{t('labels.partner')}</span>
                  <PartnerSelector onChange={handlePartnerChange} />
                </div>
              </Col>
            )}
            <Col md={{ span: 3 }} xs={12} lg={{ span: 3 }} xxl={{ span: 2 }}>
              <div className="dateSelectorStart">
                <span>{t('labels.start')}</span>
                <DateSelector onChange={handleStartDateChange} />
              </div>
            </Col>
            <Col
              md={{ span: 3 }}
              xs={{ span: 12 }}
              lg={{ span: 3 }}
              xxl={{ span: 2 }}
            >
              <div className="dateSelectorEnd">
                <span>{t('labels.end')}</span>
                <DateSelector onChange={handleEndDateChange} />
              </div>
            </Col>
            <Col md={{ span: 3 }} xs={{ span: 12 }} style={{ marginTop: 21 }}>
              <Button
                ghost
                type="primary"
                onClick={handleClickFilter}
                className="filterButton"
              >
                {t('buttons.filter')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default FilterSection;
