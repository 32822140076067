import { gql } from 'apollo-boost';

const SEARCH_PRODUCTION_COMPANY = gql`
  query productionCompanies($condition: Condition) {
    productionCompanies(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedProductionCompanyResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;

export default SEARCH_PRODUCTION_COMPANY;
