import { useState, useCallback } from 'react';

const useToggle = (initial = false) => {
  const [toggle, setToggle] = useState(initial);
  const handleToggle = useCallback(() => setToggle(!toggle), [
    setToggle,
    toggle,
  ]);

  return [toggle, handleToggle];
};

export default useToggle;
