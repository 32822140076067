import { Select, Row, Col, message, Tooltip } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SEARCH_PARTNERS } from './gql';

const { Option } = Select;

const PartnerWithSongPoolsSelector = ({ onChange }) => {
  const { t } = useTranslation();
  // hooks
  const [selectedPartner, setSelectedPartner] = useState();
  const [condition, setCondition] = useState({ limit: 5 });
  const { loading, error, data } = useQuery(SEARCH_PARTNERS, {
    variables: { condition },
  });
  const [
    songPoolsOfSelectedPartner,
    setSongPoolsOfSelectedPartner,
  ] = useState();

  useEffect(() => {
    const selectedPartnerWithPools = data?.partners?.data?.filter(
      (partner) => partner.id === selectedPartner
    )[0];
    if (selectedPartnerWithPools) {
      const { songPools } = selectedPartnerWithPools;

      if (!songPools) {
        message.warning(t('warnings.notYetAddedSongPoolToChosenPlaylist'));
      }

      setSongPoolsOfSelectedPartner(songPools);
    }
  }, [selectedPartner, data, t]);

  const handleSearchPartner = useCallback((input) => {
    if (input) {
      setCondition({ limit: 5, where: { name: { _like: `%${input}%` } } });
    }
  }, []);

  const handleChangePartnerChange = useCallback(
    (value) => {
      setSongPoolsOfSelectedPartner(undefined);
      setSelectedPartner(value);
    },
    [setSelectedPartner]
  );

  const handleSongPoolChange = useCallback(
    (songPoolId) => {
      onChange({ partnerId: selectedPartner, songPoolId });
    },
    [selectedPartner, onChange]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <>
      <Select
        loading={loading}
        showSearch
        value={selectedPartner}
        placeholder={t('messages.selectPartner')}
        style={{ width: '100%' }}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onSearch={handleSearchPartner}
        onChange={handleChangePartnerChange}
        notFoundContent={t('messages.notFoundContent')}
      >
        {data?.partners?.data &&
          data.partners.data.map((partner) => (
            <Option value={partner.id} key={partner.id}>
              {partner.name}
            </Option>
          ))}
      </Select>
      {songPoolsOfSelectedPartner && (
        <Row gutter={16}>
          <Col md={{ span: 24 }} xs={24}>
            <span>{t('titles.songPool')}</span>
            <Select style={{ width: '100%' }} onChange={handleSongPoolChange}>
              {songPoolsOfSelectedPartner.map((pool) => (
                <Option key={pool.id} value={pool.id}>
                  <Tooltip title={pool.name}>{pool.name}</Tooltip>
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      )}
    </>
  );
};

export default PartnerWithSongPoolsSelector;
