import { gql } from 'apollo-boost';

export const CREATE_ARTIST = gql`
  mutation createArtist($data: ArtistInput!) {
    createArtist(data: $data) {
      __typename
      ... on ArtistCreateSuccess {
        artist {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
      ... on ArtistInvalidInputError {
        firstName
        lastName
        gender
      }
    }
  }
`;

export const ARTISTS = gql`
  query artists($condition: Condition) {
    artists(condition: $condition) {
      __typename
      ... on PaginatedArtistResponse {
        data {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
        totalCount
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_ARTIST = gql`
  mutation updateArtist($where: JSON!, $newValues: ArtistUpdateInput!) {
    updateArtist(where: $where, newValues: $newValues) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on ArtistInvalidInputError {
        firstName
        lastName
        gender
      }
      ... on ArtistUpdateSuccess {
        affectedRows
        newValues {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const DELETE_ARTIST = gql`
  mutation deleteArtist($where: JSON!) {
    deleteArtist(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on ArtistDeleteSuccess {
        affectedRows
        deletedRows {
          id
          firstName
          lastName
          gender
          createdAt
          updatedAt
        }
      }
    }
  }
`;
