import { Select } from 'antd';
import React, { useState, useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import SEARCH_WRITER from './gql';

const { Option } = Select;

const WriterSelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  // hooks
  const [selectedOption, setSelectedOption] = useState();
  const [condition, setCondition] = useState({ limit: -1 });
  const { loading, error, data } = useQuery(SEARCH_WRITER, {
    variables: { condition },
  });

  const handleSearch = useCallback((input) => {
    if (input) {
      setCondition({
        limit: -1,
        where: {
          _or: {
            firstName: { _iLike: `%${input}%` },
            lastName: { _iLike: `%${input}%` },
          },
        },
      });
    }
  }, []);

  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <Select
      defaultValue={
        initialrecord?.writerFirstName && initialrecord?.writerLastName
          ? `${initialrecord.writerFirstName} ${initialrecord.writerLastName}`
          : undefined
      }
      loading={loading}
      showSearch
      value={selectedOption}
      placeholder={t('messages.selectWriter')}
      style={{ width: '100%' }}
      defaultActiveFirstOption={false}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={t('messages.notFoundContent')}
    >
      <Option value={null} key={null}>
        {t('buttons.cancelOption')}
      </Option>
      {data?.writers?.data &&
        data.writers.data.map((writer) => (
          <Option value={writer.id} key={writer.id}>
            {`${writer.firstName} ${writer.lastName}`}
          </Option>
        ))}
    </Select>
  );
};

export default WriterSelector;
