import { Select, Row, Col } from 'antd';
import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { SEARCH_PARTNERS } from '../PartnerSelector/gql';
import SEARCH_PARTNER_BRANCHES from '../PartnerBranchSelector/gql';
import userInfoGetter from '../../../utils.js/userInfoGetter';

const { Option } = Select;

const PartnerAndBranchSelector = ({ onChange }) => {
  // #region hooks
  const { t } = useTranslation();
  const { role, partnerId } = userInfoGetter();
  const [partnerCondition, setPartnerCondition] = useState({ limit: -1 });
  const [selectedPartner, setSelectedPartner] = useState();

  const [branchCondition, setBranchCondition] = useState();
  const [selectedBranches, setSelectedBranches] = useState([]);
  // #endregion hooks

  // #region  gql queries
  const {
    loading: partnerLoading,
    error: partnerError,
    data: partnerData,
  } = useQuery(SEARCH_PARTNERS, {
    variables: { condition: partnerCondition },
  });

  const {
    loading: branchLoading,
    error: branchError,
    data: branchData,
  } = useQuery(SEARCH_PARTNER_BRANCHES, {
    variables: { condition: branchCondition },
    skip: !branchCondition,
  });

  // #endregion gql queries

  // #region handles

  // search handles
  const handlePartnerSearch = useCallback(
    (input) => {
      if (input) {
        setPartnerCondition({
          limit: 5,
          where: {
            _or: {
              name: { _iLike: `%${input}%` },
            },
            partnerId,
          },
        });
      }
    },
    [setPartnerCondition, partnerId]
  );

  // select handles

  const handlePartnerChange = useCallback(
    (value) => {
      setSelectedPartner(value);
      setSelectedBranches();
      setBranchCondition({
        limit: -1,
        where: {
          partnerId: value,
        },
      });
    },
    [setSelectedPartner, setBranchCondition]
  );
  useEffect(() => {
    handlePartnerChange(partnerId);
  }, [handlePartnerChange, partnerId]);
  const handleBranchChange = useCallback(
    (value) => {
      setSelectedBranches(value);
      onChange(value);
    },
    [onChange, setSelectedBranches]
  );
  // #endregion handles

  if (partnerError || branchError) {
    return t('messages.somethingBad');
  }

  return (
    <Row gutter={[8, 8]}>
      <Col md={12} sm={24} xs={24}>
        {role === 'PARTNER' ? (
          <Select
            disabled
            loading={partnerLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            value={selectedPartner}
            placeholder={t('placeholders.selectPartner')}
            style={{ width: '100%' }}
            onSearch={handlePartnerSearch}
            onChange={handlePartnerChange}
          >
            {partnerData?.partners.data.map((partner) => (
              <Option value={partner.id} key={partner.id}>
                {partner.name}
              </Option>
            ))}
          </Select>
        ) : (
          <Select
            loading={partnerLoading}
            showSearch
            defaultActiveFirstOption={false}
            showArrow={false}
            filterOption={false}
            value={selectedPartner}
            placeholder={t('placeholders.selectPartner')}
            style={{ width: '100%' }}
            onSearch={handlePartnerSearch}
            onChange={handlePartnerChange}
          >
            {partnerData?.partners?.data &&
              partnerData.partners.data.map((partner) => (
                <Option value={partner.id} key={partner.id}>
                  {partner.name}
                </Option>
              ))}
          </Select>
        )}
      </Col>

      <Col md={12} sm={24} xs={24}>
        <Select
          disabled={!selectedPartner}
          loading={branchLoading}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          value={selectedBranches}
          placeholder={t('placeholders.selectBranch')}
          style={{ width: '100%' }}
          onChange={handleBranchChange}
        >
          {branchData?.partnerBranches?.data &&
            branchData.partnerBranches.data.map((branch) => (
              <Option value={branch.id} key={branch.id}>
                {branch.name}
              </Option>
            ))}
        </Select>
      </Col>
    </Row>
  );
};

export default PartnerAndBranchSelector;
