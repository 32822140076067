import { gql } from 'apollo-boost';

export const SECTORS = gql`
  query partnerSectors($condition: Condition) {
    partnerSectors(condition: $condition) {
      ... on PaginatedPartnerSectorResponse {
        data {
          id
          name
          createdAt
          updatedAt
        }
        totalCount
      }
    }
  }
`;

export const CREATE_SECTOR = gql`
  mutation createPartnerSector($data: PartnerSectorInput!) {
    createPartnerSector(data: $data) {
      __typename
      ... on PartnerSectorCreateSuccess {
        partnerSector {
          id
          name

          createdAt
          updatedAt
        }
      }
      ... on PartnerSectorInvalidInputError {
        name
      }
    }
  }
`;

export const UPDATE_SECTOR = gql`
  mutation updatePartnerSector(
    $where: JSON!
    $newValues: PartnerSectorUpdateInput!
  ) {
    updatePartnerSector(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PartnerSectorInvalidInputError {
        name
      }

      ... on PartnerSectorUpdateSuccess {
        affectedRows
        newValues {
          id
          name

          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_SECTOR = gql`
  mutation deletePartnerSector($where: JSON!) {
    deletePartnerSector(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on PartnerSectorDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
