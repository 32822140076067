import React, { Suspense } from 'react';
import Dashboard from '../../containers/Dashboard';
import CreateUser from '../../containers/CreateUser';
import Statistic from '../../containers/Statistic';
import Onoff from '../../containers/Onoff';
import Playlist from '../../containers/Playlist';
import Ads from '../../containers/Ads';
// import Sounds from '../../containers/Sounds';
// import ReleasedSounds from '../../containers/ReleasedSounds';
import Standard from '../../containers/Standart';
import InterAdvertisement from '../../containers/InterAdvertisement';
import Special from '../../containers/Special';
import User from '../../containers/User';
import Login from '../../containers/Login';
import Home from '../../containers/Home';
import Partner from '../../containers/Partner';
import PartnerBranch from '../../containers/PartnerBranch';
import SongPool from '../../containers/SongPool';
import SongCategory from '../../containers/SongCategory';
import Songs from '../../containers/Songs';
import AddNewSong from '../../containers/AddNewSong';
import Artist from '../../containers/Artist';
import ProductionCompany from '../../containers/ProductionCompany';
import LegalTracking from '../../containers/LegalTracking';
import AdvertisementSet from '../../containers/AdvertisementSet';
import ForgotPassword from '../../containers/ForgotPassword';
import Melodist from '../../containers/Melodist';
import Writer from '../../containers/Writer';
import Album from '../../containers/Album';
import SongPoolPartnerRelation from '../../containers/SongPoolPartnerRelation';
import PartnerSector from '../../containers/PartnerSector';
import PlaylistTabs from '../../containers/PlaylistTabs';
import PlaylistPublish from '../../containers/PlaylistPublish';
import BranchSounds from '../../containers/BranchPages/BranchSounds';
import BranchVerticalTimeline from '../../containers/BranchPages/BranchVerticalTimeline';
import BranchAdvertisements from '../../containers/BranchPages/BranchAdvertisements';
import BranchSongs from '../../containers/BranchPages/BranchSongs';
import PeriodicAdvertisement from '../../containers/PeriodicAdvertisement';
import Welcome from '../../containers/Welcome';
import JinglePool from '../../containers/Sounds/JinglePool';
import Jingle from '../../containers/Sounds/Jingle';
import ReleasedJinglePool from '../../containers/Sounds/ReleasedJinglePool';

// const LazyAdRequests = React.lazy(() => import('../../containers/AdRequests'));

// function AdRequests() {
//   return (
//     <Suspense fallback={<></>}>
//       <LazyAdRequests />
//     </Suspense>
//   );
// }

const PageRoutes = (t) => {
  return {
    publicRoutes: [
      {
        id: '0',
        path: '/',
        link: '/',
        component: Login,
        title: 'Login',
      },
      {
        id: '31',
        path: '/forgotPassword',
        link: '/forgotPassword',
        icon: 'home',
        name: 'Şifremi Unuttum',
        component: ForgotPassword,
        pageName: 'forgotPassword',
      },
    ],

    privateRoutes: {
      ADMIN: [
        {
          id: '1',
          path: '/home',
          link: '/home',
          icon: 'home',
          name: t('buttons.home'),
          component: Home,
          pageName: 'home',
        },
        {
          id: '2',
          path: '/dashboard',
          link: '/dashboard',
          icon: 'calendar',
          name: t('buttons.dashboard'),
          component: Dashboard,
          pageName: 'dashboard',
        },
        {
          id: '3',
          icon: 'user',
          name: t('buttons.userManagement'),
          subRoutes: [
            {
              id: '4',
              path: '/user/createUser',
              link: '/user/createUser',
              icon: 'userCreate',
              name: t('buttons.createUser'),
              component: CreateUser,
              pageName: 'createUser',
            },

            {
              id: '5',
              path: '/user/allUsers',
              link: '/user/allUsers',
              icon: 'allUser',
              name: t('buttons.users'),
              component: User,
              pageName: 'allUsers',
            },
            {
              id: '6',
              icon: 'partner',
              name: t('buttons.firms'),
              childRoutes: [
                {
                  id: '6',
                  path: '/user/partners',
                  link: '/user/partners',
                  icon: 'partner',
                  name: t('buttons.firms'),
                  component: Partner,
                  pageName: 'partners',
                },
                {
                  id: '36',
                  path: '/user/partnerSector',
                  link: '/user/partnerSector',
                  icon: 'sector',
                  name: t('buttons.sectorOfFirm'),
                  component: PartnerSector,
                  pageName: 'partnerSector',
                },
              ],
            },
            {
              id: '7',
              path: '/user/partnerBranches',
              link: '/user/partnerBranches',
              icon: 'branch',
              name: t('buttons.branches'),
              component: PartnerBranch,
              pageName: 'partnerBranches',
            },
          ],
        },
        {
          id: '8',
          path: '/statistic',
          link: '/statistic',
          icon: 'pieChart',
          name: t('buttons.statistic'),
          component: Statistic,
          pageName: 'statistic',
        },
        {
          id: '9',
          path: '/onOff',
          link: '/onOff',
          icon: 'wifi',
          name: t('buttons.onOff'),
          component: Onoff,
          pageName: 'onOff',
        },
        {
          id: '24',
          path: '/schedule/playlist',
          link: '/schedule/playlist',
          icon: 'headphone',
          name: t('buttons.playlist'),
          subRoutes: [
            {
              id: '38',
              path: '/playlistPublish',
              link: '/playlistPublish',
              icon: 'headphone',
              name: t('buttons.createPlaylistPublish'),
              component: PlaylistPublish,
              pageName: 'playlistPublish',
            },
            {
              id: '26',
              path: '/playlist',
              link: '/playlist',
              icon: 'headphone',
              name: t('buttons.createPlaylist'),
              component: Playlist,
              pageName: 'playlist',
            },
            {
              id: '32',
              path: '/schedule/addDisplaySongToPlaylist',
              link: '/schedule/addDisplaySongToPlaylist',
              icon: 'headphone',
              name: t('buttons.addSongAndViewPlaylist'),
              component: PlaylistTabs,
              pageName: 'addSongToPlaylist',
            },
          ],
        },
        {
          id: '27',
          icon: 'soundpool',
          name: t('buttons.songs'),
          subRoutes: [
            {
              id: '12',
              path: '/describings/songCategory',
              link: '/describings/songCategory',
              icon: 'songArea',
              name: t('buttons.fieldsForSong'),
              childRoutes: [
                {
                  id: '13',
                  path: '/describings/songCategory',
                  link: '/describings/songCategory',
                  icon: 'sound',
                  name: t('buttons.songCategory'),
                  component: SongCategory,
                  pageName: 'songCategory',
                },
                {
                  id: '15',
                  path: '/describings/artistName',
                  link: '/describings/artistName',
                  icon: 'sound',
                  name: t('buttons.artistName'),
                  component: Artist,
                  pageName: 'artistName',
                },
                {
                  id: '16',
                  path: '/describings/productionCompany',
                  link: '/describings/productionCompany',
                  icon: 'sound',
                  name: t('buttons.productionCompany'),
                  component: ProductionCompany,
                  pageName: 'productionCompany',
                },
                {
                  id: '17',
                  path: '/describings/legalTracking',
                  link: '/describings/legalTracking',
                  icon: 'sound',
                  name: t('buttons.legalTracking'),
                  component: LegalTracking,
                  pageName: 'legalTracking',
                },
                {
                  id: '32',
                  path: '/describings/melodist',
                  link: '/describings/melodist',
                  icon: 'sound',
                  name: t('buttons.melodist'),
                  component: Melodist,
                  pageName: 'melodist',
                },
                {
                  id: '33',
                  path: '/describings/writer',
                  link: '/describings/writer',
                  icon: 'sound',
                  name: t('buttons.writer'),
                  component: Writer,
                  pageName: 'writer',
                },
                {
                  id: '34',
                  path: '/describings/album',
                  link: '/describings/album',
                  icon: 'sound',
                  name: t('buttons.album'),
                  component: Album,
                  pageName: 'album',
                },
              ],
            },
            {
              id: 'songPool',
              icon: 'soundpool',
              name: t('buttons.songPool'),
              childRoutes: [
                {
                  id: '14',
                  path: '/describings/songPoolDesc',
                  link: '/describings/songPoolDesc',
                  icon: 'pool',
                  name: t('buttons.songPool'),
                  component: SongPool,
                  pageName: 'songPoolDesc',
                },
                {
                  id: '35',
                  path: '/describing/songPoolPartnerRelation',
                  link: '/describing/songPoolPartnerRelation',
                  icon: 'relation',
                  name: t('buttons.songPoolPartnerRelation'),
                  component: SongPoolPartnerRelation,
                  pageName: 'songPoolPartnerRelation',
                },
              ],
            },
            {
              id: '29',
              path: '/song/add',
              link: '/song/add',
              icon: 'newSong',
              name: t('buttons.addNewSong'),
              component: AddNewSong,
              pageName: 'addSong',
            },
            {
              id: '28',
              path: '/song/allSongs',
              link: '/song/allSongs',
              icon: 'music',
              name: t('buttons.allSongs'),
              component: Songs,
              pageName: 'allSongs',
            },
          ],
        },
        {
          id: '19',
          icon: 'notification',
          name: t('buttons.advertisement'),
          subRoutes: [
            // {
            //   id: 'adRequests',
            //   path: '/adRequests',
            //   link: '/adRequests',
            //   icon: 'notification',
            //   name: t('buttons.adRequest'),
            //   component: AdRequests,
            //   pageName: 'adRequests',
            // },
            {
              id: '11',
              path: '/describings/advertisementSet',
              link: '/describings/advertisementSet',
              icon: 'notification',
              name: t('buttons.createAdvertisementSet'),
              component: AdvertisementSet,
              pageName: 'advertisementSet',
            },
            {
              id: '20',
              path: '/schedule/advertisementPool',
              link: '/schedule/advertisementPool',
              icon: 'notification',
              name: t('buttons.advertisementPool'),
              component: Ads,
              pageName: 'advertisementPool',
            },
            {
              id: '21',
              path: '/schedule/standardAdvertisement',
              link: '/schedule/standardAdvertisement',
              icon: 'notification',
              name: t('buttons.standardAdvertisement'),
              component: Standard,
              pageName: 'standardAdvertisement',
            },
            {
              id: '22',
              path: '/schedule/interAdvertisement',
              link: '/schedule/interAdvertisement',
              icon: 'notification',
              name: t('buttons.interAdvertisement'),
              component: InterAdvertisement,
              pageName: 'interAdvertisement',
            },
            {
              id: '23',
              path: '/schedule/specialAdvertisement',
              link: '/schedule/specialAdvertisement',
              icon: 'notification',
              name: t('buttons.specialAdvertisement'),
              component: Special,
              pageName: 'specialAdvertisement',
            },
          ],
        },
        {
          id: '25',
          icon: 'sound',
          name: t('columns.jingle'),
          subRoutes: [
            {
              id: '36',
              path: '/schedule/jinglePool',
              link: '/schedule/jinglePool',
              icon: 'sound',
              name: t('columns.jinglePools'),
              component: JinglePool,
              pageName: 'jinglePool',
            },
            {
              id: '37',
              path: '/schedule/jingle',
              link: '/schedule/jingle',
              icon: 'sound',
              name: t('columns.jingle'),
              component: Jingle,
              pageName: 'jingle',
            },
            {
              id: '38',
              path: '/schedule/releasedJingle',
              link: '/schedule/releasedJingle',
              icon: 'sound',
              name: t('header.releasedJingle'),
              component: ReleasedJinglePool,
              pageName: 'releasedJingle',
            },
          ],
        },
      ],
      MANAGER: [
        {
          id: '1',
          path: '/home',
          link: '/home',
          icon: 'home',
          name: t('buttons.home'),
          component: Home,
          pageName: 'home',
        },
        {
          id: '2',
          path: '/dashboard',
          link: '/dashboard',
          icon: 'calendar',
          name: t('buttons.dashboard'),
          component: Dashboard,
          pageName: 'dashboard',
        },
        {
          id: '3',
          icon: 'user',
          name: t('buttons.userManagement'),
          subRoutes: [
            {
              id: '4',
              path: '/user/createUser',
              link: '/user/createUser',
              icon: 'userCreate',
              name: t('buttons.createUser'),
              component: CreateUser,
              pageName: 'createUser',
            },

            {
              id: '5',
              path: '/user/allUsers',
              link: '/user/allUsers',
              icon: 'allUser',
              name: t('buttons.users'),
              component: User,
              pageName: 'allUsers',
            },
            {
              id: '6',
              icon: 'partner',
              name: t('buttons.firms'),
              childRoutes: [
                {
                  id: '6',
                  path: '/user/partners',
                  link: '/user/partners',
                  icon: 'partner',
                  name: t('buttons.firms'),
                  component: Partner,
                  pageName: 'partners',
                },
                {
                  id: '36',
                  path: '/user/partnerSector',
                  link: '/user/partnerSector',
                  icon: 'sector',
                  name: t('buttons.sectorOfFirm'),
                  component: PartnerSector,
                  pageName: 'partnerSector',
                },
              ],
            },
            {
              id: '7',
              path: '/user/partnerBranches',
              link: '/user/partnerBranches',
              icon: 'branch',
              name: t('buttons.branches'),
              component: PartnerBranch,
              pageName: 'partnerBranches',
            },
          ],
        },
        {
          id: '8',
          path: '/statistic',
          link: '/statistic',
          icon: 'pieChart',
          name: t('buttons.statistic'),
          component: Statistic,
          pageName: 'statistic',
        },
        {
          id: '9',
          path: '/onOff',
          link: '/onOff',
          icon: 'wifi',
          name: t('buttons.onOff'),
          component: Onoff,
          pageName: 'onOff',
        },
        {
          id: '24',
          path: '/schedule/playlist',
          link: '/schedule/playlist',
          icon: 'headphone',
          name: t('buttons.playlist'),
          subRoutes: [
            {
              id: '38',
              path: '/playlistPublish',
              link: '/playlistPublish',
              icon: 'headphone',
              name: t('buttons.createPlaylistPublish'),
              component: PlaylistPublish,
              pageName: 'playlistPublish',
            },
            {
              id: '26',
              path: '/playlist',
              link: '/playlist',
              icon: 'headphone',
              name: t('buttons.createPlaylist'),
              component: Playlist,
              pageName: 'playlist',
            },
            {
              id: '32',
              path: '/schedule/addDisplaySongToPlaylist',
              link: '/schedule/addDisplaySongToPlaylist',
              icon: 'headphone',
              name: t('buttons.addSongAndViewPlaylist'),
              component: PlaylistTabs,
              pageName: 'addSongToPlaylist',
            },
          ],
        },
        {
          id: '27',
          icon: 'soundpool',
          name: t('buttons.songs'),
          subRoutes: [
            {
              id: '12',
              path: '/describings/songCategory',
              link: '/describings/songCategory',
              icon: 'songArea',
              name: t('buttons.fieldsForSong'),
              childRoutes: [
                {
                  id: '13',
                  path: '/describings/songCategory',
                  link: '/describings/songCategory',
                  icon: 'sound',
                  name: t('buttons.songCategory'),
                  component: SongCategory,
                  pageName: 'songCategory',
                },
                {
                  id: '15',
                  path: '/describings/artistName',
                  link: '/describings/artistName',
                  icon: 'sound',
                  name: t('buttons.artistName'),
                  component: Artist,
                  pageName: 'artistName',
                },
                {
                  id: '16',
                  path: '/describings/productionCompany',
                  link: '/describings/productionCompany',
                  icon: 'sound',
                  name: t('buttons.productionCompany'),
                  component: ProductionCompany,
                  pageName: 'productionCompany',
                },
                {
                  id: '17',
                  path: '/describings/legalTracking',
                  link: '/describings/legalTracking',
                  icon: 'sound',
                  name: t('buttons.legalTracking'),
                  component: LegalTracking,
                  pageName: 'legalTracking',
                },
                {
                  id: '32',
                  path: '/describings/melodist',
                  link: '/describings/melodist',
                  icon: 'sound',
                  name: t('buttons.melodist'),
                  component: Melodist,
                  pageName: 'melodist',
                },
                {
                  id: '33',
                  path: '/describings/writer',
                  link: '/describings/writer',
                  icon: 'sound',
                  name: t('buttons.writer'),
                  component: Writer,
                  pageName: 'writer',
                },
                {
                  id: '34',
                  path: '/describings/album',
                  link: '/describings/album',
                  icon: 'sound',
                  name: t('buttons.album'),
                  component: Album,
                  pageName: 'album',
                },
              ],
            },
            {
              id: 'songPool',
              icon: 'soundpool',
              name: t('buttons.songPool'),
              childRoutes: [
                {
                  id: '14',
                  path: '/describings/songPoolDesc',
                  link: '/describings/songPoolDesc',
                  icon: 'pool',
                  name: t('buttons.songPool'),
                  component: SongPool,
                  pageName: 'songPoolDesc',
                },
                {
                  id: '35',
                  path: '/describing/songPoolPartnerRelation',
                  link: '/describing/songPoolPartnerRelation',
                  icon: 'relation',
                  name: t('buttons.songPoolPartnerRelation'),
                  component: SongPoolPartnerRelation,
                  pageName: 'songPoolPartnerRelation',
                },
              ],
            },
            {
              id: '29',
              path: '/song/add',
              link: '/song/add',
              icon: 'newSong',
              name: t('buttons.addNewSong'),
              component: AddNewSong,
              pageName: 'addSong',
            },
            {
              id: '28',
              path: '/song/allSongs',
              link: '/song/allSongs',
              icon: 'music',
              name: t('buttons.allSongs'),
              component: Songs,
              pageName: 'allSongs',
            },
          ],
        },
        {
          id: '19',
          icon: 'notification',
          name: t('buttons.advertisement'),
          subRoutes: [
            // {
            //   id: 'adRequests',
            //   path: '/adRequests',
            //   link: '/adRequests',
            //   icon: 'notification',
            //   name: t('buttons.adRequest'),
            //   component: AdRequests,
            //   pageName: 'adRequests',
            // },
            {
              id: '11',
              path: '/describings/advertisementSet',
              link: '/describings/advertisementSet',
              icon: 'notification',
              name: t('buttons.createAdvertisementSet'),
              component: AdvertisementSet,
              pageName: 'advertisementSet',
            },
            {
              id: '20',
              path: '/schedule/advertisementPool',
              link: '/schedule/advertisementPool',
              icon: 'notification',
              name: t('buttons.advertisementPool'),
              component: Ads,
              pageName: 'advertisementPool',
            },
            {
              id: '21',
              path: '/schedule/standardAdvertisement',
              link: '/schedule/standardAdvertisement',
              icon: 'notification',
              name: t('buttons.standardAdvertisement'),
              component: Standard,
              pageName: 'standardAdvertisement',
            },
            {
              id: '22',
              path: '/schedule/interAdvertisement',
              link: '/schedule/interAdvertisement',
              icon: 'notification',
              name: t('buttons.interAdvertisement'),
              component: InterAdvertisement,
              pageName: 'interAdvertisement',
            },
            {
              id: '23',
              path: '/schedule/specialAdvertisement',
              link: '/schedule/specialAdvertisement',
              icon: 'notification',
              name: t('buttons.specialAdvertisement'),
              component: Special,
              pageName: 'specialAdvertisement',
            },
          ],
        },
        {
          id: '25',
          icon: 'sound',
          name: t('columns.jingle'),
          subRoutes: [
            {
              id: '36',
              path: '/schedule/jinglePool',
              link: '/schedule/jinglePool',
              icon: 'sound',
              name: t('columns.jinglePools'),
              component: JinglePool,
              pageName: 'jinglePool',
            },
            {
              id: '37',
              path: '/schedule/jingle',
              link: '/schedule/jingle',
              icon: 'sound',
              name: t('buttons.addJingle'),
              component: Jingle,
              pageName: 'jingle',
            },
            {
              id: '38',
              path: '/schedule/releasedJingle',
              link: '/schedule/releasedJingle',
              icon: 'sound',
              name: t('header.releasedJingle'),
              component: ReleasedJinglePool,
              pageName: 'releasedJingle',
            },
          ],
        },
      ],
      PARTNER: [
        {
          id: '2',
          path: '/dashboard',
          link: '/dashboard',
          icon: 'calendar',
          name: t('buttons.dashboardPartner'),
          component: Dashboard,
          pageName: 'dashboard',
        },
        {
          id: '7',
          path: '/user/partnerBranches',
          link: '/user/partnerBranches',
          icon: 'branch',
          name: t('buttons.branches'),
          component: PartnerBranch,
          pageName: 'partnerBranches',
        },
        {
          id: '9',
          path: '/onOff',
          link: '/onOff',
          icon: 'wifi',
          name: t('buttons.onOff'),
          component: Onoff,
          pageName: 'onOff',
        },
        {
          id: '24',
          path: '/schedule/playlist',
          link: '/schedule/playlist',
          icon: 'headphone',
          name: t('buttons.playlists'),
          subRoutes: [
            {
              id: '26',
              path: '/playlist',
              link: '/playlist',
              icon: 'headphone',
              name: t('buttons.createPlaylistName'),
              component: Playlist,
              pageName: 'playlist',
            },
            {
              id: '32',
              path: '/schedule/addDisplaySongToPlaylist',
              link: '/schedule/addDisplaySongToPlaylist',
              icon: 'headphone',
              name: t('buttons.addToSongPlaylist'),
              component: PlaylistTabs,
              pageName: 'addSongToPlaylist',
            },

            {
              id: '38',
              path: '/playlistPublish',
              link: '/playlistPublish',
              icon: 'headphone',
              name: t('buttons.createPlaylists'),
              component: PlaylistPublish,
              pageName: 'playlistPublish',
            },
            {
              id: '25',
              icon: 'sound',
              name: t('columns.jingle'),
              childRoutes: [
                {
                  id: '37',
                  path: '/schedule/jingle',
                  link: '/schedule/jingle',
                  icon: 'sound',
                  name: t('columns.jingle'),
                  component: Jingle,
                  pageName: 'jingle',
                },
                {
                  id: '38',
                  path: '/schedule/releasedJingle',
                  link: '/schedule/releasedJingle',
                  icon: 'sound',
                  name: t('header.releasedJingle'),
                  component: ReleasedJinglePool,
                  pageName: 'releasedJingle',
                },
              ],
            },
          ],
        },
        {
          id: '19',
          icon: 'notification',
          name: t('buttons.advertisement'),
          subRoutes: [
            // {
            //   id: 'adRequests',
            //   path: '/adRequests',
            //   link: '/adRequests',
            //   icon: 'notification',
            //   name: t('buttons.adRequest'),
            //   component: AdRequests,
            //   pageName: 'adRequests',
            // },
            // {
            //   id: '11',
            //   path: '/describings/advertisementSet',
            //   link: '/describings/advertisementSet',
            //   icon: 'notification',
            //   name: t('buttons.createAdsSet'),
            //   component: AdvertisementSet,
            //   pageName: 'advertisementSet',
            // },

            {
              id: '20',
              path: '/schedule/advertisementPool',
              link: '/schedule/advertisementPool',
              icon: 'notification',
              name: t('buttons.advertisementPlanning'),
              component: Ads,
              pageName: 'advertisementPool',
            },
            {
              id: '50',
              path: '/schedule/advertisementPeriodic',
              link: '/schedule/advertisementPeriodic',
              icon: 'notification',
              name: t('buttons.periodicAdvertisementsRelease'),
              component: PeriodicAdvertisement,
              pageName: 'periodicAdvertisement',
            },
            // {
            //   id: '21',
            //   path: '/schedule/standardAdvertisement',
            //   link: '/schedule/standardAdvertisement',
            //   icon: 'notification',
            //   name: t('buttons.standardAdsPublish'),
            //   component: Standard,
            //   pageName: 'standardAdvertisement',
            // },
            // {
            //   id: '22',
            //   path: '/schedule/interAdvertisement',
            //   link: '/schedule/interAdvertisement',
            //   icon: 'notification',
            //   name: t('buttons.interAdsPublish'),
            //   component: InterAdvertisement,
            //   pageName: 'interAdvertisement',
            // },
            {
              id: '23',
              path: '/schedule/specialAdvertisement',
              link: '/schedule/specialAdvertisement',
              icon: 'notification',
              name: t('buttons.specialAdsPublish'),
              component: Special,
              pageName: 'specialAdvertisement',
            },
          ],
        },
      ],
      BRANCH: [
        {
          id: '1',
          path: '/welcome',
          link: '/welcome',
          icon: 'home',
          name: t('buttons.home'),
          component: Welcome,
          pageName: 'welcome',
        },
        {
          id: '2',
          path: '/branchVerticalTimeline',
          link: '/branchVerticalTimeline',
          icon: 'calendar',
          name: t('buttons.timeline'),
          component: BranchVerticalTimeline,
          pageName: 'branchVerticalTimeline',
        },
        {
          id: '3',
          path: '/branchSounds',
          link: '/branchSounds',
          icon: 'sound',
          name: t('columns.jingle'),
          component: BranchSounds,
          pageName: 'branchSounds',
        },
        {
          id: '4',
          path: '/branchAdvertisements',
          link: '/branchAdvertisements',
          icon: 'notification',
          name: t('buttons.advertisements'),
          component: BranchAdvertisements,
          pageName: 'branchAdvertisements',
        },
        {
          id: '5',
          path: '/branchSongs',
          link: '/branchSongs',
          icon: 'soundpool',
          name: t('buttons.songs'),
          component: BranchSongs,
          pageName: 'branchSongs',
        },
      ],
    },
  };
};

export default PageRoutes;
