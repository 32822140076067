/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { MONTHLY_STATISTIC } from '../gql';
import ServerWrong from '../../../components/Result/ServerWrong';

const InfoBoxes = ({ setTotalSongPublishCount }) => {
  const { t } = useTranslation();
  const [info, setInfo] = useState();
  const { loading, error, data } = useQuery(MONTHLY_STATISTIC, {
    variables: {
      type: 'SONG',
      topNumber: 3,
    },
  });

  useEffect(() => {
    if (
      data &&
      data?.monthlyTopByEachRecords?.__typename === 'MonthsStatistics'
    ) {
      const monthsStatistics = data?.monthlyTopByEachRecords?.statistic;
      const currentMonthIndex = new Date().getMonth() + 1;
      const currentMonthData = monthsStatistics.filter(
        (datum) => datum.month === currentMonthIndex
      );
      const totalSongPublishData = currentMonthData[0]?.statistic?.reduce(
        (acc, cur) => {
          acc += cur.value;
          return acc;
        },
        0
      );
      setTotalSongPublishCount(totalSongPublishData);
      setInfo(currentMonthData[0]);
    }
  }, [data, setTotalSongPublishCount, setInfo]);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading}>
      <Card style={styles.songInfoBox} className="songInfoBox">
        <Row>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 6 }}>
            <FontAwesomeIcon icon="music" size="4x" />
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 18 }}>
            <p className="p">{t('titles.mostThreeSong')}</p>
            <ul>
              {info
                ? info?.statistic?.map((datum, index) => (
                    <li className="p" key={datum.name + index}>
                      {`${datum?.name}: ${datum.value}`}
                    </li>
                  ))
                : t('warnings.noData')}
            </ul>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default InfoBoxes;

const styles = {
  songInfoBox: {
    height: 220,
    fontSize: 16,
    color: '#fff',
    borderRadius: 10,
    boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
    background: 'linear-gradient(150deg, #42e695, #3bb2b8 100%)',
  },
};
