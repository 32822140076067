/* eslint-disable consistent-return */
import React from 'react';

import Lottie from 'react-lottie';
import animationData from './animation.json';
const Load = () => {
  // hooks

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  return <Lottie options={defaultOptions} height={150} width={150} />;
};

export default Load;
