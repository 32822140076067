import { gql } from 'apollo-boost';

const VERTICAL_TIMELINE = gql`
  query verticalTimeline($date: Date!, $partnerBranchId: Int!) {
    verticalTimeline(date: $date, partnerBranchId: $partnerBranchId) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on Timeline {
        timelines
      }
    }
  }
`;

export default VERTICAL_TIMELINE;
