import { gql } from 'apollo-boost';

const SEARCH_ALBUM = gql`
  query albums($condition: Condition) {
    albums(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedAlbumResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;

export default SEARCH_ALBUM;
