import React from 'react';
import { Row, Col, Card, Statistic } from 'antd';
import { DASHBOARD_DATA_COUNT } from './gql';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../../components/MainLayout/style.css';

export default function Home() {
  const { t } = useTranslation();
  const { data } = useQuery(DASHBOARD_DATA_COUNT);

  // const columns = [
  //   {
  //     title: 'Kullanıcı',
  //     dataIndex: 'user',
  //     key: 'user',
  //   },
  //   {
  //     title: 'Tarih',
  //     dataIndex: 'date',
  //     key: 'date',
  //   },
  //   {
  //     title: 'Aktivite Adı',
  //     dataIndex: 'name',
  //     key: 'name',
  //   },
  // ];
  // const dataSource = [
  //   {
  //     key: '1',
  //     name: 'Şarkı Ekledi',
  //     date: '23.04.2020',
  //     user: 'Emel',
  //   },
  //   {
  //     key: '2',
  //     name: 'Playlist Tanımladı',
  //     date: '23.04.2020',
  //     user: 'Erkan',
  //   },
  //   {
  //     key: '2',
  //     name: 'Şarkı Ekledi',
  //     date: '23.04.2020',
  //     user: 'Çağatay',
  //   },
  //   {
  //     key: '1',
  //     name: 'Şarkı Ekledi',
  //     date: '23.04.2020',
  //     user: 'Emel',
  //   },
  //   {
  //     key: '2',
  //     name: 'Playlist Tanımladı',
  //     date: '23.04.2020',
  //     user: 'Erkan',
  //   },
  // ];
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#00C0EF',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
              color: '#fff',
            }}
          >
            <Row>
              <Col xs={6} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="rss" size="4x" color="#fff" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.activeBranchCount')}
                    </h3>
                  }
                  value={data?.allPartnerBranchStatus.onlineCount}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#00A75A',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
            }}
          >
            <Row>
              <Col xs={8} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="handshake" color="#fff" size="4x" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.totalPartnerCount')}
                    </h3>
                  }
                  value={data?.partnerCount - 1 /** remove test account */}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#F39C11',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
            }}
          >
            <Row>
              <Col xs={6} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="code-branch" color="#fff" size="4x" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.totalBranchCount')}
                    </h3>
                  }
                  value={data?.partnerBranchCount - 1 /** remove test account */}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#D84937',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
            }}
          >
            <Row>
              <Col xs={6} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="play-circle" color="#fff" size="4x" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.planningPlaylistCount')}
                    </h3>
                  }
                  value={data?.plannedPlaylistCount}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#026FB0',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
            }}
          >
            <Row>
              <Col xs={6} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="microphone" color="#fff" size="4x" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.planningSpeciesCount')}
                    </h3>
                  }
                  value={data?.plannedSpeciesCount}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#6553A3',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
            }}
          >
            <Row>
              <Col xs={8} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="ad" color="#fff" size="4x" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.planningTeaserCount')}
                    </h3>
                  }
                  value={data?.plannedTeaserCount}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#ffccc7',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
            }}
          >
            <Row>
              <Col xs={8} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="music" color="#fff" size="4x" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.totalSongCount')}
                    </h3>
                  }
                  value={data?.songCount}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card
            style={{
              backgroundColor: '#819594',
              borderRadius: 10,
              boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
              transition: '0.3s',
              height: 140,
            }}
          >
            <Row>
              <Col xs={8} sm={6} md={6} className="icon">
                <FontAwesomeIcon icon="book-open" color="#fff" size="4x" />
              </Col>
              <Col xs={14} sm={14} md={14}>
                <Statistic
                  title={
                    <h3 className="h4" style={{ color: '#fff' }}>
                      {t('titles.totalCategoryCount')}
                    </h3>
                  }
                  value={data?.songCategoryCount}
                  // precision={2}
                  valueStyle={{ color: '#fff' }}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        {/* <Col xs={24} sm={24} md={24} lg={24}>
          <Card style={{ backgroundColor: '#fff' }}>
            <Col xs={24} sm={24} md={24}>
              <h1>Son 5 Aktivite</h1>
            </Col>
            <Col md={24} xs={24} sm={24} style={{ backgroundColor: '#fff' }}>
              {' '}
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
              />
            </Col>
          </Card>
        </Col> */}
      </Row>
    </div>
  );
}
