import React from 'react';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import panelData from './panel.json';
import { Row, Col } from 'antd';

export default function Welcome() {
  const { t } = useTranslation();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: panelData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <>
      <Row>
        <Col md={24} sm={24} xs={24}>
          {' '}
          <h1 className="welcomeMessage" style={{ textAlign: 'center' }}>
            {t('titles.welcome')}
          </h1>
        </Col>
      </Row>
      <Row>
        <Col md={24} sm={24} xs={24}>
          <Lottie
            options={defaultOptions}
            style={{ width: '60%', height: 'auto' }}
          />
        </Col>
      </Row>
    </>
  );
}
