/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import { Row, Col, Divider, Tabs } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import InfoBoxes from './InfoBoxesStatistic/InfoBoxes';
import AdvertisementStatistic from './AdvertisementStatistic/AdvertisementStatistic';
import SoundTypesStatistic from './SoundTypesStatistic/SoundTypesStatistic';
import SongStatistic from './SongStatistic/SongStatistic';
import './statistic.css';

const Statistic = () => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();

  return (
    <div>
      <Divider orientation="left">{t('titles.statisticSummary')}</Divider>
      <Row>
        <Col sm={24} xs={24} md={{ span: 24 }} lg={24}>
          <InfoBoxes />
        </Col>
      </Row>

      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <FontAwesomeIcon icon="ad" size="1x" />{' '}
              {t('titles.advertisementStatistic')}
            </span>
          }
          key="1"
        >
          <Divider orientation="left">
            {' '}
            {t('titles.advertisementStatistic')}
          </Divider>
          <Row>
            <Col sm={24} xs={24} md={{ span: 24 }}>
              <AdvertisementStatistic />
            </Col>
          </Row>
        </TabPane>
        <TabPane
          tab={
            <span>
              <FontAwesomeIcon icon="microphone" size="1x" />{' '}
              {t('titles.soundTypeStatistic')}
            </span>
          }
          key="2"
        >
          <Divider orientation="left">{t('titles.soundTypeStatistic')}</Divider>
          <Row>
            <Col sm={24} xs={24} md={{ span: 24 }}>
              <SoundTypesStatistic />
            </Col>
          </Row>
        </TabPane>
      </Tabs>
      <Divider orientation="left"> {t('titles.songStatistic')}</Divider>
      <Row>
        <Col className="songCard" md={{ span: 10, offset: 1 }} sm={24} xs={24}>
          <SongStatistic type="max" />
        </Col>
        <Col md={{ span: 10, offset: 2 }} sm={24} xs={24}>
          <SongStatistic type="min" />
        </Col>
      </Row>
    </div>
  );
};

export default Statistic;
