import { gql } from 'apollo-boost';

export const SEARCH_JINGLE_POOLS = gql`
  query jinglePools($condition: Condition) {
    jinglePools(condition: $condition) {
      __typename

      ... on PaginatedJinglePoolResponse {
        data {
          id
          name
        }

        totalCount
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const SEARCH_PARTNERS = gql`
  query searchPartners($condition: Condition) {
    partners(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;
