import { gql } from 'apollo-boost';

const CREATE_SONG = gql`
  mutation createSong($data: SongInput!, $file: Upload!) {
    createSong(data: $data, file: $file) {
      __typename

      ... on SongInvalidInputError {
        name
        country
        productionCompanyId
        artistId
        albumId
        melodistId
        writerId
        legalTackingId
        popularity
        songPoolId
        songCategories
      }

      ... on SongCreateSuccess {
        song {
          id
          name
          country
          productionCompany {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on ProductionCompany {
              id
              name
            }
          }
          file {
            id
            filename
            address
          }
          artist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Artist {
              id
              firstName
              lastName
            }
          }
          album {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Album {
              id
              name
            }
          }
          writer {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Writer {
              id
              firstName
              lastName
            }
          }
          melodist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Melodist {
              id
              firstName
              lastName
            }
          }
          legalTracking {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on LegalTracking {
              id
              name
            }
          }
          popularity
          createdAt
          updatedAt
          songPool {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on SongPool {
              id
              name
            }
          }
          songCategories {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on PaginatedSongCategoryResponse {
              data {
                id
                name
              }
              totalCount
            }
          }
        }
      }
    }
  }
`;

export default CREATE_SONG;
