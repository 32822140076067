import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Row, Col, message } from 'antd';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import { timeToMS, msToTime } from '../../utils.js/adsSetHelper';
import useToggle from '../../hooks/useToggle';
import {
  RELEASED_PLAYLISTS,
  UPDATE_RELEASED_PLAYLIST,
  DELETE_RELEASED_PLAYLIST,
  CREATE_RELEASED_PLAYLIST,
} from './gql';
import userInfoGetter from '../../utils.js/userInfoGetter';

const fieldsCreator = (t) => [
  {
    name: 'partnerBranchAndPlaylistSection',
    label: t('labels.partner'),
    inputType: 'partnerAndBranchWithPlaylistSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'days',
    label: t('labels.days'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    inputType: 'daysOfWeekSelector',
  },
  {
    name: 'start',
    label: t('labels.startHour'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    inputType: 'timeSelector',
    propsForSelector: { returnType: 'timeString', fieldName: 'start' },
  },
  {
    name: 'end',
    label: t('labels.endHour'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    inputType: 'timeSelector',
    propsForSelector: { returnType: 'timeString', fieldName: 'end' },
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.partnerName'),
    dataIndex: 'partnerId',
    key: 'partnerId',
    width: '14%',
    inputType: 'partnerSelector',
    editable: false,
    render: (_, record) => {
      return record?.partnerName || t('warnings.noData');
    },
  },
  {
    title: t('labels.partnerBranch'),
    dataIndex: 'partnerBranchIds',
    key: 'partnerBranchIds',
    width: '20%',
    inputType: 'partnerBranchSelector',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (_, record) => {
      if (record?.areAllBranchesSelected) {
        return <h4 key="all">{t('labels.allBranchesSelected')}</h4>;
      }
      return record?.partnerBranches?.map((branch) => (
        <span style={{ display: 'block' }} key={branch.id}>
          {branch.name}
        </span>
      ));
    },
  },
  {
    title: t('columns.playlist'),
    dataIndex: 'playlistId',
    key: 'playlistId',
    width: '15%',
    inputType: 'playlistSelector',
    editable: false,
    render: (_, record) => {
      return record?.playlistName || t('warnings.noData');
    },
  },
  {
    title: t('columns.selectedDays'),
    dataIndex: 'days',
    key: 'days',
    width: '11%',
    inputType: 'daysOfWeekSelector',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (days) => {
      if (days.length === 7) {
        return t('labels.allDays');
      }
      if (
        days.length === 2 &&
        days.includes('SUNDAY') &&
        days.includes('SATURDAY')
      ) {
        return t('labels.weekend');
      }
      if (
        days.length === 5 &&
        days.includes('MONDAY') &&
        days.includes('TUESDAY') &&
        days.includes('WEDNESDAY') &&
        days.includes('THURSDAY') &&
        days.includes('FRIDAY')
      ) {
        return t('labels.weekdays');
      }

      return days.map((day) => (
        <span style={{ display: 'block' }} key={day}>
          {t(`labels.${day}`)}
        </span>
      ));
    },
  },
  {
    title: t('columns.startHour'),
    dataIndex: 'start',
    key: 'start',
    width: '13%',
    inputType: 'timeSelector',
    propsForSelector: { returnType: 'timeString', fieldName: 'start' },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      if (value === undefined || value === null) {
        return t('warnings.noData');
      }
      return msToTime(value);
    },
  },
  {
    title: t('columns.endHour'),
    dataIndex: 'end',
    key: 'end',
    width: '13%',
    inputType: 'timeSelector',
    propsForSelector: { returnType: 'timeString', fieldName: 'end' },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      if (value === undefined || value === null) {
        return t('warnings.noData');
      }
      return msToTime(value);
    },
  },
];

const PlaylistPublish = () => {
  const { partnerId, role } = userInfoGetter();
  const { t } = useTranslation();
  const [visibility, toggleModal] = useToggle(false);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const releasedPlaylistsData = response?.releasedPlaylists?.data;
    const newData = releasedPlaylistsData?.map((releasedPlaylist) => {
      return {
        ...releasedPlaylist,
        partnerBranchIds: releasedPlaylist?.partnerBranches?.map(
          (branch) => branch.id
        ),
        partnerName: releasedPlaylist?.partner?.name,
        partnerId: releasedPlaylist?.partner?.id,
        playlistName: releasedPlaylist?.playlist?.name,
        playlistId: releasedPlaylist?.playlist?.id,
      };
    });

    return {
      ...response,
      releasedPlaylists: {
        ...response?.releasedPlaylists,
        data: newData,
      },
    };
  }, []);

  const beforeUpload = useCallback(
    (req) => {
      const startDuration = timeToMS(req.start);
      const endDuration = timeToMS(req.end);

      if (startDuration >= endDuration) {
        message.error(t('warnings.startMustBeSmallerThanEnd'));
        return null;
      }

      const newReq = {
        ...req.partnerBranchAndPlaylistSection,
        days: req.days,
        start: startDuration,
        end: endDuration,
      };

      return { variables: { data: newReq } };
    },
    [t]
  );

  const beforeSave = useCallback(
    (recordId, record) => {
      const newRecord = {
        ...record,
        start:
          typeof record.start === 'number'
            ? record.start
            : timeToMS(record.start),
        end: typeof record.end === 'number' ? record.end : timeToMS(record.end),
      };

      if (newRecord.start >= newRecord.end) {
        message.error(t('warnings.startMustBeSmallerThanEnd'));
        return null;
      }

      return {
        variables: {
          where: { id: recordId },
          newValues: newRecord,
        },
      };
    },
    [t]
  );

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="center">{t('header.playlist')}</Divider>
        <Col span={5} md={24} sm={24} xs={24}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleModal}
            modalTitle={t('header.playlist')}
            showModalButtonLabel={t('header.playlist')}
            fields={fields}
            postReq={CREATE_RELEASED_PLAYLIST}
            postResIndex="createReleasedPlaylist"
            postResFieldForUpdating="releasedPlaylist"
            willBeUpdatedQuery={RELEASED_PLAYLISTS}
            willBeUpdatedQueryName="releasedPlaylists"
            willBeUpdatedQueryExtraVariable={
              role === 'PARTNER' ? { where: { partnerId } } : undefined
            }
            beforeUpload={beforeUpload}
          />
        </Col>
        <Col style={{ marginTop: 10 }} md={24} lg={24} sm={24} xs={24}>
          <IntegratedEditableTable
            columns={columns}
            getReq={RELEASED_PLAYLISTS}
            getResIndex="releasedPlaylists"
            deleteReq={DELETE_RELEASED_PLAYLIST}
            deleteResIndex="deleteReleasedPlaylist"
            updateReq={UPDATE_RELEASED_PLAYLIST}
            updateResIndex="updateReleasedPlaylist"
            afterGetFunc={afterGetFunc}
            fetchPolicy="network-only"
            beforeSave={beforeSave}
            filterColumns={{
              playlist: ['name'],
              partner: ['name'],
            }}
            upperGetReqCondition={
              partnerId && {
                limit: 10,
                offset: 0,
                order: [['createdAt', 'desc']],
                where: {
                  partnerId,
                },
              }
            }
          />
        </Col>
      </Row>
    </>
  );
};

export default PlaylistPublish;
