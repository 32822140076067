import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import inputCreator from '../../utils.js/inputCreator';

const EditableCell = ({
  form,
  editing,
  dataIndex,
  inputType,
  children,
  rules,
  record,
  errorsMessages,
  propsForSelector,
  ...restProps
}) => {
  const { t } = useTranslation();
  const SelectedInput = inputCreator(inputType);

  // trigger handleChange manually for dropdown and select menu.
  // Because they overrides handleChange.
  const handleChange = (value) => {
    if (typeof value !== 'object') {
      form.setFieldsValue({ [dataIndex]: value });
    }
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          onChange={handleChange}
          validateStatus={errorsMessages?.[dataIndex] ? 'error' : 'success'}
          help={
            errorsMessages?.[dataIndex]
              ? t(`errors.${errorsMessages?.[dataIndex]}`)
              : null
          }
          rules={rules}
          name={dataIndex}
        >
          <SelectedInput
            initialrecord={record}
            propsForSelector={propsForSelector}
          />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
