import { gql } from 'apollo-boost';

const CREATE_USER = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      __typename
      ... on UserCreateSuccess {
        user {
          id
          firstName
          lastName
          email
          username
          role
          description
          primaryPhone
          secondaryPhone
          createdAt
          updatedAt
        }
      }
      ... on UserInvalidInputError {
        firstName
        lastName
        email
        password
        username
        role
        description
        primaryPhone
        secondaryPhone
      }
    }
  }
`;

export default CREATE_USER;
