/* eslint-disable no-param-reassign */
import React, { useState, useCallback } from 'react';
import { Row, Col, Divider, Avatar } from 'antd';
import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import { CREATE_ALBUM, ALBUMS, UPDATE_ALBUM, DELETE_ALBUM } from './gql';
import { BACKEND_URL } from '../../constants';

const mimeTypeGroups = [
  'image/bmp',
  'image/gif',
  'image/jpeg',
  'image/png',
  'image/png',
  'image/tiff',
  'image/webp',
];

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.name'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'artistId',
    label: t('titles.Artist'),
    inputType: 'artistSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'file',
    label: t('titles.photo'),
    inputType: 'albumFileInput',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        validator: (_, value) => {
          const fileType = value.type;
          return mimeTypeGroups.includes(fileType)
            ? Promise.resolve()
            : Promise.reject(t('messages.invalideFile'));
        },
      },
      {
        validator: (_, value) => {
          const fileSize = value.size;
          return fileSize < 104857600
            ? Promise.resolve()
            : Promise.reject(t('messages.fileSmaller'));
        },
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.name'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
  },
  {
    title: t('titles.Artist'),
    dataIndex: 'artistId',
    key: 'artistId',
    inputType: 'artistSelector',
    editable: true,
    render: (artistId, record) => {
      if (record.artistFirstName && record.artistLastName) {
        return `${record.artistFirstName} ${record.artistLastName}`;
      }
      return t('warnings.nodata');
    },
  },
  {
    title: t('titles.file'),
    dataIndex: 'file',
    key: 'file',
    inputType: 'albumFileInput',
    editable: true,
    render: (fileId, record) => {
      return <Avatar size={64} src={BACKEND_URL + record.address} />;
    },
  },
];

export default function Album() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const beforeUpload = useCallback((formValues) => {
    const { file } = formValues;
    delete formValues.file;

    return { variables: { data: formValues, file } };
  }, []);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.albums?.data?.map((album) => ({
      id: album.id,
      name: album.name,
      artistFirstName: album.artist?.firstName,
      artistLastName: album.artist?.lastName,
      artistId: album.artist?.id,
      address: album.file?.address,
    }));

    return { ...response, albums: { ...response?.albums, data: newData } };
  }, []);

  const beforeSave = useCallback((recordId, newRecord) => {
    const { file } = newRecord;
    // eslint-disable-next-line no-undef
    delete newRecord.file;

    return {
      variables: {
        where: { id: recordId },
        newValues: newRecord,
        file,
      },
    };
  }, []);
  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('titles.albums')}</Divider>

      <Col span={24} style={{ marginTop: '20px' }} sm={24} xs={24}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newAlbum')}
          showModalButtonLabel={t('titles.newAlbum')}
          fields={fields}
          postReq={CREATE_ALBUM}
          postResIndex="createAlbum"
          postResFieldForUpdating="album"
          willBeUpdatedQuery={ALBUMS}
          willBeUpdatedQueryName="albums"
          beforeUpload={beforeUpload}
        />
      </Col>
      <Col style={{ marginTop: 10 }} md={24} lg={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={ALBUMS}
          getResIndex="albums"
          deleteReq={DELETE_ALBUM}
          deleteResIndex="deleteAlbum"
          updateReq={UPDATE_ALBUM}
          updateResIndex="updateAlbum"
          afterGetFunc={afterGetFunc}
          filterColumns={{ album: ['name'], artist: ['firstName', 'lastName'] }}
          beforeSave={beforeSave}
        />
      </Col>
    </Row>
  );
}
