import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const columnsConfig = (t) => [
  t('country.turkey'),
  t('country.afghanistan'),
  t('country.albania'),
  t('country.algeria'),
  t('country.americanSamoa'),
  t('country.andorrA'),
  t('country.angola'),
  t('country.anguilla'),
  t('country.antarctica'),
  t('country.antiguaBarbuda'),
  t('country.argentina'),
  t('country.armenia'),
  t('country.aruba'),
  t('country.australia'),
  t('country.austria'),
  t('country.azerbaijan'),
  t('country.bahamas'),
  t('country.bahrain'),
  t('country.bangladesh'),
  t('country.barbados'),
  t('country.belarus'),
  t('country.belgium'),
  t('country.belize'),
  t('country.benin'),
  t('country.bermuda'),
  t('country.bhutan'),
  t('country.bolivia'),
  t('country.bosniaHerzegovina'),
  t('country.botswana'),
  t('country.bouvetIsland'),
  t('country.brazil'),
  t('country.britishIndianOceanTerritory'),
  t('country.bruneiDarussalam'),
  t('country.bulgaria'),
  t('country.burkinaFaso'),
  t('country.burundi'),
  t('country.cambodia'),
  t('country.cameroon'),
  t('country.canada'),
  t('country.capeVerde'),
  t('country.caymanIslands'),
  t('country.centralAfricanRepublic'),
  t('country.chad'),
  t('country.chile'),
  t('country.china'),
  t('country.christmasIsland'),
  t('country.cocosIslands'),
  t('country.colombia'),
  t('country.comoros'),
  t('country.congo'),
  t('country.congDemocraticRepublic'),
  t('country.costaRica'),
  t('country.coteIvoire'),
  t('country.croatia'),
  t('country.cuba'),
  t('country.cyprus'),
  t('country.czechRepublic'),
  t('country.denmark'),
  t('country.djibouti'),
  t('country.dominica'),
  t('country.dominicanRepublic'),
  t('country.ecuador'),
  t('country.egypt'),
  t('country.elSalvador'),
  t('country.equatorialGuinea'),
  t('country.eritrea'),
  t('country.estonia'),
  t('country.ethiopia'),
  t('country.falklandIslands'),
  t('country.faroeIslands'),
  t('country.fiji'),
  t('country.finland'),
  t('country.france'),
  t('country.frenchGuiana'),
  t('country.frenchPolynesia'),
  t('country.frenchSouthernTerritories'),
  t('country.gabon'),
  t('country.gambia'),
  t('country.georgia'),
  t('country.germany'),
  t('country.ghana'),
  t('country.gibraltar'),
  t('country.greece'),
  t('country.greenland'),
  t('country.grenada'),
  t('country.guadeloupe'),
  t('country.guam'),
  t('country.guatemala'),
  t('country.guernsey'),
  t('country.guinea'),
  t('country.guineaBissau'),
  t('country.guyana'),
  t('country.haiti'),
  t('country.heardIslandMcdonaldIslands'),
  t('country.holySee'),
  t('country.honduras'),
  t('country.hongKong'),
  t('country.hungary'),
  t('country.iceland'),
  t('country.india'),
  t('country.indonesia'),
  t('country.iran'),
  t('country.iraq'),
  t('country.ireland'),
  t('country.isleofMan'),
  t('country.israel'),
  t('country.italy'),
  t('country.jamaica'),
  t('country.japan'),
  t('country.jersey'),
  t('country.jordan'),
  t('country.kazakhstan'),
  t('country.kenya'),
  t('country.kiribati'),
  t('country.koreaNorth'),
  t('country.koreaSouth'),
  t('country.kosovo'),
  t('country.kuwait'),
  t('country.kyrgyzstan'),
  t('country.laos'),
  t('country.latvia'),
  t('country.lebanon'),
  t('country.lesotho'),
  t('country.liberia'),
  t('country.libyanArabJamahiriya'),
  t('country.liechtenstein'),
  t('country.lithuania'),
  t('country.luxembourg'),
  t('country.macao'),
  t('country.macedonia'),
  t('country.madagascar'),
  t('country.malawi'),
  t('country.malaysia'),
  t('country.maldives'),
  t('country.mali'),
  t('country.malta'),
  t('country.marshallIslands'),
  t('country.martinique'),
  t('country.mauritania'),
  t('country.mauritius'),
  t('country.mayotte'),
  t('country.mexico'),
  t('country.micronesia'),
  t('country.moldova'),
  t('country.monaco'),
  t('country.mongolia'),
  t('country.montserrat'),
  t('country.morocco'),
  t('country.mozambique'),
  t('country.myanmar'),
  t('country.namibia'),
  t('country.nauru'),
  t('country.nepal'),
  t('country.netherlands'),
  t('country.netherlandsAntilles'),
  t('country.newCaledonia'),
  t('country.newZealand'),
  t('country.nicaragua'),
  t('country.niger'),
  t('country.nigeria'),
  t('country.niue'),
  t('country.norfolkIsland'),
  t('country.northernMarianaIslands'),
  t('country.norway'),
  t('country.oman'),
  t('country.pakistan'),
  t('country.palau'),
  t('country.palestinian'),
  t('country.panama'),
  t('country.papua'),
  t('country.paraguay'),
  t('country.peru'),
  t('country.philippines'),
  t('country.pitcairn'),
  t('country.poland'),
  t('country.portugal'),
  t('country.puertoRico'),
  t('country.qatar'),
  t('country.reunion'),
  t('country.romania'),
  t('country.russianFederation'),
  t('country.rwanda'),
  t('country.saintHelena'),
  t('country.saintKittsNevis'),
  t('country.saintLucia'),
  t('country.saintPierreMiquelon'),
  t('country.sainVincentGrenadines'),
  t('country.sanMarino'),
  t('country.saoTomePrincipe'),
  t('country.saudiArabia'),
  t('country.senegal'),
  t('country.serbia'),
  t('country.montenegro'),
  t('country.seychelles'),
  t('country.sierraLeone'),
  t('country.singapore'),
  t('country.slovakia'),
  t('country.slovenia'),
  t('country.solomonIslands'),
  t('country.somalia'),
  t('country.southAfrica'),
  t('country.southGeorgiaSouthSandwichIslands'),
  t('country.spain'),
  t('country.sriLanka'),
  t('country.sudan'),
  t('country.suriname'),
  t('country.svalbardJanMayen'),
  t('country.swaziland'),
  t('country.sweden'),
  t('country.switzerland'),
  t('country.syrianArabRepublic'),
  t('country.taiwanProvinceChina'),
  t('country.tajikistan'),
  t('country.tanzania'),
  t('country.thailand'),
  t('country.timorLeste'),
  t('country.togo'),
  t('country.tokelau'),
  t('country.tonga'),
  t('country.trinidadandTobago'),
  t('country.tunisia'),

  t('country.turkmenistan'),
  t('country.turksandCaicosIslands'),
  t('country.tuvalu'),
  t('country.uganda'),
  t('country.ukraine'),
  t('country.unitedArabEmirates'),
  t('country.unitedKingdom'),
  t('country.unitedStates'),
  t('country.unitedStatesMinorOutlyingIslands'),
  t('country.uruguay'),
  t('country.uzbekistan'),
  t('country.vanuatu'),
  t('country.venezuela'),
  t('country.vienam'),
  t('country.virginIslandsBritish'),
  t('country.virginIslands'),
  t('country.wallisFutuna'),
  t('country.westernSahara'),
  t('country.yemen'),
  t('country.zambia'),
  t('country.zimbabwe'),
];
const CountrySelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();

  const countries = columnsConfig(t);
  const [selectedOption, setSelectedOption] = useState();
  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      showSearch
      defaultValue={initialrecord ? initialrecord.country : t('titles.choose')}
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
    >
      <Option value={null} key={null}>
        {t('buttons.cancelOption')}
      </Option>

      {countries.map((country) => (
        <Option key={country} value={country}>
          {country}
        </Option>
      ))}
    </Select>
  );
};

export default CountrySelector;
