import { gql } from 'apollo-boost';

export const USERS = gql`
  query users($condition: Condition) {
    users(condition: $condition) {
      __typename

      ... on PaginatedUserResponse {
        data {
          id
          firstName
          lastName
          email
          password
          username
          role

          primaryPhone
          secondaryPhone
          createdAt
          updatedAt
        }
        totalCount
      }

      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($where: JSON!, $newValues: UserUpdateInput!) {
    updateUser(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on UserInvalidInputError {
        firstName
        lastName
        email
        primaryPhone
        secondaryPhone
        username
        role
        description
        password
      }

      ... on UserUpdateSuccess {
        affectedRows
        newValues {
          id
          firstName
          lastName
          email
          username
          role
          description
          primaryPhone
          secondaryPhone
          createdAt
          updatedAt
          password
        }
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($where: JSON!) {
    deleteUser(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on UserDeleteSuccess {
        affectedRows
        deletedRows {
          id
        }
      }
    }
  }
`;
