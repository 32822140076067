/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import IntegratedFormBuilder from '../../components/IntegratedFormBuilder';
import { UPDATE_PLAYLIST } from './gql';
import userInfoGetter from '../../utils.js/userInfoGetter';

const fieldsCreator = (t, chosenRecordForEdit) => {
  let songPoolId = null;
  let partnerIds = null;
  if (chosenRecordForEdit?.songPoolId) {
    songPoolId = chosenRecordForEdit.songPoolId;
    partnerIds = chosenRecordForEdit.partners?.map((partner) => partner.id);
  }
  return [
    {
      name: 'name',
      label: t('titles.playlist'),
      rules: [
        {
          type: 'string',
          message: t('warnings.string'),
        },
        {
          min: 2,
          message: t('warnings.min'),
        },
        {
          max: 150,
          message: t('warnings.max'),
        },
      ],
    },
    {
      name: 'songPoolWithPartners',
      label: t('titles.songPool'),
      inputType: 'songPoolWithPartnersSelector',
      propsForSelector: {
        initialSongPoolId: songPoolId,
        initialPartnerIds: partnerIds,
      },
    },
  ];
};

const EditModal = ({
  visibility,
  setVisibility,
  chosenRecordForEdit,
  setChosenRecordForEdit,
}) => {
  const { userId } = userInfoGetter();
  // hooks
  const { t } = useTranslation();

  // handles
  const handleCloseModalButton = useCallback(() => {
    setVisibility(false);
    setChosenRecordForEdit();
  }, [setVisibility, setChosenRecordForEdit]);

  const fields = fieldsCreator(t, chosenRecordForEdit);

  const beforeUpload = useCallback(
    (formValues) => {
      // nothing selected
      if (!formValues.name && !formValues.songPoolWithPartners) {
        return;
      }

      let songPoolId;
      let partnerIds;
      if (formValues.songPoolWithPartners) {
        songPoolId = formValues.songPoolWithPartners.songPoolId;
        partnerIds = formValues.songPoolWithPartners.partnerIds;
        delete formValues.songPoolWithPartners;
      }

      return {
        variables: {
          where: { id: chosenRecordForEdit.id },
          newValues: {
            ...formValues,
            createdBy: userId,
            songPoolId,
            partnerIds,
          },
        },
      };
    },
    [userId, chosenRecordForEdit]
  );

  return (
    <Modal
      title={t('titles.updatePlaylist')}
      onCancel={handleCloseModalButton}
      visible={visibility}
      footer={null}
    >
      <IntegratedFormBuilder
        fields={fields}
        postReq={UPDATE_PLAYLIST}
        postResIndex="updatePlaylist"
        // postResFieldForUpdating="playlist"
        beforeUpload={beforeUpload}
        // willBeUpdatedQuery={PLAYLISTS}
        // willBeUpdatedQueryName="playlists"
        showResetButton={false}
        executeFuncAfterSave={handleCloseModalButton}
      />
    </Modal>
  );
};

export default EditModal;
