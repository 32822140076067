import { gql } from 'apollo-boost';

export const CREATE_LEGAL_TRACKING = gql`
  mutation createLegalTracking($data: LegalTrackingInput!) {
    createLegalTracking(data: $data) {
      __typename
      ... on LegalTrackingInvalidInputError {
        name
      }
      ... on LegalTrackingCreateSuccess {
        legalTracking {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const LEGAL_TRACKINGS = gql`
  query legalTrackings($condition: Condition) {
    legalTrackings(condition: $condition) {
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedLegalTrackingResponse {
        totalCount
        data {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const UPDATE_LEGAL_TRACKING = gql`
  mutation updateLegalTracking(
    $where: JSON!
    $newValues: LegalTrackingUpdateInput!
  ) {
    updateLegalTracking(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on LegalTrackingInvalidInputError {
        name
      }

      ... on LegalTrackingUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const DELETE_LEGAL_TRACKING = gql`
  mutation deleteLegalTracking($where: JSON!) {
    deleteLegalTracking(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on LegalTrackingDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          createdAt
          updatedAt
        }
      }
    }
  }
`;
