import { gql } from 'apollo-boost';

export const SEARCH_SONG_POOLS = gql`
  query songPools($condition: Condition) {
    songPools(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedSongPoolResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;

export const SEARCH_PARTNERS = gql`
  query searchPartners($condition: Condition) {
    partners(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;
