import { gql } from 'apollo-boost';

export const SPECIAL_ADVERTISEMENTS = gql`
  query specialAdvertisements($condition: Condition) {
    specialAdvertisements(condition: $condition) {
      ... on PaginatedSpecialAdvertisementResponse {
        data {
          id
          createdAt
          updatedAt
          isReleased
          date
          partnerBranches {
            id
            name
            partner {
              id
            }
          }
          areAllBranchesSelected
          advertisementPool {
            id
            name
            isPlanned
            file {
              id
              filename
              mimetype
              path
              duration
              address
            }
            releasedCount
            releasedDuration
          }
        }
      }
    }
  }
`;

export const UPDATE_SPECIAL_ADVERTISEMENT = gql`
  mutation updateSpecialAdvertisement(
    $where: JSON!
    $newValues: SpecialAdvertisementUpdateInput!
  ) {
    updateSpecialAdvertisement(where: $where, newValues: $newValues) {
      __typename
      ... on SpecialAdvertisementInvalidInputError {
        date
        advertisementPoolId
        partnerBranchIds
      }
      ... on SpecialAdvertisementUpdateSuccess {
        affectedRows
        newValues {
          id
          createdAt
          updatedAt
          isReleased
          date
          partnerBranches {
            id
            name
            partner {
              id
            }
          }
          areAllBranchesSelected
          advertisementPool {
            id
            name
            isPlanned
            file {
              id
              filename
              mimetype
              path
              duration
              address
            }
            releasedCount
            releasedDuration
          }
        }
      }
    }
  }
`;

export const DELETE_SPECIAL_ADVERTISEMENT = gql`
  mutation deleteSpecialAdvertisement($where: JSON!) {
    deleteSpecialAdvertisement(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on SpecialAdvertisementDeleteSuccess {
        affectedRows
        deletedRows {
          id
          createdAt
          updatedAt
          isReleased
          date
          advertisementPool {
            id
            name
            file {
              id
              filename
              mimetype
              path
              duration
              address
            }
            releasedCount
            releasedDuration
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const RELEASE_SPECIAL_ADVERTISEMENT = gql`
  mutation releaseSpecialAdvertisement($id: Int!) {
    releaseSpecialAdvertisement(id: $id) {
      __typename
      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;

export const UN_RELEASE_SPECIAL_ADVERTISEMENT = gql`
  mutation unReleaseSpecialAdvertisement($id: Int!) {
    unReleaseSpecialAdvertisement(id: $id) {
      __typename

      ... on UnAuthorizedError {
        message
        statusCode
      }

      ... on ActionResult {
        success
        errors
        data
      }
    }
  }
`;
