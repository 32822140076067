import React, { useCallback } from 'react';
import { TimePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const TimeSelector = ({
  onChange,
  initialrecord,
  format = 'HH:mm',
  propsForSelector,
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (time, timeString) => {
      if (propsForSelector?.returnType === 'timeString') {
        onChange(timeString);
      } else {
        onChange(time);
      }
    },
    [onChange, propsForSelector]
  );

  return (
    <TimePicker
      defaultValue={
        initialrecord?.[propsForSelector.fieldName]
          ? moment.utc(initialrecord?.[propsForSelector.fieldName])
          : null
      }
      placeholder={t('placeholders.selectTime')}
      format={format}
      onChange={handleChange}
    />
  );
};

export default TimeSelector;
