import React, { useState, useEffect } from 'react';
import { Modal, Descriptions } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import dateParser from '../../utils.js/dateParser';

momentDurationFormatSetup(moment);

const DetailOfSpecialAds = ({ data, visibility, toggleModal, title }) => {
  const { t } = useTranslation();
  const [modalData, setModalData] = useState();

  useEffect(() => {
    setModalData({
      name: data?.advertisementPool?.name,
      date: dateParser(data?.date).date,
      time: dateParser(data?.date).time,
      isReleased: `${data?.advertisementPool?.releasedCount} ${t(
        'labels.advertisemet'
      )}`,
      duration: moment
        .duration(
          (data?.advertisementPool?.file?.duration / 1000).toFixed(2),
          'seconds'
        )
        .format(
          `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
            'labels.seconds'
          )}]`
        ),
      releasedTime: moment
        .duration(
          (data?.advertisementPool?.releasedDuration / 1000).toFixed(2),
          'seconds'
        )
        .format(
          `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
            'labels.seconds'
          )}]`
        ),
    });
  }, [data, t]);

  return (
    <>
      {modalData && (
        <Modal
          title={title}
          visible={visibility}
          onOk={toggleModal}
          onCancel={toggleModal}
          width={720}
          footer={null}
        >
          <Descriptions
            title={`${modalData.name}`}
            bordered
            column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
          >
            <Descriptions.Item label={t('labels.advertisementName')}>
              {modalData.name || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.date')}>
              {modalData.date || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.time')}>
              {modalData.time || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.duration')}>
              {modalData.duration || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('columns.isReleased')}>
              {modalData.isReleased || t('warnings.noData')}
            </Descriptions.Item>
            <Descriptions.Item label={t('labels.duration')}>
              {modalData.releasedTime || t('warnings.noData')}
            </Descriptions.Item>
          </Descriptions>
        </Modal>
      )}
    </>
  );
};

export default DetailOfSpecialAds;
