import React, { useState, useCallback } from 'react';
import { Row, Col, Button, Card, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { DateSelector } from '../../../components/Selectors';
import '../../../components/MainLayout/style.css';

const TimelineFilter = ({ handleOnSelect }) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState();

  const handleFilterButton = useCallback(() => {
    handleOnSelect(selectedDate);
  }, [handleOnSelect, selectedDate]);
  const text = <span>{t('labels.dateTooltip')}</span>;
  return (
    <Row gutter={[8, 8]}>
      <Col xs={24} sm={24} md={24} lg={24} xxl={24}>
        <Card>
          <Row gutter={[8, 8]}>
            <Col xs={18} sm={12} md={8} lg={6}>
              <Tooltip title={text}>
                <DateSelector onChange={(date) => setSelectedDate(date)} />
              </Tooltip>
            </Col>
            <Col xs={6} sm={6} md={3} lg={2}>
              <Button
                style={{ width: '100%' }}
                disabled={!selectedDate}
                ghost
                type="primary"
                onClick={handleFilterButton}
              >
                {t('buttons.filter')}
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default TimelineFilter;
