const userInfoGetter = () => {
  // eslint-disable-next-line no-undef
  const userInfo = JSON.parse(localStorage.getItem('user'));

  const firstName = userInfo?.firstName;
  const lastName = userInfo?.lastName;
  const username = userInfo?.username;
  const isAdmin =
    userInfo?.role?.toLocaleUpperCase() === 'ADMIN' ||
    userInfo?.role?.toLocaleUpperCase() === 'MANAGER';
  const role = userInfo?.role;
  const userId = userInfo?.id;
  const id = userInfo?.id;
  const partnerId = userInfo?.partner?.id;

  return {
    firstName,
    lastName,
    username,
    isAdmin,
    role,
    userId,
    id,
    partnerId,
  };
};

export default userInfoGetter;
