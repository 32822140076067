import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const RoleSelector = ({ onChange, initialrecord }) => {
  const [selectedOption, setSelectedOption] = useState();
  const { t } = useTranslation();
  const handleChange = useCallback(
    (value) => {
      setSelectedOption(value);
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      defaultValue={initialrecord.role}
      value={selectedOption}
      style={{ width: '100%' }}
      onChange={handleChange}
    >
      <Option value="MANAGER">{t('columns.manager')}</Option>
      <Option value="PARTNER">{t('columns.partner')}</Option>
      <Option value="BRANCH">{t('columns.branch')}</Option>
      <Option value="GUEST">{t('columns.guest')}</Option>
    </Select>
  );
};

export default RoleSelector;
