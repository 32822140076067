/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable no-param-reassign */
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import IntegratedFormBuilder from '../../components/IntegratedFormBuilder';
import CREATE_SONG from './gql';
import { SONGS } from '../Songs/gql';
import './form.css';
import image from './img/production.jpeg';

const mimeTypeGroups = [
  'audio/aac',
  'audio/mpeg',
  'audio/ogg',
  'audio/opus',
  'audio/wav',
  'audio/webm',
];

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.name'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'country',
    label: t('titles.country'),
    inputType: 'countrySelector',
  },
  {
    name: 'productionCompanyId',
    label: t('titles.productionCompany'),
    inputType: 'productionCompanySelector',
  },
  {
    name: 'artistId',
    label: t('titles.Artist'),
    inputType: 'artistSelector',
  },
  {
    name: 'albumId',
    label: t('titles.album'),
    inputType: 'albumSelector',
  },
  {
    name: 'writerId',
    label: t('titles.songWriter'),
    inputType: 'writerSelector',
  },
  {
    name: 'melodistId',
    label: t('titles.melodists'),
    inputType: 'melodistSelector',
  },
  {
    name: 'popularity',
    label: t('titles.popularity'),
    inputType: 'popularitySelector',
  },
  {
    name: 'songPoolId',
    label: t('titles.songPool'),
    inputType: 'songPoolSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'songCategories',
    label: t('titles.songCategory'),
    inputType: 'songCategorySelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    name: 'file',
    label: t('titles.song'),
    inputType: 'songFileUploadInput',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        validator: (_, value) => {
          // const fileType = value.type;
          // console.log(value);

          return value.name.endsWith('.ogg')
            ? Promise.resolve()
            : Promise.reject(t('messages.invalideFile'));
        },
      },
      {
        validator: (_, value) => {
          const fileSize = value.size;
          return fileSize < 104857600
            ? Promise.resolve()
            : Promise.reject(t('messages.fileSmaller'));
        },
      },
    ],
  },
];

const PlaylistAdd = () => {
  const { t } = useTranslation();

  const fields = fieldsCreator(t);

  const beforeUpload = useCallback((formValues) => {
    const { file } = formValues;
    delete formValues.file;

    return { variables: { data: formValues, file } };
  }, []);

  return (
    <Row gutter={(16, 16)}>
      <Col sm={24} xs={24} md={14}>
        <div
          style={{
            background: '#FAFAFA',
            boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
            transition: '0.3s',
          }}
          className="createFormBackground"
        >
          <Divider>{t('header.songUpload')}</Divider>
          <IntegratedFormBuilder
            fields={fields}
            postReq={CREATE_SONG}
            postResIndex="createSong"
            postResFieldForUpdating="song"
            beforeUpload={beforeUpload}
            willBeUpdatedQuery={SONGS}
            willBeUpdatedQueryName="songs"
          />
        </div>
      </Col>
      <Col md={7} lg={10} sm={12} xs={24}>
        <div className="leftImg">
          <img
            src={image}
            alt="logo"
            style={{
              marginTop: '30%',
              marginLeft: '15%',
              width: '400px',
              height: '400px',
              borderRadius: '50%',
              boxShadow: '0 0 15px 5px',
            }}
          />
        </div>
      </Col>
    </Row>
  );
};

export default PlaylistAdd;
