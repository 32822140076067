import { gql } from 'apollo-boost';

export const CREATE_RELEASED_JINGLE_POOL = gql`
  mutation createReleasedJinglePool($data: ReleasedJinglePoolInput!) {
    createReleasedJinglePool(data: $data) {
      __typename
      ... on ReleasedJinglePoolInvalidInputError {
        start
        end
        period
        jinglePoolId
        partnerId
        partnerBranchIds
      }
      ... on ReleasedJinglePoolCreateSuccess {
        releasedJinglePool {
          id
          start
          end
          period
          jinglePool {
            id
            name
          }
          partner {
            id
            name
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
      }
    }
  }
`;

export const RELEASED_JINGLE_POOLS = gql`
  query releasedJinglePools($condition: Condition) {
    releasedJinglePools(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedReleasedJinglePoolResponse {
        data {
          id
          start
          end
          period
          jinglePool {
            id
            name
          }
          partner {
            id
            name
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
        totalCount
      }
    }
  }
`;

export const UPDATE_RELEASED_JINGLE_POOL = gql`
  mutation updateReleasedJinglePool(
    $where: JSON!
    $newValues: ReleasedJinglePoolUpdateInput!
  ) {
    updateReleasedJinglePool(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on ReleasedJinglePoolInvalidInputError {
        start
        end
        period
        jinglePoolId
        partnerId
        partnerBranchIds
      }

      ... on ReleasedJinglePoolUpdateSuccess {
        affectedRows
        newValues {
          id
          start
          end
          period
          jinglePool {
            id
            name
          }
          partner {
            id
            name
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
      }
    }
  }
`;

export const DELETE_RELEASED_JINGLE_POOL = gql`
  mutation deleteReleasedJinglePool($where: JSON!) {
    deleteReleasedJinglePool(where: $where) {
      __typename

      ... on DeleteError {
        message
      }

      ... on ReleasedJinglePoolDeleteSuccess {
        affectedRows
        deletedRows {
          id
          start
          end
          period
          jinglePool {
            id
            name
          }
          partner {
            id
            name
          }
          partnerBranches {
            id
            name
          }
          areAllBranchesSelected
        }
      }
    }
  }
`;
