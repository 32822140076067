import React, { useState, useCallback } from 'react';
import { Row, Col, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import {
  CREATE_SONG_CATEGORY,
  CATEGORIES,
  UPDATE_SONG_CATEGORY,
  DELETE_SONG_CATEGORY,
} from './gql';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.categoryName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.categoryName'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
  },
  {
    title: t('titles.totalFiles'),
    dataIndex: 'songCount',
    key: 'songCount',
    editable: false,
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
];

export default function Category() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.data?.map((songCategory) => ({
      id: songCategory.id,
      name: songCategory.name,
    }));

    return {
      ...response,
      songCategory: { ...response?.songCategory, data: newData },
    };
  }, []);

  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('titles.songCategory')}</Divider>

      <Col span={24} style={{ marginTop: '20px' }}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newCategoryAdd')}
          showModalButtonLabel={t('titles.newCategoryAdd')}
          fields={fields}
          postReq={CREATE_SONG_CATEGORY}
          postResIndex="createSongCategory"
          postResFieldForUpdating="songCategory"
          willBeUpdatedQuery={CATEGORIES}
          willBeUpdatedQueryName="songCategories"
        />
      </Col>
      <Col style={{ marginTop: 10 }} md={24} lg={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={CATEGORIES}
          getResIndex="songCategories"
          deleteReq={DELETE_SONG_CATEGORY}
          deleteResIndex="deleteSongCategory"
          updateReq={UPDATE_SONG_CATEGORY}
          updateResIndex="updateSongCategory"
          afterGetFunc={afterGetFunc}
          filterColumns={{ songCategory: ['name'] }}
        />
      </Col>
    </Row>
  );
}
