import { gql } from 'apollo-boost';

export const SEARCH_PARTNER_PLAYLIST = gql`
  query searchPlaylist($condition: Condition) {
    playlists(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPlaylistResponse {
        data {
          id
          name
          songPool {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`;

export const SEARCH_PARTNERS_WITH_PLAYLIST = gql`
  query searchPartners($condition: Condition) {
    partners(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedPartnerResponse {
        data {
          id
          name
          playlists {
            id
            name
          }
        }
        totalCount
      }
    }
  }
`;

export const READ_PLAYLISTS_FOR_PUBLISH = gql`
  query playlists($condition: Condition) {
    playlists(condition: $condition) {
      __typename
      ... on PaginatedPlaylistResponse {
        data {
          id
          name
        }
      }
    }
  }
`;
