import { gql } from 'apollo-boost';

export const SECTOR_CATEGORY = gql`
  query partnerSectorCategories($condition: Condition) {
    partnerSectorCategories(condition: $condition) {
      ... on PaginatedPartnerSectorCategoryResponse {
        data {
          id
          name
          partnerSector {
            id
            name
          }
          createdAt
          updatedAt
        }
        totalCount
      }
    }
  }
`;

export const CREATE_SECTOR_CATEGORY = gql`
  mutation createPartnerSectorCategory($data: PartnerSectorCategoryInput!) {
    createPartnerSectorCategory(data: $data) {
      __typename
      ... on PartnerSectorCategoryCreateSuccess {
        partnerSectorCategory {
          id
          name
          partnerSector {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
      ... on PartnerSectorCategoryInvalidInputError {
        name
        partnerSectorId
      }
    }
  }
`;
export const UPDATE_SECTOR_CATEGORY = gql`
  mutation updatePartnerSectorCategory(
    $where: JSON!
    $newValues: PartnerSectorCategoryUpdateInput!
  ) {
    updatePartnerSectorCategory(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PartnerSectorCategoryInvalidInputError {
        name
        partnerSectorId
      }

      ... on PartnerSectorCategoryUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          partnerSector {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_SECTOR_CATEGORY = gql`
  mutation deletePartnerSectorCategory($where: JSON!) {
    deletePartnerSectorCategory(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on PartnerSectorCategoryDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          partnerSector {
            id
            name
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
