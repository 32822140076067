/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import { MONTHLY_TOTAL_STATISTIC } from '../gql';
import ServerWrong from '../../../components/Result/ServerWrong';

const InfoBoxes = ({ setTotalAdsPublishCount }) => {
  const { t } = useTranslation();
  const [standardValue, setStandardValue] = useState();
  const [interValue, setInterValue] = useState();
  const [specialValue, setSpecialValue] = useState();
  const [loading, setLoading] = useState(false);

  // #region gql queries
  const {
    loading: standardLoading,
    error: standardError,
    data: standardData,
  } = useQuery(MONTHLY_TOTAL_STATISTIC, {
    variables: { type: 'STANDARD_ADVERTISEMENT' },
  });

  const {
    loading: interLoading,
    error: interError,
    data: interData,
  } = useQuery(MONTHLY_TOTAL_STATISTIC, {
    variables: { type: 'INTER_ADVERTISEMENT' },
  });

  const {
    loading: specialLoading,
    error: specialError,
    data: specialData,
  } = useQuery(MONTHLY_TOTAL_STATISTIC, {
    variables: { type: 'SPECIAL_ADVERTISEMENT' },
  });
  // #endregion

  useEffect(() => {
    const currentMonthIndex = new Date().getMonth() + 1;

    if (
      standardData &&
      standardData?.monthlyTopByTotalOfType?.__typename ===
        'MonthlyTotalStatistic'
    ) {
      const monthsStandardStatistics =
        standardData?.monthlyTopByTotalOfType?.statistic;
      const currentMonthStandardData = monthsStandardStatistics.filter(
        (datum) => datum.month === currentMonthIndex
      )[0];
      setStandardValue(currentMonthStandardData?.value);
    }

    if (
      interData &&
      interData?.monthlyTopByTotalOfType?.__typename === 'MonthlyTotalStatistic'
    ) {
      const monthsInterStatistics =
        interData?.monthlyTopByTotalOfType?.statistic;
      const currentMonthInterData = monthsInterStatistics.filter(
        (datum) => datum.month === currentMonthIndex
      )[0];
      setInterValue(currentMonthInterData?.value);
    }

    if (
      specialData &&
      specialData?.monthlyTopByTotalOfType?.__typename ===
        'MonthlyTotalStatistic'
    ) {
      const monthsSpecialStatistics =
        specialData?.monthlyTopByTotalOfType?.statistic;
      const currentMonthSpecialData = monthsSpecialStatistics.filter(
        (datum) => datum.month === currentMonthIndex
      )[0];
      setSpecialValue(currentMonthSpecialData?.value);
    }
  }, [
    standardData,
    interData,
    specialData,
    setStandardValue,
    setInterValue,
    setSpecialValue,
  ]);

  useEffect(() => {
    if (standardLoading || interLoading || specialLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [standardLoading, interLoading, specialLoading, setLoading]);

  useEffect(() => {
    if (standardValue || interValue || specialValue) {
      const safeStandardValue = standardValue || 0;
      const safeInterValue = interValue || 0;
      const safeSpecialValue = specialValue || 0;
      setTotalAdsPublishCount(
        safeStandardValue + safeInterValue + safeSpecialValue
      );
    }
  }, [setTotalAdsPublishCount, standardValue, interValue, specialValue]);

  if (standardError || interError || specialError) {
    return <ServerWrong />;
  }
  return (
    <Spin spinning={loading}>
      <Card style={styles.adsInfoBox} className="adsInfoBox">
        <Row>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 6 }}>
            <FontAwesomeIcon icon="ad" size="4x" />
          </Col>
          <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 18 }}>
            <p className="p">{t('titles.advertisementReleasedCount')}</p>
            <ul>
              <li className="p">
                {`${t('labels.standard')}: ${
                  standardValue || t('warnings.noData')
                }`}
              </li>
              <li className="p">
                {`${t('labels.inter')}: ${interValue || t('warnings.noData')}`}
              </li>
              <li className="p">
                {`${t('labels.special')}: ${
                  specialValue || t('warnings.noData')
                }`}
              </li>
            </ul>
          </Col>
        </Row>
      </Card>
    </Spin>
  );
};

export default InfoBoxes;

const styles = {
  adsInfoBox: {
    height: 220,
    fontSize: 16,
    color: '#fff',
    borderRadius: 10,
    boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
    background: 'linear-gradient(150deg, #13f1fc, #0470dc 100%)',
  },
};
