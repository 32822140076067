/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, Spin } from 'antd';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { BACKEND_URL } from '../../../constants';
import dateParser from '../../../utils.js/dateParser';
import StartEndFilter from '../../../components/StartEndFilter';
import { RELEASED_SPECIAL_ADVERTISEMENTS_OF_BRANCH } from './gql';
import ServerWrong from '../../../components/Result/ServerWrong';

const columnsConfig = (t) => [
  {
    title: t('labels.advertisementName'),
    dataIndex: 'advertisementPoolId',
    key: 'advertisementPoolId',
    width: '12%',
    editable: false,
    render: (value, record) => record?.advertisementPool?.name,
  },
  {
    title: t('titles.song'),
    dataIndex: 'address',
    key: 'address',
    editable: false,
    render: (fileId, record) => {
      return (
        <audio
          controls
          controlsList="nodownload"
          style={{ width: '190px' }}
          src={BACKEND_URL + record.advertisementPool.file.address}
        />
      );
    },
  },
  {
    title: t('columns.time'),
    dataIndex: 'file',
    key: 'file',
    render: (file, record) => {
      if (record?.advertisementPool?.file?.duration) {
        const durations = record?.advertisementPool?.file?.duration / 1000;
        return moment
          .duration(durations.toFixed(2), 'seconds')
          .format(
            `hh [${t('labels.hours')}], mm [${t('labels.minutes')}], ss [${t(
              'labels.seconds'
            )}]`
          );
      }

      return t('warnings.noData');
    },
  },
  {
    title: t('columns.date'),
    dataIndex: 'date',
    key: 'date',
    inputType: 'dateWithTimeSelector',
    propsForSelector: { fieldName: 'date' },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
    render: (value) => {
      const { date, time } = dateParser(value);

      return [date, '  ', time];
    },
  },
];

const SpecialAdvertisements = () => {
  // hooks
  const { t } = useTranslation();
  const [request, setRequest] = useState();

  const { loading, error, data } = useQuery(
    RELEASED_SPECIAL_ADVERTISEMENTS_OF_BRANCH,
    {
      variables: request,
      skip: !request,
      fetchPolicy: 'network-only',
    }
  );

  // #region handles
  const handleFilterResult = useCallback(
    ({ startDate, endDate }) => {
      const user = JSON.parse(localStorage.getItem('user'));

      setRequest({
        condition: {
          limit: -1,
          order: [['createdAt', 'desc']],
          where: {
            id: user?.partnerBranch?.id,
          },
        },
        start: startDate,
        end: endDate,
        isReleased: true,
      });
    },
    [setRequest]
  );
  // #endregion

  const columns = columnsConfig(t);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <Spin spinning={loading}>
      <StartEndFilter handleFilterResult={handleFilterResult} />
      <Table
        dataSource={data?.partnerBranches?.data[0]?.specialAdvertisements || []}
        rowKey={(record, key) => {
          // eslint-disable-next-line no-param-reassign
          record.key = key;
          return key;
        }}
        columns={columns}
        pagination={{
          defaultCurrent: 1,
          total: data?.partnerBranches?.totalCount,
          pageSize: 10,
          responsive: true,
        }}
      />
    </Spin>
  );
};

export default SpecialAdvertisements;
