import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Divider } from 'antd';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

import {
  DELETE_SECTOR_CATEGORY,
  UPDATE_SECTOR_CATEGORY,
  SECTOR_CATEGORY,
  CREATE_SECTOR_CATEGORY,
} from './gql';

import ModalFormBuilder from '../../components/ModalFormBuilder';

const fieldsCreator = (t) => [
  {
    name: 'name',
    label: t('titles.sectorCategory'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'partnerSectorId',
    label: t('titles.partnerSector'),
    inputType: 'partnerSectorSelector',
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.sectorCategory'),
    dataIndex: 'name',
    key: 'name',
    editable: true,
  },
  {
    title: t('titles.partnerSector'),
    dataIndex: 'partnerSectorId',
    key: 'partnerSectorId',
    inputType: 'partnerSectorSelector',
    render: (partnerSectorId, record) => {
      return record?.partnerSectorName || t('warnings.noData');
    },
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },

  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
  },
];

const SectorCategory = () => {
  const { t } = useTranslation();

  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.partnerSectorCategories?.data?.map((sector) => ({
      id: sector.id,
      name: sector.name,
      partnerSectorId: sector.partnerSector.id,
      partnerSectorName: sector.partnerSector.name,
      createdAt: humanizeTimestamp(sector.createdAt),
      updatedAt: humanizeTimestamp(sector.updatedAt),
    }));

    return {
      ...response,
      partnerSectorCategories: {
        ...response?.partnerSectorCategories,
        data: newData,
      },
    };
  }, []);

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="center">{t('header.partnerSector')}</Divider>
        <Col span={24} style={{ marginTop: '20px' }}>
          <ModalFormBuilder
            visibility={visibility}
            toggleModal={toggleTrueFalse}
            modalTitle={t('titles.newSectorCategory')}
            fields={fields}
            postReq={CREATE_SECTOR_CATEGORY}
            showModalButtonLabel={t('titles.newSectorCategory')}
            postResIndex="createPartnerSectorCategory"
            postResFieldForUpdating="partnerSectorCategory"
            willBeUpdatedQuery={SECTOR_CATEGORY}
            willBeUpdatedQueryName="partnerSectorCategories"
          />
        </Col>
      </Row>
      <Row>
        <Col md={24} lg={24} sm={24} xs={24}>
          <IntegratedEditableTable
            columns={columns}
            getReq={SECTOR_CATEGORY}
            getResIndex="partnerSectorCategories"
            deleteReq={DELETE_SECTOR_CATEGORY}
            deleteResIndex="deletePartnerSectorCategory"
            updateReq={UPDATE_SECTOR_CATEGORY}
            updateResIndex="updatePartnerSectorCategory"
            afterGetFunc={afterGetFunc}
            filterColumns={{
              partnerSector: ['name'],
              partnerSectorCategory: ['name'],
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default SectorCategory;
