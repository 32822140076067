import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import { USERS, DELETE_USER, UPDATE_USER } from './gql';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import regexCreator from '../../utils.js/regexCreator';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const columnsConfig = (t) => [
  {
    title: t('titles.username'),
    dataIndex: 'username',
    key: 'username',
    width: '10%',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    title: t('titles.name'),
    dataIndex: 'firstName',
    key: 'firstName',
    width: '6%',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },

  {
    title: t('titles.surname'),
    dataIndex: 'lastName',
    key: 'lastName',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    title: t('placeholders.password'),
    dataIndex: 'password',
    key: 'password',
    width: '6%',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    title: t('titles.email'),
    dataIndex: 'email',
    key: 'email',
    editable: true,
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        pattern: regexCreator('email'),
        message: t('warnings.email'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    title: t('titles.primaryPhone'),
    dataIndex: 'primaryPhone',
    key: 'primaryPhone',
    inputType: 'phoneSelector',

    editable: true,
  },
  {
    title: t('titles.secondaryPhone'),
    dataIndex: 'secondaryPhone',
    key: 'secondaryPhone',
    inputType: 'phoneSelector',

    editable: true,
  },

  {
    title: t('titles.role'),
    dataIndex: 'role',
    key: 'role',
    inputType: 'role',
    editable: true,
    filters: [
      {
        text: t('columns.manager'),
        value: 'MANAGER',
      },
      {
        text: t('columns.partner'),
        value: 'PARTNER',
      },
      {
        text: t('columns.branch'),
        value: 'BRANCH',
      },
      {
        text: t('columns.guest'),
        value: 'GUEST',
      },
    ],
    onFilter: (value, record) => record.role.indexOf(value) === 0,
    render: (role) => t(`columns.${role.toLowerCase()}`),

    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
    ],
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
  },
];

export default function UserInfo() {
  const { t } = useTranslation();

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.users?.data?.map((user) => ({
      ...user,
      createdAt: humanizeTimestamp(user.createdAt),
      updatedAt: humanizeTimestamp(user.updatedAt),
    }));

    const removedData = newData?.filter((user) => user.role !== 'ADMIN');

    return {
      ...response,
      users: { ...response?.users, data: removedData },
    };
  }, []);

  return (
    <>
      <Divider orientation="center">{t('titles.users')}</Divider>
      <IntegratedEditableTable
        columns={columns}
        getReq={USERS}
        getResIndex="users"
        deleteReq={DELETE_USER}
        deleteResIndex="deleteUser"
        updateReq={UPDATE_USER}
        updateResIndex="updateUser"
        afterGetFunc={afterGetFunc}
        willBeUpdatedQuery={USERS}
        willBeUpdatedQueryName="users"
        filterColumns={{
          user: ['username', 'firstName', 'lastName', 'email', 'role'],
        }}
      />
    </>
  );
}
