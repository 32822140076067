import { gql } from 'apollo-boost';

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on AuthPayload {
        token
        user {
          id
          firstName
          lastName
          email
          username
          role
          description
          partner {
            __typename
            ... on Partner {
              id
            }
          }
          partnerBranch {
            __typename
            ... on PartnerBranch {
              id
            }
          }
        }
      }
    }
  }
`;

export default LOGIN;
