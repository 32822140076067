import { gql } from 'apollo-boost';

export const SONGS = gql`
  query songs($condition: Condition) {
    songs(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on PaginatedSongResponse {
        data {
          id
          name
          country
          productionCompany {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on ProductionCompany {
              id
              name
            }
          }
          file {
            id
            filename
            address
          }
          artist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Artist {
              id
              firstName
              lastName
            }
          }
          album {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Album {
              id
              name
            }
          }
          writer {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Writer {
              id
              firstName
              lastName
            }
          }
          melodist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Melodist {
              id
              firstName
              lastName
            }
          }
          legalTracking {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on LegalTracking {
              id
              name
            }
          }
          popularity
          songPool {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on SongPool {
              id
              name
            }
          }
          songCategories {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on PaginatedSongCategoryResponse {
              data {
                id
                name
              }
            }
          }
          createdAt
          updatedAt
        }
        totalCount
      }
    }
  }
`;

export const UPDATE_SONG = gql`
  mutation updateSong($where: JSON!, $newValues: SongUpdateInput!) {
    updateSong(where: $where, newValues: $newValues) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }

      ... on SongInvalidInputError {
        name
        country
        productionCompanyId
        artistId
        albumId
        melodistId
        writerId
        legalTackingId
        popularity
        songPoolId
        songCategories
      }

      ... on SongUpdateSuccess {
        affectedRows
        newValues {
          id
          name
          country
          productionCompany {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on ProductionCompany {
              id
              name
            }
          }
          artist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Artist {
              id
              firstName
              lastName
            }
          }
          album {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Album {
              id
              name
            }
          }
          writer {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Writer {
              id
              firstName
              lastName
            }
          }
          melodist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Melodist {
              id
              firstName
              lastName
            }
          }
          legalTracking {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on LegalTracking {
              id
              name
            }
          }
          popularity
          songPool {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on SongPool {
              id
              name
            }
          }
          songCategories {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on PaginatedSongCategoryResponse {
              data {
                id
                name
              }
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;

export const DELETE_SONG = gql`
  mutation deleteSong($where: JSON!) {
    deleteSong(where: $where) {
      __typename
      ... on DeleteError {
        message
      }
      ... on SongDeleteSuccess {
        affectedRows
        deletedRows {
          id
          name
          country
          productionCompany {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on ProductionCompany {
              id
              name
            }
          }
          artist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Artist {
              id
              firstName
              lastName
            }
          }
          album {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Album {
              id
              name
            }
          }
          writer {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Writer {
              id
              firstName
              lastName
            }
          }
          melodist {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on Melodist {
              id
              firstName
              lastName
            }
          }
          legalTracking {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on LegalTracking {
              id
              name
            }
          }
          popularity
          songPool {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on SongPool {
              id
              name
            }
          }
          songCategories {
            __typename
            ... on NotFoundResult {
              notFoundErrorMessage
            }
            ... on PaginatedSongCategoryResponse {
              data {
                id
                name
              }
            }
          }
          createdAt
          updatedAt
        }
      }
    }
  }
`;
