import React, { useState, useCallback } from 'react';
import { Row, Col, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import { CREATE_ARTIST, ARTISTS, UPDATE_ARTIST, DELETE_ARTIST } from './gql';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const fieldsCreator = (t) => [
  {
    name: 'firstName',
    label: t('titles.artistFirstName'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'lastName',
    label: t('titles.artistLastName'),
    rules: [
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'gender',
    label: t('titles.gender'),
    inputType: 'genderSelector',
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.artistFirstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    editable: true,
  },
  {
    title: t('titles.artistLastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    editable: true,
  },
  {
    title: t('titles.gender'),
    dataIndex: 'gender',
    key: 'gender',
    inputType: 'genderSelector',
    editable: true,
    render: (gender) => t(`columns.${gender.toLowerCase()}`),
    filters: [
      {
        text: t('columns.woman'),
        value: 'WOMAN',
      },
      {
        text: t('columns.man'),
        value: 'MAN',
      },
      {
        text: t('columns.other'),
        value: 'OTHER',
      },
    ],
    // specify the condition of filtering result
    // here is that finding the name started with `value`
    onFilter: (value, record) => record.gender.indexOf(value) === 0,
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
];

export default function Artist() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.data?.map((artist) => ({
      id: artist.id,
      firstName: artist.firstName,
      lastName: artist.lastName,
      gender: artist.gender,
    }));

    return { ...response, artsits: { ...response?.artsits, data: newData } };
  }, []);

  const beforeUpload = useCallback((formValues) => {
    const data = { ...formValues };

    // Yes this is stupid, but it's a way to make this area optional without messing with the backend.
    if (!data.lastName || data.lastName.trim() === '') {
      data.lastName = '\u200B\u200B';
    }

    if (!data.gender) {
      data.gender = 'OTHER';
    }

    return {
      variables: {
        data,
      },
    };
  }, []);

  const beforeSave = useCallback((recordId, newRecord) => {
    const newValues = { ...newRecord };

    // Yes this is stupid, but it's a way to make this area optional without messing with the backend.
    if (!newValues.lastName || newValues.lastName.trim() === '')
      newValues.lastName = '\u200B\u200B';

    return {
      variables: {
        where: { id: recordId },
        newValues,
      },
    };
  }, []);

  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('titles.Artist')}</Divider>

      <Col span={24} style={{ marginTop: '20px' }}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newArtist')}
          showModalButtonLabel={t('titles.newArtist')}
          fields={fields}
          beforeUpload={beforeUpload}
          postReq={CREATE_ARTIST}
          postResIndex="createArtist"
          postResFieldForUpdating="artist"
          willBeUpdatedQuery={ARTISTS}
          willBeUpdatedQueryName="artists"
        />
      </Col>
      <Col style={{ marginTop: 10 }} md={24} lg={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={ARTISTS}
          getResIndex="artists"
          deleteReq={DELETE_ARTIST}
          deleteResIndex="deleteArtist"
          updateReq={UPDATE_ARTIST}
          updateResIndex="updateArtist"
          beforeSave={beforeSave}
          afterGetFunc={afterGetFunc}
          filterColumns={{ artist: ['firstName', 'lastName', 'gender'] }}
        />
      </Col>
    </Row>
  );
}
