import React, { useState, useCallback } from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/react-hooks';
import SEARCH_ADVERTISEMENT_SETS from './gql';

const { Option } = Select;

const AdvertisementTypeAndSetSelector = ({ onChange, initialrecord }) => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState();
  const [selectedSet, setSelectedSet] = useState();
  const [condition, setCondition] = useState();
  const { loading, error, data } = useQuery(SEARCH_ADVERTISEMENT_SETS, {
    variables: { condition },
    skip: !condition,
  });

  const handleTypeChange = useCallback((value) => {
    setSelectedType(value);
    setCondition({
      limit: -1,
      where: { type: value },
    });
  }, []);

  const handleSetChange = useCallback(
    (value) => {
      setSelectedSet(value);
      onChange({
        advertisementType: selectedType,
        advertisementSet: value,
      });
    },
    [onChange, selectedType]
  );

  const handleSetSearch = useCallback(
    (input) => {
      if (input) {
        setCondition({
          limit: -1,
          where: {
            type: selectedType,
            _or: {
              name: { _iLike: `%${input}%` },
            },
          },
        });
      }
    },
    [selectedType]
  );

  if (error) {
    return t('messages.somethingBad');
  }

  return (
    <>
      <Select
        value={selectedType}
        style={{ width: '100%' }}
        onChange={handleTypeChange}
      >
        <Option value="STANDARD">{t('columns.standard')}</Option>
        <Option value="INTER">{t('columns.inter')}</Option>
      </Select>

      {selectedType && (
        <Select
          loading={loading}
          showSearch
          value={selectedSet}
          placeholder={t('placeholders.selectAdvertisementSet')}
          style={{ width: '100%', marginTop: 20 }}
          defaultActiveFirstOption={false}
          onSearch={handleSetSearch}
          onChange={handleSetChange}
          notFoundContent={t('messages.notFoundContent')}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={null} key={null}>
            {t('buttons.cancelOption')}
          </Option>
          {data?.advertisementSets?.data &&
            data.advertisementSets.data.map((set) => (
              <Option value={set.id} key={set.id}>
                {set.name}
              </Option>
            ))}
        </Select>
      )}
    </>
  );
};

export default AdvertisementTypeAndSetSelector;
