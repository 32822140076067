import { gql } from 'apollo-boost';

const SEARCH_SONG_CATEGORIES = gql`
  query searchSongCategories($condition: Condition) {
    songCategories(condition: $condition) {
      __typename

      ... on NotFoundResult {
        notFoundErrorMessage
      }
      ... on PaginatedSongCategoryResponse {
        data {
          id
          name
        }
        totalCount
      }
    }
  }
`;

export default SEARCH_SONG_CATEGORIES;
