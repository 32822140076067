import React from "react";
import { Table, Row, Col } from "antd";
import { Link } from "react-router-dom";
const columns = [
  {
    title: "Şubeler",
    dataIndex: "sube",
    key: "sube",
    width: 120,
    render: (text) => <Link>{text}</Link>,
    fixed: "left",
  },
  {
    title: "Son Aktif Olma",
    dataIndex: "sonAktif",
    key: "sonAktif",
    width: 150,
  },
  {
    title: "Offline Bilgi",
    dataIndex: "offline",
    key: "offline",
    width: 150,
  },
  {
    title: "Durum",
    dataIndex: "durum",
    key: "durum",
    width: 150,
  },
  // {
  //   title: 'Tags',
  //   key: 'tags',
  //   dataIndex: 'tags',
  //   render: tags => (
  //     <span>
  //       {tags.map(tag => {
  //         let color = tag.length > 5 ? 'geekblue' : 'green';
  //         if (tag === 'loser') {
  //           color = 'volcano';
  //         }
  //         return (
  //           <Tag color={color} key={tag}>
  //             {tag.toUpperCase()}
  //           </Tag>
  //         );
  //       })}
  //     </span>
  //   ),
  // },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   render: (text, record) => (
  //     <span>
  //       <a style={{ marginRight: 16 }}>Invite {record.name}</a>
  //       <a>Delete</a>
  //     </span>
  //   ),
  // },
];

const data = [
  {
    key: "1",
    sube: "A Şubesi",
    sonAktif: "21.03.2020 - 18:00",
    offline: "58 Gündür Offline",
    durum: ["Offline"],
  },
  {
    key: "2",
    sube: "B Şubesi",
    sonAktif: "20.03.2020 - 18:00",
    offline: "58 Gündür Offline",
    durum: ["Online"],
  },
  {
    key: "3",
    sube: "C Şubesi",
    sonAktif: "20.03.2020 - 18:00",
    offline: "58 Gündür Offline",
    durum: ["Offline"],
  },
];
const TableRedash = () => {
  return (
    <Row>
      <Col md={24}>
        {" "}
        <Table columns={columns} dataSource={data} />
      </Col>
    </Row>
  );
};
export default TableRedash;
