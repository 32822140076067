import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import { CREATE_PERIODIC_ADVERTISEMENT } from './gql';

const PlanModal = ({ visibility, toggleVisibility, advertisementRecord }) => {
  const { t } = useTranslation();

  const fields = [
    {
      name: 'partnerBranchIds',
      label: t('labels.partnerBranch'),
      inputType: 'partnerBranchSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
      propsForSelector: {
        partnerId: advertisementRecord?.partnerId,
        searchWithService: false,
      },
    },
    {
      name: 'advertisementTypeAndSet',
      label: t('labels.advertisementSet'),
      inputType: 'advertisementTypeAndSetSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
    },
    {
      name: 'startDate',
      label: t('labels.startDate'),
      inputType: 'dateSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
    },
    {
      name: 'endDate',
      label: t('labels.endDate'),
      inputType: 'dateSelector',
      rules: [
        {
          required: true,
          message: t('warnings.required'),
        },
      ],
    },
  ];

  const beforeUpload = useCallback(
    (req) => {
      if (
        req.type === 'INTER' &&
        advertisementRecord.file.duration / 1000 > 15
      ) {
        message.error(t('messages.fileSize'));
        return null;
      }

      // const differenceBetweenDatesAsDays = moment
      //   .duration(moment(req.endDate).diff(moment(req.startDate)))
      //   .asDays();

      // if (differenceBetweenDatesAsDays > 90) {
      //   message.error(t('messages.datesGapCanNotBeGt90Days'));
      //   return null;
      // }

      req.type = req.advertisementTypeAndSet.advertisementType;
      req.advertisementSetId = req.advertisementTypeAndSet.advertisementSet;
      delete req.advertisementTypeAndSet;
      req.advertisementPoolId = advertisementRecord.id;
      req.partnerId = advertisementRecord?.partnerId;

      return { variables: { data: req } };
    },

    [advertisementRecord, t]
  );

  return (
    <ModalFormBuilder
      visibility={visibility}
      toggleModal={toggleVisibility}
      modalTitle={t('titles.planAdvertisement')}
      fields={fields}
      postReq={CREATE_PERIODIC_ADVERTISEMENT}
      postResIndex="createPeriodicAdvertisement"
      postResFieldForUpdating="periodicAdvertisement"
      showOpenButton={false}
      beforeUpload={beforeUpload}
    />
  );
};

export default PlanModal;
