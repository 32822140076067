import React, { useState, useCallback } from 'react';
import { Row, Col, Divider } from 'antd';

import { useTranslation } from 'react-i18next';
import IntegratedEditableTable from '../../components/IntegratedEditableTable/Index';
import ModalFormBuilder from '../../components/ModalFormBuilder';
import { CREATE_WRITER, WRITERS, UPDATE_WRITER, DELETE_WRITER } from './gql';
import humanizeTimestamp from '../../utils.js/humanizeTimestamp';

const fieldsCreator = (t) => [
  {
    name: 'firstName',
    label: t('titles.name'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'lastName',
    label: t('titles.surname'),
    rules: [
      {
        required: true,
        message: t('warnings.required'),
      },
      {
        type: 'string',
        message: t('warnings.string'),
      },
      {
        min: 2,
        message: t('warnings.min'),
      },
      {
        max: 150,
        message: t('warnings.max'),
      },
    ],
  },
  {
    name: 'gender',
    label: t('titles.gender'),
    inputType: 'genderSelector',
  },
];

const columnsConfig = (t) => [
  {
    title: t('titles.name'),
    dataIndex: 'firstName',
    key: 'firstName',
    editable: true,
  },
  {
    title: t('titles.surname'),
    dataIndex: 'lastName',
    key: 'lastName',
    editable: true,
  },
  {
    title: t('titles.gender'),
    dataIndex: 'gender',
    key: 'gender',
    inputType: 'genderSelector',
    editable: true,
    render: (gender) => t(`columns.${gender.toLowerCase()}`),
  },
  {
    title: t('columns.createdAt'),
    dataIndex: 'createdAt',
    key: 'createdAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
  {
    title: t('columns.updatedAt'),
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    editable: false,
    render: (value) => humanizeTimestamp(value),
  },
];

export default function Writer() {
  const { t } = useTranslation();
  const [visibility, toggleModal] = useState(false);
  const toggleTrueFalse = () => toggleModal(!visibility);

  const fields = fieldsCreator(t);

  const columns = columnsConfig(t);

  const afterGetFunc = useCallback((response) => {
    const newData = response?.data?.map((writer) => ({
      id: writer.id,
      firstName: writer.firstName,
      lastName: writer.lastName,
      gender: writer.gender,
    }));

    return { ...response, artsits: { ...response?.artsits, data: newData } };
  }, []);
  return (
    <Row gutter={16}>
      <Divider orientation="center">{t('titles.writers')}</Divider>

      <Col span={24} style={{ marginTop: '20px' }}>
        <ModalFormBuilder
          visibility={visibility}
          toggleModal={toggleTrueFalse}
          modalTitle={t('titles.newWriter')}
          showModalButtonLabel={t('titles.newWriter')}
          fields={fields}
          postReq={CREATE_WRITER}
          postResIndex="createWriter"
          postResFieldForUpdating="writer"
          willBeUpdatedQuery={WRITERS}
          willBeUpdatedQueryName="writers"
        />
      </Col>
      <Col style={{ marginTop: 10 }} md={24} lg={24}>
        <IntegratedEditableTable
          columns={columns}
          getReq={WRITERS}
          getResIndex="writers"
          deleteReq={DELETE_WRITER}
          deleteResIndex="deleteWriter"
          updateReq={UPDATE_WRITER}
          updateResIndex="updateWriter"
          afterGetFunc={afterGetFunc}
          filterColumns={{ writer: ['firstName', 'lastName', 'gender'] }}
        />
      </Col>
    </Row>
  );
}
