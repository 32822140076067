import { gql } from 'apollo-boost';

const SEARCH_PLAYLIST = gql`
  query playlists($condition: Condition) {
    playlists(condition: $condition) {
      __typename

      ... on PaginatedPlaylistResponse {
        data {
          id
          name
        }
        totalCount
      }
      __typename
      ... on NotFoundResult {
        notFoundErrorMessage
      }
    }
  }
`;

export default SEARCH_PLAYLIST;
