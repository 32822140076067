/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col, Divider, Button, Empty, Tooltip, Spin } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@apollo/react-hooks';
import { MONTHLY_STATISTIC } from '../gql';
import ServerWrong from '../../../components/Result/ServerWrong';
import { monthsMappedByIndex } from '../../../constants';
import { useTranslation } from 'react-i18next';

const SongStatistic = ({ type }) => {
  const { t } = useTranslation();

  const [currentMonthOrder, setCurrentMonthOrder] = useState(1);
  const [songsMappedByMonthOrder, setSongsMappedByMonthOrder] = useState();

  const { loading, error, data } = useQuery(MONTHLY_STATISTIC, {
    variables: { type: 'SONG', topNumber: 10 },
  });

  useEffect(() => {
    const statisticArray = data?.monthlyTopByEachRecords?.statistic;

    if (statisticArray) {
      const result = statisticArray.reduce((acc, cur) => {
        const songs = cur.statistic.map((datum) => datum.name);
        acc[cur.month] = { month: monthsMappedByIndex[cur.month], songs };
        return acc;
      }, {});

      setSongsMappedByMonthOrder(result);
    }
  }, [setSongsMappedByMonthOrder, data]);

  const handleBefore = useCallback(() => {
    if (currentMonthOrder === 1) {
      return;
    }
    setCurrentMonthOrder(currentMonthOrder - 1);
  }, [currentMonthOrder, setCurrentMonthOrder]);

  const handleAfter = useCallback(() => {
    if (currentMonthOrder === 12) {
      return;
    }
    setCurrentMonthOrder(currentMonthOrder + 1);
  }, [currentMonthOrder, setCurrentMonthOrder]);

  if (error) {
    return <ServerWrong />;
  }

  return (
    <>
      {songsMappedByMonthOrder && (
        <Spin spinning={loading}>
          <Row className="songMuch">
            <Col span={24} sm={24} xs={24}>
              <div
                style={{
                  width: '100%',
                  minHeight: 300,
                  backgroundColor: 'red',
                  background: 'linear-gradient(150deg, #004e92,#000428 100%)',
                  color: '#fff',
                  fontSize: 16,
                  borderRadius: 10,
                  boxShadow: '0px 4px 15px 0px rgba(0,0,0,0.2)',
                  marginTop: 30,
                  padding: 10,
                }}
              >
                <Divider orientation="center">
                  <h4 style={{ color: '#fff' }} className="title">
                    {`${monthsMappedByIndex[currentMonthOrder]} ${t(
                      'titles.month'
                    )} ${
                      type === 'max' ? t('titles.most') : t('titles.least')
                    } ${t('titles.playTenSong')}`}
                  </h4>
                </Divider>
                <Row>
                  <Col md={{ span: 18, offset: 4 }} xs={24} sm={24}>
                    {songsMappedByMonthOrder[currentMonthOrder]?.songs?.length >
                    0 ? (
                      <ol>
                        {songsMappedByMonthOrder[currentMonthOrder].songs.map(
                          (song, index) => (
                            <li key={index}>{song}</li>
                          )
                        )}
                      </ol>
                    ) : (
                      <Empty />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 4, offset: 19 }}
                  >
                    <div style={{ float: 'right' }}>
                      <Tooltip title={t('titles.beforeMonth')}>
                        <Button
                          type="default"
                          style={{ marginRight: 5 }}
                          onClick={handleBefore}
                        >
                          <FontAwesomeIcon icon="arrow-left" />
                        </Button>
                      </Tooltip>
                      <Tooltip title={t('titles.afterMonth')}>
                        <Button type="default" onClick={handleAfter}>
                          <FontAwesomeIcon icon="arrow-right" />
                        </Button>
                      </Tooltip>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Spin>
      )}
    </>
  );
};

export default SongStatistic;
